import { __awaiter } from "tslib";
import { postJson } from '@avensia/scope';
import { cleanVariationCode } from 'TrackingInformation/cart-throttler';
import { formatPriceForGtm } from 'TrackingInformation/utils';
const productEndpoint = '/Tracking/GetTrackingProduct';
const productsEndpoint = '/Tracking/GetTrackingProducts';
const transactionEndpoint = '/Tracking/GetTransaction';
const trackingVariantEndpoint = '/Tracking/GetTrackingVariant';
export function fetchProducts(variantCodes) {
    return __awaiter(this, void 0, void 0, function* () {
        let trackingProducts;
        if ((variantCodes === null || variantCodes === void 0 ? void 0 : variantCodes.length) > 0) {
            trackingProducts = yield postJson(productsEndpoint, {
                variantCodes,
            });
            trackingProducts = cleanBackendProducts(trackingProducts);
        }
        return trackingProducts;
    });
}
export function fetchProduct(productCode) {
    return __awaiter(this, void 0, void 0, function* () {
        let trackingProducts;
        if (productCode) {
            trackingProducts = yield postJson(productEndpoint, {
                productCode,
            });
            trackingProducts = cleanBackendProducts(trackingProducts);
        }
        return trackingProducts;
    });
}
export function fetchTrackingVariant(variantCode, addedQuantity) {
    return __awaiter(this, void 0, void 0, function* () {
        let trackingProducts;
        if (variantCode) {
            const response = yield postJson(trackingVariantEndpoint, {
                variantCode,
                addedQuantity,
            });
            trackingProducts = cleanBackendProducts([Object.assign(Object.assign({}, response), { id: variantCode })]);
        }
        return trackingProducts;
    });
}
export function fetchTransaction(id) {
    return __awaiter(this, void 0, void 0, function* () {
        const trackingTransaction = yield postJson(transactionEndpoint, { id });
        trackingTransaction.products = cleanBackendProducts(trackingTransaction.products);
        return trackingTransaction;
    });
}
export function cleanBackendProducts(products) {
    return products.map(trackingProduct => {
        let key;
        for (key in trackingProduct) {
            if (trackingProduct[key] === null) {
                delete trackingProduct[key];
            }
        }
        return trackingProduct;
    });
}
export function getCartTrackingProducts(items) {
    return __awaiter(this, void 0, void 0, function* () {
        if (items.length > 0) {
            const productsWithQuantityAndCorrectPrice = yield fetchProductsWithQuantityAndPrice(items);
            return productsWithQuantityAndCorrectPrice;
        }
        return [];
    });
}
let lastFetchedItems = [];
let lastFetchProductsPromise = Promise.resolve([]);
export function fetchProductsWithQuantityAndPrice(items) {
    return __awaiter(this, void 0, void 0, function* () {
        const mapTrackingProducts = (products) => {
            // Deduplicates mtm products of the same variant, unit and price
            const itemsDeduped = items.reduce((array, item) => {
                const match = array.find(i => {
                    var _a, _b, _c, _d;
                    return cleanVariationCode(i.code) === cleanVariationCode(item.code) &&
                        ((_a = i.additional) === null || _a === void 0 ? void 0 : _a.unit) === ((_b = item.additional) === null || _b === void 0 ? void 0 : _b.unit) &&
                        ((_c = i.totalPrice) === null || _c === void 0 ? void 0 : _c.exclVat) / i.quantity === ((_d = item.totalPrice) === null || _d === void 0 ? void 0 : _d.exclVat) / item.quantity;
                });
                if (match) {
                    match.quantity += item.quantity;
                    match.totalPrice.exclVat += item.totalPrice.exclVat;
                }
                else {
                    // Clone object to avoid updating any references
                    const clonedItem = JSON.parse(JSON.stringify(item));
                    array.push(clonedItem);
                }
                return array;
            }, []);
            return itemsDeduped
                .map(item => {
                var _a, _b;
                const trackingProduct = products.find(p => cleanVariationCode(p.id) === cleanVariationCode(item.code));
                // Get price from item instead of trackingProduct to avoid duplicating half-pair price logic
                const pricePerItem = ((_a = item.totalPrice) === null || _a === void 0 ? void 0 : _a.exclVat) / item.quantity;
                // Add custom mtm suffix for tracking
                let id = cleanVariationCode(item.code);
                if (((_b = item.additional.mtmMeasurements) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                    id += `_${item.additional.unit}`;
                }
                if (trackingProduct) {
                    return Object.assign(Object.assign({}, trackingProduct), { originalCode: item.code, id, quantity: item.quantity, price: formatPriceForGtm(pricePerItem) });
                }
            })
                .filter(Boolean);
        };
        const isSameLineItems = (items, lastItems) => {
            return (items === lastItems ||
                (items.length === lastItems.length && items.every((item, index) => item.code === lastItems[index].code)));
        };
        if (isSameLineItems(items, lastFetchedItems)) {
            /**
             * In order to avoid multiple calls for the same items we do cache the last request promise.
             * Example on where this will have effect:
             * - Closing and open the minicart without any changes
             * - usePaymentInfoTracking() & useShippingInfoTracking() both need the same tracking products and will invividually reqeuest it.
             *
             * Note that we are using quantity from LineItem so it's totally fine to use cached promise when only quantity has changed
             */
            const trackingProducts = yield lastFetchProductsPromise;
            return mapTrackingProducts(trackingProducts);
        }
        const fetchProductsPromise = fetchProducts(items.map(s => s.code));
        lastFetchedItems = items;
        lastFetchProductsPromise = fetchProductsPromise;
        const trackingProducts = yield fetchProductsPromise;
        return mapTrackingProducts(trackingProducts);
    });
}
export function getWishlistTrackingProducts(items) {
    return __awaiter(this, void 0, void 0, function* () {
        if ((items === null || items === void 0 ? void 0 : items.length) > 0) {
            const productsWithQuantityAndCorrectPrice = yield fetchWishlistProductsWithQuantityAndPrice(items);
            return productsWithQuantityAndCorrectPrice;
        }
        return [];
    });
}
let lastFetchedWishlistItems = [];
let lastFetchWishlistProductsPromise = Promise.resolve([]);
export function fetchWishlistProductsWithQuantityAndPrice(items) {
    return __awaiter(this, void 0, void 0, function* () {
        const mapProductsToproductsWithQuantityAndCorrectPrice = (products) => {
            const _products = [...new Set(products.map(obj => obj.id))].map(id => {
                return products.find(obj => obj.id === id);
            });
            const resProducts = _products.map(p => {
                var _a;
                const lineItem = items.find(item => item.code === p.id);
                const currentPricePerItem = (_a = lineItem === null || lineItem === void 0 ? void 0 : lineItem.price) === null || _a === void 0 ? void 0 : _a.minPrice.original.exclVat;
                return Object.assign(Object.assign({}, p), { quantity: 1, price: formatPriceForGtm(currentPricePerItem) });
            });
            return resProducts;
        };
        const isSameLineItems = (items, lastItems) => {
            const sameLineItems = items === lastItems ||
                (items.length === lastItems.length && items.every((item, index) => item.code === lastItems[index].code));
            return sameLineItems;
        };
        if (isSameLineItems(items, lastFetchedWishlistItems)) {
            /**
             * In order to avoid multiple calls for the same items we do cache the last request promise.
             * Example on where this will have effect:
             * - Closing and open the minicart without any changes
             * - usePaymentInfoTracking() & useShippingInfoTracking() both need the same tracking products and will invividually reqeuest it.
             *
             * Note that we are using quantity from LineItem so it's totally fine to use cached promise when only quantity has changed
             */
            const trackingProducts = yield lastFetchWishlistProductsPromise;
            return mapProductsToproductsWithQuantityAndCorrectPrice(trackingProducts);
        }
        const fetchProductsPromise = fetchProducts(items.map(s => s.code));
        lastFetchedWishlistItems = items;
        lastFetchWishlistProductsPromise = fetchProductsPromise;
        const trackingProducts = yield fetchProductsPromise;
        return mapProductsToproductsWithQuantityAndCorrectPrice(trackingProducts);
    });
}
