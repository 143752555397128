import { TOGGLE_SUGGESTION_RETAILERS, SET_SELECTED_SUGGESTED_RETAILER, TOGGLE_MAP_VIEW, TOGGLE_SEMI_VIEW, TOGGLE_LIST_VIEW, SET_SELECTED_RETAILER, } from './action-creators';
export default function (state = null, action) {
    if (state === null) {
        state = {
            isAutocompleteOpen: false,
            selectedSuggestRetailer: null,
            isMapView: true,
            isSemiView: false,
            isListView: false,
            selectedRetailer: null,
        };
    }
    switch (action.type) {
        case TOGGLE_SUGGESTION_RETAILERS: {
            const { isAutocompleteOpen } = action;
            return Object.assign(Object.assign({}, state), { isAutocompleteOpen });
        }
        case TOGGLE_MAP_VIEW: {
            const { isMapView } = action;
            return Object.assign(Object.assign({}, state), { isMapView });
        }
        case TOGGLE_SEMI_VIEW: {
            const { isSemiView } = action;
            return Object.assign(Object.assign({}, state), { isSemiView });
        }
        case TOGGLE_LIST_VIEW: {
            const { isListView } = action;
            return Object.assign(Object.assign({}, state), { isListView });
        }
        case SET_SELECTED_RETAILER:
            const { selectedRetailer } = action;
            return Object.assign(Object.assign({}, state), { selectedRetailer });
        case SET_SELECTED_SUGGESTED_RETAILER:
            const { selectedSuggestRetailer } = action;
            return Object.assign(Object.assign({}, state), { selectedSuggestRetailer });
        case TOGGLE_SUGGESTION_RETAILERS: {
            const { isAutocompleteOpen } = action;
            return Object.assign(Object.assign({}, state), { isAutocompleteOpen });
        }
        default:
            break;
    }
    return state;
}
