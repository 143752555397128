import React, { useEffect, useState } from 'react';
import Flyout, { Position, Header, Body as FlyoutBody } from 'Shared/Flyout';
import { useDispatch } from 'react-redux';
import { closeWishList } from './action-creators';
import { styled } from '@glitz/react';
import { Heart } from 'Shared/Icon';
import { translate, getJson } from '@avensia/scope';
import { medium, FontWeight, sourceSansProSemiBold } from 'Shared/Style';
import { useSelector } from 'Shared/State';
import { testIdProps } from '../../test-automation';
import { viewWishlistGa4Gracking } from 'TrackingInformation/ga4';
import WishListItem from './WishListItem';
import { P, Ul } from 'Shared/Generic';
const getItems = () => getJson('/wishlist/getProducts');
export default function WishListPanel() {
    const dispatch = useDispatch();
    const isOpen = useSelector(state => state.wishList.isOpen);
    const [list, setList] = useState(null);
    const wishListItems = useSelector(state => state.wishList.codes);
    const [hasBeenOpen, setHasBeenOpen] = useState(false);
    // Load the list every time the panel is opened, as only the codes are synced, we need to fetch the products
    useEffect(() => {
        if (isOpen) {
            getItems().then(items => setList(items));
            if (!hasBeenOpen) {
                setHasBeenOpen(true);
                viewWishlistGa4Gracking();
            }
        }
    }, [hasBeenOpen, isOpen]);
    function closePanel() {
        setHasBeenOpen(false);
        dispatch(closeWishList());
    }
    const filteredList = list ? list.filter(s => wishListItems.includes(s.code)) : [];
    return (React.createElement(Flyout, { position: Position.Right, onClose: closePanel, isOpen: isOpen },
        React.createElement(Base, Object.assign({}, testIdProps.WishlistFlyout),
            React.createElement(Header, { onClose: closePanel },
                React.createElement(StyledHeart, null),
                React.createElement(Title, null, translate('/Shared/Favorites'))),
            React.createElement(Body, null, filteredList.length > 0 ? (React.createElement(Ul, { column: true }, filteredList.map(product => (React.createElement(WishListItem, { key: product.code, item: product }))))) : (React.createElement(Empty, null, translate('/Account/Wishlist/Empty')))))));
}
const Base = styled.div({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
});
const Empty = styled(P, sourceSansProSemiBold(medium), {
    textAlign: 'center',
});
const StyledHeart = styled(Heart, {
    margin: { right: medium },
});
const Title = styled.span({
    fontWeight: FontWeight.SemiBold,
});
const Body = styled(FlyoutBody, {
    flex: {
        grow: 1,
        shrink: 1,
        basis: 'auto',
    },
    overflow: 'auto',
    position: 'relative',
});
