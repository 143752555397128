import { withTheme as wt, resolveThemeContextSyncIfLoadedOrThrow } from '@avensia/scope';
import { useSelector } from 'Shared/State';
/**
 * Helper for withTheme, ThemeProps are typed
 */
export const withTheme = (Component) => {
    return wt(Component);
};
/**
 * Get theming from render props
 */
export const ThemeRenderProps = withTheme(({ children, theme, setTheme, themeName }) => {
    return children({
        theme,
        setTheme,
        themeName,
    });
});
export function useCurrentTheme() {
    const currentThemeName = useSelector(state => state.currentTheme);
    return currentThemeName ? resolveThemeContextSyncIfLoadedOrThrow(currentThemeName) : null;
}
