import { createContext } from 'react';
export const CampaignBrandContext = createContext({
    themeColor: null,
    themeColorLight: null,
    themeColorDark: null,
    isCampaignPage: false,
    isTitlePositionBottom: false,
    campaignHeadline: null,
    isWithCampaignContent: false,
    campaignTitleColor: null,
});
