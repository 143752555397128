import React from 'react';
import { styled } from '@glitz/react';
import { LinkButton } from 'Shared/Button';
import { useSelector } from 'Shared/State';
import useToggleVat from 'Pricing/use-toggle-vat';
import { translate } from '@avensia/scope';
import { small } from 'Shared/Style';
function ToggleVat(props) {
    const displayPricesInclVat = useSelector(state => state.appShellData.displayPricesInclVat);
    const toggleVat = useToggleVat();
    return (React.createElement(styled.Div, null,
        displayPricesInclVat ? translate('/Vat/IncVatText') : translate('/Vat/ExVatText'),
        React.createElement(LinkButton, { onClick: toggleVat, css: __$hoisted_o0 }, translate('/Vat/Change'))));
}
export default styled(ToggleVat);
const __$hoisted_o0 = { marginLeft: small };
