import { SET_RETAILER_LIST } from './action-creators';
export default function (state = null, action) {
    if (state === null) {
        state = {
            retailerList: [],
        };
    }
    switch (action.type) {
        case SET_RETAILER_LIST:
            return Object.assign(Object.assign({}, state), { retailerList: action.retailerList });
        default:
            break;
    }
    return state;
}
