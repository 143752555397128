import { __rest } from "tslib";
import React from 'react';
import { styled } from '@glitz/react';
import AspectRatio from 'Shared/AspectRatio';
import Image, { ImageMode } from './index';
import { supportsObjectFit } from 'Shared/supports';
import { useBrowser } from 'Shared/Browser';
import { imageSizes } from 'Shared/image-sizes';
import { isImageUrl } from './utils';
export { Preset, Format, ImageMode } from './index';
export { Ratio } from 'Shared/AspectRatio';
const AspectRatioImage = styled((props) => {
    const { ratio, fill } = props, passProps = __rest(props, ["ratio", "fill"]);
    const browser = useBrowser();
    const aspectRatio = ratio || calcRatio(props.src, props.mode, props.preset);
    const imageFitStyle = { objectFit: fill ? 'fill' : 'cover' };
    if (!aspectRatio) {
        if (__DEV__) {
            console.warn(`Image ${props.src} is missing width or height, falling back to Image without AspectRatio`);
        }
        return React.createElement(Image, Object.assign({}, passProps));
    }
    return (React.createElement(AspectRatio, { ratio: aspectRatio }, supportsObjectFit(browser) ? (React.createElement(ModernImageFit, Object.assign({ css: imageFitStyle }, passProps))) : (React.createElement(LegacyImageFit, Object.assign({}, passProps)))));
}, {
    position: 'relative',
});
export default AspectRatioImage;
function calcRatio(src, mode, preset) {
    if (!(src && isImageUrl(src))) {
        return null;
    }
    const { width, height } = outputSize(src.width, src.height, mode, preset);
    return roundWithPrecision(height / width, 2);
}
export function outputSize(width, height, mode, preset, padRatio = 1) {
    if (typeof preset === 'number') {
        const ratio = height / width;
        width = mode === ImageMode.padding ? imageSizes[preset] : Math.min(width, imageSizes[preset]);
        height = mode === ImageMode.padding ? Math.round(imageSizes[preset] * padRatio) : Math.round(width * ratio);
    }
    return { width, height };
}
function roundWithPrecision(num, precision) {
    return Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision);
}
export const ModernImageFit = styled(Image, {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
});
export const LegacyImageFit = styled(Image, {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    margin: { xy: 'auto' },
});
