import { addUserLog, postJson } from '@avensia/scope';
import { trackAddToWishList, trackRemoveToWishList } from 'TrackingInformation';
export const OPEN_WISHLIST = 'OPEN_WISHLIST';
export const CLOSE_WISHLIST = 'CLOSE_WISHLIST';
export const ADD_WISHLIST_ITEM = 'ADD_WISHLIST_ITEM';
export const REMOVE_WISHLIST_ITEM = 'REMOVE_WISHLIST_ITEM';
export function openWishList() {
    addUserLog('Toggling wish list');
    return {
        type: OPEN_WISHLIST,
    };
}
export function closeWishList() {
    addUserLog('Close wish list');
    return {
        type: CLOSE_WISHLIST,
    };
}
export function addToWishList(productCode, variationCode) {
    return (dispatch) => {
        postJson('/wishlist/save', {
            productCode: productCode,
            variationCode: variationCode,
        }).then(() => {
            trackAddToWishList(productCode, variationCode);
            dispatch({
                type: ADD_WISHLIST_ITEM,
                productCode: variationCode || productCode,
            });
        });
    };
}
export function removeFromWishList(productCode, variationCode) {
    return (dispatch) => {
        postJson('/wishlist/deleteEntry', {
            productCode: productCode,
            variationCode: variationCode,
        }).then(() => {
            trackRemoveToWishList(productCode, variationCode);
            dispatch({
                type: REMOVE_WISHLIST_ITEM,
                productCode: variationCode || productCode,
            });
        });
    };
}
