import { __awaiter } from "tslib";
import React from 'react';
import { styled } from '@glitz/react';
import { translate } from '@avensia/scope';
import Input, { BareInput } from 'Shared/Fields/Text';
import { average, lightGrey, large, thin, medium, epsilon, iota, tiny } from 'Shared/Style';
import { DefaultButton, LinkButton, resetButtonDecorator } from 'Shared/Button';
import QuickOrderList from './List';
import { createFeedbackSlaveButton, useFeedback } from 'Shared/Button/Feedback';
import { testIdProps } from 'test-automation';
import Select from 'Shared/Fields/Select';
import { Close } from 'Shared/Icon';
import useWindowSize from 'Shared/use-window-size';
import { SpinnerLoader } from 'Shared/SpinnerLoader';
import BasicQuantity from 'Shared/BasicQuantity';
import { MAX_LIMIT_COUNT } from 'Shared/Quantity';
const CONTENT_HEIGHT = 295;
const B2B_CONTENT_HEIGHT = 355;
function CompactQuickOrder({ searchText, onSearchChange, onCustomerCommentChange, customerComment, quantityValue, isQuantityValid, updateIsQuantityValid, onSelectProduct, onUpdateQuantity, quickOrders, onClickAdd, showLoader, message, pairOptions, selectedPair, isHalfPairEnabled, onClickClear, onChangePair, onKeyPress, selectedOrder, isB2B, }) {
    const [addState, pushAdd] = useFeedback();
    const { height } = useWindowSize();
    const styledQuickOrderList = {
        height: isB2B ? height - B2B_CONTENT_HEIGHT : height - CONTENT_HEIGHT,
    };
    function updateQuantity(newValue) {
        onUpdateQuantity(newValue);
        return Promise.resolve();
    }
    function onClickInternalAdd() {
        return __awaiter(this, void 0, void 0, function* () {
            const action = () => __awaiter(this, void 0, void 0, function* () {
                onClickAdd();
                return Promise.resolve();
            });
            yield pushAdd(action());
        });
    }
    return (React.createElement(Base, null,
        React.createElement(Container, null,
            React.createElement(SearchInputWrapper, null,
                React.createElement(SearchInput, { type: "search", name: "searchphrase", autoComplete: "off", value: searchText, placeholder: translate('/QuickOrder/ArticleNumberEtc'), onChange: onSearchChange, onKeyDown: onKeyPress }),
                React.createElement(ClearLink, { onClick: onClickClear },
                    React.createElement(ClearIcon, null))),
            React.createElement(OrderListWrapper, { css: styledQuickOrderList },
                showLoader && (React.createElement(SpinnerWrapper, null,
                    React.createElement(SpinnerLoader, null))),
                (quickOrders === null || quickOrders === void 0 ? void 0 : quickOrders.length) > 0 ? (React.createElement(QuickOrderList, { open: true, orders: quickOrders, orderSearchText: searchText, onSelectOrder: (order) => onSelectProduct(order) })) : (message && !showLoader && React.createElement(Message, null, message))),
            isB2B && (React.createElement(CustomerCommentInput, { type: "text", value: customerComment, placeholder: translate('/QuickOrder/CustomerComment'), onChange: onCustomerCommentChange, disabled: !selectedOrder })),
            React.createElement(ActionWrapper, null,
                isHalfPairEnabled && (React.createElement(Select, { disabled: !selectedOrder, value: selectedPair, options: pairOptions, onChangeOption: onChangePair, id: "dropdown" })),
                React.createElement(QuantityWrapper, null,
                    React.createElement(BasicQuantity, { value: quantityValue, updateCurrentQuantity: newValue => updateQuantity(newValue), isQuantityValid: isQuantityValid, updateIsQuantityValid: updateIsQuantityValid, maxQuantity: MAX_LIMIT_COUNT, disabled: !selectedOrder })),
                React.createElement(AddButton, Object.assign({ state: addState }, testIdProps.AddQuickOrder, { title: 'add', disabled: !selectedOrder || !isQuantityValid, onClick: onClickInternalAdd }), translate('/QuickOrder/Add'))))));
}
export default styled(CompactQuickOrder);
const Base = styled.div({
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    overflow: 'hidden',
});
const SpinnerWrapper = styled.div({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
});
const Message = styled(SpinnerWrapper, {
    justifyContent: 'flex-start',
});
const Container = styled.div({
    width: '100%',
    position: 'relative',
});
const QuantityWrapper = styled.div({
    margin: {
        left: theme => (theme.isMobile ? 0 : medium),
        right: tiny,
    },
});
const ActionWrapper = styled.div({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    marginTop: medium,
    justifyContent: 'flex-start',
});
const CustomerCommentInput = styled(Input, {
    width: '100%',
});
const SearchInput = styled(BareInput, {
    padding: {
        left: large,
        right: iota,
        y: average,
    },
    backgroundColor: lightGrey,
    borderRadius: 30,
    width: '100%',
});
const SearchInputWrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
});
const ClearLink = styled(LinkButton, resetButtonDecorator, {
    position: 'absolute',
    padding: {
        y: epsilon,
        x: 0,
    },
    right: '7%',
});
const ClearIcon = styled(Close, {
    width: average - thin,
    height: average - thin,
});
const OrderListWrapper = styled.div({
    width: '100%',
    overflowY: 'scroll',
    margin: {
        top: large,
        bottom: large,
    },
});
const AddButton = styled(createFeedbackSlaveButton(DefaultButton), {
    border: { bottom: { style: 'none' } },
    width: theme => (theme.isMobile ? 150 : 'inherit'),
    maxWidth: theme => (theme.isMobile ? 150 : 'inherit'),
});
