import { getLocalStorageItem, setLocalStorageItem } from '@avensia/scope';
const LOCALSTORAGE_KEY = 'search_history';
const HISTORY_LENGTH = 30;
let searchHistory = deserialize();
export function getAll(words) {
    words = words.filter(word => word.length > 1);
    if (words.length === 0) {
        return searchHistory;
    }
    return searchHistory.filter(phrase => words.every(word => phrase.toLowerCase().includes(word.toLowerCase())));
}
export function add(phrase) {
    phrase = phrase.trim();
    if (phrase === '') {
        return;
    }
    searchHistory = searchHistory.filter(history => history !== phrase);
    searchHistory.unshift(phrase);
    if (searchHistory.length > HISTORY_LENGTH) {
        searchHistory.splice(HISTORY_LENGTH);
    }
    serialize();
}
function serialize() {
    if (__BROWSER__) {
        setLocalStorageItem(LOCALSTORAGE_KEY, searchHistory);
    }
}
function deserialize() {
    if (__BROWSER__) {
        return getLocalStorageItem(LOCALSTORAGE_KEY) || [];
    }
    return [];
}
