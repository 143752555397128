import React from 'react';
import { styled } from '@glitz/react';
import Item from './Item';
import { itemKey } from './item-helpers';
import { Ul } from 'Shared/Generic';
import { margin, sourceSansProSemiBold, figma, medium } from 'Shared/Style';
import { formatIcon, requireIfExists } from 'Shared/Icon/fontawesomefactory';
import Image from 'Shared/Image/Ratio';
export default function Level({ data }) {
    const items = isRoot(data) ? data : data.children || data.secondRowChildren || [];
    const faIcon = !isRoot(data) && (data === null || data === void 0 ? void 0 : data.fontAwesomeIcon);
    const filePath = requireIfExists(formatIcon(faIcon));
    return (React.createElement(Base, null,
        React.createElement(Ul, { column: true },
            !isRoot(data) && (React.createElement(Flex, null,
                faIcon !== null && !!filePath && React.createElement(StyledIcon, { src: { url: filePath }, lazy: true }),
                React.createElement(Parent, { url: data.url, css: { marginLeft: !!filePath ? 0 : 18 } }, data.name))),
            items.map((item, index) => {
                const formattedChildMenuIcon = formatIcon(item === null || item === void 0 ? void 0 : item.fontAwesomeIcon);
                return (React.createElement(Flex, { key: itemKey(item, index) },
                    requireIfExists(formatIcon(item === null || item === void 0 ? void 0 : item.fontAwesomeIcon)) !== null && (React.createElement(StyledIcon, { src: { url: requireIfExists(formattedChildMenuIcon) }, lazy: true })),
                    React.createElement(Item, Object.assign({}, item, { css: { marginLeft: requireIfExists(formattedChildMenuIcon) ? 0 : 18 } }))));
            }))));
}
export function isRoot(data) {
    return Array.isArray(data);
}
const Base = styled.li({
    display: 'flex',
    breakInside: 'avoid',
    listStyleType: 'none',
    marginBottom: margin.large,
    padding: { top: 16, x: 46 },
});
const Parent = styled(Item, sourceSansProSemiBold(figma));
const Flex = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
});
const StyledIcon = styled(Image, {
    width: medium,
});
