import React, { useState, createContext, useMemo, useContext, useCallback } from 'react';
import { forwardStyle, styled } from '@glitz/react';
import SlideIn from 'Shared/SlideIn';
const Context = createContext({ actionbar: null, setActionbar: () => { } });
export function ActionbarProvider(props) {
    const [actionbar, setActionbar] = useState(null);
    const value = useMemo(() => ({ actionbar, setActionbar }), [actionbar]);
    return React.createElement(Context.Provider, { value: value }, props.children);
}
export const ActionbarContainer = styled(() => {
    const { actionbar } = useContext(Context);
    return actionbar ? React.createElement(styled.Div, null, actionbar) : null;
});
export function useActionbar() {
    const { setActionbar } = useContext(Context);
    return useCallback(function set(node) {
        let realNode;
        function remove() {
            setActionbar(actionbar => (actionbar === realNode ? null : actionbar));
        }
        if (typeof node === 'function') {
            realNode = node(remove);
        }
        else {
            realNode = node;
        }
        setActionbar(realNode);
        return remove;
    }, [setActionbar]);
}
export default styled(forwardStyle(function Snackbar({ compose, children }) {
    return (React.createElement(SlideIn, { open: true }, style => (React.createElement(styled.Div, { css: compose(), style: style }, children))));
}), {
    pointerEvents: 'auto',
});
