import { styled } from '@glitz/react';
import { negativeDark, margin, monochromeText, monochromeDark, positiveDark, thick, white, radius } from 'Shared/Style';
export const neutralMessageStyled = styled({
    padding: { xy: margin.medium },
    color: monochromeText,
    backgroundColor: white,
    border: {
        xy: {
            color: monochromeDark,
            width: thick,
            style: 'solid',
        },
        radius: radius,
    },
});
export const NeutralMessage = neutralMessageStyled(styled.Div);
export const errorMessageStyled = neutralMessageStyled({
    color: negativeDark,
    backgroundColor: white,
    border: { xy: { color: negativeDark } },
});
export const ErrorMessage = errorMessageStyled(styled.Div);
export const successMessageStyled = neutralMessageStyled({
    color: positiveDark,
    backgroundColor: white,
    border: { xy: { color: positiveDark } },
});
export const SuccessMessage = successMessageStyled(styled.Div);
