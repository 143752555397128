import React from 'react';
import { useSelector } from 'Shared/State';
export default function FeatureToggler(props) {
    const featureToggles = useSelector(state => state.appShellData.featureToggles);
    const feature = featureToggles.find(p => p.name === props.featureName);
    if (feature === null || feature === void 0 ? void 0 : feature.enabled) {
        return React.createElement(React.Fragment, null, props.children);
    }
    else if (props.fallback) {
        return React.createElement(props.fallback, null);
    }
    else {
        return null;
    }
}
