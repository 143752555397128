import { useSelector } from 'Shared/State';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { toggleQuickOrderSearch as toggleQuickOrderSearchAction } from './action-creators';
export function useQuickOrderSearch() {
    const dispatch = useDispatch();
    const isOpen = useSelector(state => state.quickOrderSearch.isOpen);
    const toggleQuickOrderSearch = useCallback((e) => {
        dispatch(toggleQuickOrderSearchAction());
    }, [dispatch]);
    return { isOpen, toggleQuickOrderSearch };
}
