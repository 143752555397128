import React from 'react';
import { styled } from '@glitz/react';
import { Plus, Minus } from 'Shared/Icon';
import { BareInput, resetInputStyled } from 'Shared/Fields/Text';
import { selector } from '@glitz/core';
import { outlineGrey, thin, small, humongous, huge, visuallyHiddenDecorator, black05 } from 'Shared/Style';
import { PlainButton } from 'Shared/Button';
import { translate } from '@avensia/scope';
import { borderInvalidStyle } from 'Shared/Quantity';
function isValidQuantity(quantity, maxQuantity = 100) {
    if (typeof quantity !== 'number') {
        return false;
    }
    return quantity > 0 && quantity <= maxQuantity;
}
function parse(int) {
    return parseInt(int, 10);
}
export default class BasicQuantity extends React.Component {
    constructor(props) {
        super(props);
        this.isFocused = false;
        this.reduce = () => {
            const quantity = parse(this.state.proxyValue) - 1;
            if (isValidQuantity(quantity, this.props.maxQuantity)) {
                return this.change(quantity);
            }
            else {
                this.setState({
                    invalid: true,
                });
            }
        };
        this.add = () => {
            const quantity = (parse(this.state.proxyValue) || 0) + 1;
            if (isValidQuantity(quantity, this.props.maxQuantity)) {
                return this.change(quantity);
            }
            else {
                this.setState({
                    invalid: true,
                });
            }
        };
        this.state = {
            proxyValue: props.value,
            invalid: false,
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.isFocused) {
            return;
        }
        if (this.state.proxyValue !== nextProps.value) {
            this.setState({
                proxyValue: nextProps.value,
            });
        }
    }
    change(value) {
        const parsedValue = typeof value === 'number' ? value : parse(value);
        this.setState({
            proxyValue: parsedValue || '',
            invalid: parsedValue > (this.props.maxQuantity ? this.props.maxQuantity : 100),
        });
        this.props.updateCurrentQuantity(parsedValue);
        this.props.updateIsQuantityValid(isValidQuantity(parsedValue, this.props.maxQuantity));
    }
    render() {
        const quantityWrapperStyle = Object.assign(Object.assign({}, (this.props.disabled && { backgroundColor: black05, pointerEvents: 'none' })), (!this.props.isQuantityValid && borderInvalidStyle));
        return (React.createElement(QuantityWrapper, { css: quantityWrapperStyle },
            React.createElement(styled.Span, { css: {
                    display: 'inline-flex',
                    alignItems: 'center',
                    margin: { x: small },
                } },
                React.createElement(PlainButton, { onClick: this.reduce, "aria-label": translate('/Cart/DecreaseQuantity') },
                    React.createElement(Minus, { css: { fontSize: '1em', cursor: 'pointer' } })),
                React.createElement(Label, null,
                    React.createElement(HiddenForA11y, null, translate('/Shared/Quantity')),
                    React.createElement(Input, { type: "number", value: this.state.proxyValue, onChange: e => this.change(e.currentTarget.value), onFocus: () => {
                            this.isFocused = true;
                        }, css: { width: huge } })),
                React.createElement(PlainButton, { onClick: this.add, "aria-label": translate('/Cart/IncreaseQuantity') },
                    React.createElement(Plus, { css: { fontSize: '1em', cursor: 'pointer' } })))));
    }
}
const Input = styled(BareInput, resetInputStyled, Object.assign({ width: humongous, textAlign: 'center', margin: { x: small }, MozAppearance: 'textfield' }, selector(['::-webkit-outer-spin-button', '::-webkit-inner-spin-button'], {
    WebkitAppearance: 'none',
})));
const QuantityWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: small,
    border: { xy: { color: outlineGrey, width: thin, style: 'solid' } },
});
const HiddenForA11y = styled(styled.Span, visuallyHiddenDecorator);
const Label = styled(styled.Label, { display: 'flex', margin: { xy: 0 } });
