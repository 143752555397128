import { __rest } from "tslib";
import React, { forwardRef } from 'react';
import { styled } from '@glitz/react';
import Link from 'Shared/Link';
import { createLinkDecorator, linkDecorator, resetLinkDecorator } from 'Shared/Generic/A';
import { selector } from '@glitz/core';
import { General, general, primary, average, small, delta, createGeneralDecorator, tiny } from 'Shared/Style';
import { createTabbingFocusElement } from 'Shared/a11y/TabFocus';
import { ThemeButton } from './Themed';
export { ThemeButton, ThemeButtonPropType, Theme, Casing } from './Themed';
const ButtonLink = styled(forwardRef((_a, ref) => {
    var { disabled, children } = _a, restProps = __rest(_a, ["disabled", "children"]);
    return (React.createElement(Link, Object.assign({}, restProps, { ref: ref, onClick: (e) => {
            if (disabled) {
                e.preventDefault();
            }
            else if (restProps.onClick) {
                restProps.onClick(e);
            }
        } }), children));
}), resetLinkDecorator, {
    ':hover': {
        textDecoration: 'none',
    },
});
/**
 * Be aware that you should utalize createTabbingFocusElement() if you just apply this on a styled.Button
 * Maybe you just want to use PlainButton?
 */
export const resetButtonDecorator = styled({
    font: {
        family: 'inherit',
        size: 'inherit',
        style: 'inherit',
        variant: 'inherit',
        weight: 'inherit',
    },
    border: { xy: { width: 0 } },
    padding: { xy: 0 },
    userSelect: 'none',
    color: 'inherit',
    textDecoration: 'none',
    textAlign: 'inherit',
    backgroundColor: ['transparent', 'initial'],
    cursor: 'pointer',
    ':focus': {
        outlineWidth: 0,
    },
});
const ButtonNotLink = createTabbingFocusElement(styled(forwardRef((_a, ref) => {
    var { type = 'button', children } = _a, restProps = __rest(_a, ["type", "children"]);
    return (React.createElement(styled.Button, Object.assign({}, restProps, { type: type, ref: ref, onClick: e => {
            if (restProps.onClick) {
                restProps.onClick(e);
            }
        } }), children));
}), resetButtonDecorator));
export const PlainButton = createTabbingFocusElement(styled(forwardRef((props, ref) => isLink(props) ? (React.createElement(ButtonLink, Object.assign({}, props, { ref: ref }))) : (React.createElement(ButtonNotLink, Object.assign({}, props, { ref: ref }))))));
function isLink(props) {
    return 'to' in props;
}
export const TextLink = styled(PlainButton, { display: 'inline' });
export const LinkButton = styled(PlainButton, linkDecorator, { display: 'inline' });
export const SmallButton = styled(ThemeButton, createGeneralDecorator(General.Small));
export const DefaultButton = styled(ThemeButton, createGeneralDecorator(General.Default));
export const LargeButton = styled(ThemeButton, createGeneralDecorator(General.Large));
// export const DirectionButton = styled(ThemeButton, { padding: 0, fontSize: 16, height: 41 });
const Button = styled(ThemeButton, createGeneralDecorator());
export default Button;
export const createSquareButtonStyled = (type = General.Default) => styled({
    minWidth: general(type).height,
    padding: { x: 0 },
}, createGeneralDecorator(type));
export const createRoundButtonStyled = (type = General.Default) => styled({ borderRadius: '100%' }, createSquareButtonStyled(type));
export const createSquareButtonDecorator = (type = General.Default) => styled(createGeneralDecorator(type), {
    minWidth: general(type).height,
    padding: { x: 0 },
});
export const createRoundButtonDecorator = (type = General.Default) => styled(createSquareButtonDecorator(type), { borderRadius: '100%' });
export const SquareButton = styled(ThemeButton, createSquareButtonStyled());
export const LargeSquareButton = styled(ThemeButton, createSquareButtonDecorator(General.Large));
export const RoundButton = styled(ThemeButton, createRoundButtonDecorator());
export const LargeRoundButton = styled(ThemeButton, createRoundButtonDecorator(General.Large));
export const LinkStyledButton = styled(LinkButton, createLinkDecorator(Object.assign({ display: 'flex', alignItems: 'center', padding: { x: average, y: small }, position: 'relative', textDecoration: 'none', fontSize: delta, color: primary, borderRadius: tiny }, selector(':hover', {
    backgroundColor: ({ siteTheme }) => siteTheme.navbar.hoverBackgroundColor,
}))));
export const fullButtonStyled = styled({ width: '100%' });
export const quarterButtonStyled = styled({ width: '25%', padding: { x: 32 } });
export const BareButton = styled(PlainButton, resetButtonDecorator);
export const fullButtonDecorator = styled({ width: '100%', padding: { x: 0 } });
