export const SHOW_SYSTEM_TEXT = 'SHOW_SYSTEM_TEXT';
export const HIDE_SYSTEM_TEXT = 'HIDE_SYSTEM_TEXT';
const DEFAULT_TIMEOUT = 5000;
let lastTimer;
export function showSystemText(text, timeout = DEFAULT_TIMEOUT) {
    return (dispatch) => {
        dispatch({
            type: SHOW_SYSTEM_TEXT,
            text,
        });
        if (typeof timeout === 'number') {
            const timer = (lastTimer = setTimeout(() => {
                if (timer === lastTimer) {
                    dispatch(hideSystemText());
                }
            }, timeout));
        }
    };
}
export function hideSystemText() {
    return {
        type: HIDE_SYSTEM_TEXT,
    };
}
