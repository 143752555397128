import { addUserLog } from '@avensia/scope';
export const TOGGLE_SUGGESTION_RETAILERS = 'TOGGLE_SUGGESTION_RETAILERS';
export const SET_SELECTED_SUGGESTED_RETAILER = 'SET_SELECTED_SUGGESTED_RETAILER';
export const TOGGLE_MAP_VIEW = 'TOGGLE_MAP_VIEW';
export const TOGGLE_SEMI_VIEW = 'TOGGLE_SEMI_VIEW';
export const TOGGLE_LIST_VIEW = 'TOGGLE_LIST_VIEW';
export const TOGGLE_SHOW_RETAILER_LIST = 'TOGGLE_SHOW_RETAILER_LIST';
export const SET_SELECTED_RETAILER = 'SET_SELECTED_RETAILER';
export function toggleMapView(isMapView) {
    return {
        type: TOGGLE_MAP_VIEW,
        isMapView,
    };
}
export function toggleSemiView(isSemiView) {
    return {
        type: TOGGLE_SEMI_VIEW,
        isSemiView,
    };
}
export function toggleListView(isListView) {
    return {
        type: TOGGLE_LIST_VIEW,
        isListView,
    };
}
export function toggleSuggestionRetailers(isOpen) {
    return {
        type: TOGGLE_SUGGESTION_RETAILERS,
        isAutocompleteOpen: isOpen,
    };
}
export function toggleUpdateRetailerList(isShowRetailerList) {
    return {
        type: TOGGLE_SHOW_RETAILER_LIST,
        isShowRetailerList,
    };
}
export function setSelectedSuggestRetailer(selectedSuggestRetailer) {
    addUserLog('Setting selected suggested retailer');
    return {
        type: SET_SELECTED_SUGGESTED_RETAILER,
        selectedSuggestRetailer,
    };
}
export function setSelectedRetailer(selectedRetailer) {
    addUserLog('Setting selected retailer');
    return {
        type: SET_SELECTED_RETAILER,
        selectedRetailer,
    };
}
