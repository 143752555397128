// This file has been generated by the Scope build. Do not alter
// it manually as your changes will be overwritten. Run:
// scope.cmd frontend:build-component-registry
// instead.
/* tslint:disable */
// prettier-ignore
import { setComponentRegistry, setThemeContextCreatorRegistry } from '@avensia/scope';
// prettier-ignore
export { default as componentNames } from './component-names';
// prettier-ignore
const componentRegistry = {
    'Avensia.Common.Features.Account.ForgotPassword.ForgotPasswordBlock': (theme) => import('../Account/ForgotPassword/ForgotPasswordBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Account.Login.LoginBlock': (theme) => import('../Account/Login/LoginBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Account.MyPages.ChangePassword.ChangePasswordPageViewModel': (theme) => import('../Account/MyPages/ChangePassword/ChangePasswordPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Account.Member.Logout.LogoutPageViewModel': (theme) => import('../Account/MyPages/Logout/LogoutPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Account.MyPages.MyAddresses.MyAddressDetails.MyAddressDetailsViewModel': (theme) => import('../Account/MyPages/MyAddresses/MyAddressDetails/MyAddressDetailsPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Account.MyPages.MyAddresses.MyAddressesPageViewModel': (theme) => import('../Account/MyPages/MyAddresses/MyAddressesPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Account.MyPages.MyDetails.MyDetailsB2CPageViewModel': (theme) => import('../Account/MyPages/MyDetails/MyDetailsB2CPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Account.MyPages.MyDetails.MyDetailsB2BPageViewModel': (theme) => import('../Account/MyPages/MyDetails/MyDetailsPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Account.MyPages.MyOrderDetails.MyOrderDetailsPageViewModel': (theme) => import('../Account/MyPages/MyOrderDetails/MyOrderDetailsPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Account.MyPages.MyOrderDetails.OrderPageExtraTextBlock': (theme) => import('../Account/MyPages/MyOrderDetails/OrderPageExtraTextBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Account.MyPages.MyOrders.MyOrdersPageViewModel': (theme) => import('../Account/MyPages/MyOrders/MyOrdersPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Account.MyPages.MyWishList.MyWishListPageViewModel': (theme) => import('../Account/MyPages/MyWishlist/MyWishListPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Account.Register.RegisterBlock': (theme) => import('../Account/Register/RegisterBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.AppShell.Partials.AppShellViewModel': (theme) => import('../AppShell/Partials/AppShell').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Blog.BlogArchive.BlogArchivePageViewModel': (theme) => import('../Blog/BlogArchive/BlogArchivePage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Blog.BlogCategory.BlogCategoryPageViewModel': (theme) => import('../Blog/BlogCategory/BlogCategoryPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Blog.BlogPost.BlogPostViewModel': (theme) => import('../Blog/BlogPost/BlogPost').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Brand.BrandListViewModel': (theme) => import('../Brand/BrandListPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Brand.BrandViewModel': (theme) => import('../Brand/BrandPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Campaigns.CampaignOverviewPageViewModel': (theme) => import('../Campaigns/CampaignOverviewPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Campaigns.CampaignPage': (theme) => import('../Campaigns/CampaignPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Category.CategoryViewModel': (theme) => import('../Category/CategoryPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Checkout.CheckoutPage.CheckoutPageViewModel': (theme) => import('../Checkout/CheckoutPage/CheckoutPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Checkout.CheckoutPage.CheckoutPageExtraTextBlock': (theme) => import('../Checkout/CheckoutPage/CheckoutPageExtraTextBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.EpiFoundation.Shipping.CheckoutModels.ShippingMethod': (theme) => import('../Checkout/CheckoutPage/ShippingMethod').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Checkout.PaymentMethods.InvoicePayment.InvoicePaymentViewModel': (theme) => import('../Checkout/PaymentMethods/InvoicePayment/InvoicePayment').then(m => ({ component: m.default, theme: null })),
    'Avensia.Payments.PayPal.PayPalCheckoutPaymentMethodViewModel': (theme) => import('../Checkout/PaymentMethods/PayPal/Checkout/PayPalCheckoutPaymentMethod').then(m => ({ component: m.default, theme: null })),
    'Avensia.Payments.PayPal.PayPalCreditCardPaymentMethodViewModel': (theme) => import('../Checkout/PaymentMethods/PayPal/CreditCard/PayPalCreditCardPaymentMethod').then(m => ({ component: m.default, theme: null })),
    'Avensia.Payments.Stripe.StripePaymentMethodViewModel': (theme) => import('../Checkout/PaymentMethods/Stripe/StripePaymentMethod').then(m => ({ component: m.default, theme: null })),
    'Avensia.Payments.TestPayment.TestPaymentMethodViewModel': (theme) => import('../Checkout/PaymentMethods/Test/TestPaymentMethod').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.ContactForm.ContactUs.ContactUsBlockViewModel': (theme) => import('../ContactForm/ContactUs').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Content.ContentPageViewModel': (theme) => import('../Content/ContentPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Error.Pages.PageNotFoundViewModel': (theme) => import('../Error/Pages/PageNotFound').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.FindRetailer.Blocks.FeaturedRetailerBlock': (theme) => import('../FindRetailer/Blocks/FeaturedRetailerBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.FindRetailer.Blocks.FindRetailerFilterBlock': (theme) => import('../FindRetailer/Blocks/FindRetailerFilterBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.FindRetailer.Models.FindRetailerPageViewModel': (theme) => import('../FindRetailer/FindRetailerPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.LegReader.LegReaderPageViewModel': (theme) => import('../LegReader/LegReaderPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.OrderConfirmation.OrderConfirmationPageViewModel': (theme) => import('../OrderConfirmation/OrderConfirmationPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Pages.Article.Models.ArticlePageViewModel': (theme) => import('../Pages/Article/ArticlePage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Pages.Campaign.Models.CampaignBrandPageViewModel': (theme) => import('../Pages/Campaign/CampaignBrandPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Pages.Models.ContentOverviewPageViewModel': (theme) => import('../Pages/ContentOverviewPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Pages.Cookies.Models.CookiesPageViewModel': (theme) => import('../Pages/Cookies/CookiesPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Pages.Events.Models.EventPageViewModel': (theme) => import('../Pages/Events/EventPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Pages.News.Models.NewsPageViewModel': (theme) => import('../Pages/News/NewsPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Product.ProductViewModel': (theme) => import('../Product/ProductPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.ProductListing.ProductListingPageViewModel': (theme) => import('../ProductListing/ProductListingPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.ResetPassword.ResetPasswordPageViewModel': (theme) => import('../ResetPassword/ResetPasswordPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Search.SearchPageViewModel': (theme) => import('../Search/SearchPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.Accordion.AccordionHolderBlockViewModel': (theme) => import('./Blocks/Accordion/AccordionHolderBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.AnchorLink.AnchorLinkBlock': (theme) => import('./Blocks/AnchorLink/AnchorLinkBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.Author.AuthorBlock': (theme) => import('./Blocks/Author/AuthorBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.Campaign.CampaignBlockViewModel': (theme) => import('./Blocks/Campaign/CampaignBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.CTA.CallToActionBlock': (theme) => import('./Blocks/CTA/CallToActionBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.DXCProducts.ProductListBlockViewModel': (theme) => import('./Blocks/DXCProducts/ProductsBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.Editor.EditorBlock': (theme) => import('./Blocks/Editor/EditorBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.Elfsight.ElfsightBlockViewModel': (theme) => import('./Blocks/Elfsight/ElfsightBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.EsalesProducts.ProductListBlockViewModel': (theme) => import('./Blocks/EsalesProducts/ProductsBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.Image.ImageBlockViewModel': (theme) => import('./Blocks/Image/ImageBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.ImageGallery.Models.MediaGalleryBlockViewModel': (theme) => import('./Blocks/ImageGallery/MediaGalleryBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.Instagram.InstagramBlockViewModel': (theme) => import('./Blocks/Instagram/InstagramBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.Jotform.JotformBlockViewModel': (theme) => import('./Blocks/Jotform/JotformBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.Navigation.NavigationBlockViewModel': (theme) => import('./Blocks/Navigation/NavigationBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.Ostendis.OstendisBlock': (theme) => import('./Blocks/Ostendis/OstendisBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.PromoContentIn.PromoContentInBlockViewModel': (theme) => import('./Blocks/PromoContentIn/PromoContentInBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.RowLayout.RowLayoutBlockViewModel': (theme) => import('./Blocks/RowLayout/RowLayoutBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.StyleGuide.Models.StyleGuideViewModel': (theme) => import('./Blocks/StyleGuide/StyleGuideBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.Typeform.TypeformBlockViewModel': (theme) => import('./Blocks/Typeform/TypeformBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.Usp.UspBlockViewModel': (theme) => import('./Blocks/Usp/UspBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.Video.VideoBlock': (theme) => import('./Blocks/Video/VideoBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.ContentTeaser.ContentTeaserBlockViewModel': (theme) => import('./ContentTeaser/ContentTeaserBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.GenericErrorViewModel': (theme) => import('./GenericErrorPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.ContentTypes.Media.ImageDataModel': (theme) => import('./Partials/ImageDataModel').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Partials.PreviewViewModel': (theme) => import('./Partials/Preview').then(m => ({ component: m.default, theme: null })),
    'EPiServer.Core.XhtmlString': (theme) => import('./Partials/PropertyTypes/Html').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.SiteBanner.SiteBannerBlock': (theme) => import('./SiteBanner/SiteBannerBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Start.StartPageViewModel': (theme) => import('../Start/StartPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Stores.NearestStore.NearestStoreBlockViewModel': (theme) => import('../Stores/NearestStore/NearestStoreBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Stores.StoreListPageViewModel': (theme) => import('../Stores/StoreListPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Stores.StorePageViewModel': (theme) => import('../Stores/StorePage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Storybook.StorybookViewModel': (theme) => import('../Storybook/StorybookPage').then(m => ({ component: m.default, theme: null })),
    'EPiServer.Core.ContentArea': (theme) => import('@avensia/scope-episerver/dist/PropertyTypes/ContentArea').then(m => ({ component: m.default, theme: null })),
    'EPiServer.SpecializedProperties.LinkItemCollection': (theme) => import('@avensia/scope-episerver/dist/PropertyTypes/LinkItemCollection').then(m => ({ component: m.default, theme: null })),
};
// prettier-ignore
setComponentRegistry(componentRegistry);
// prettier-ignore
export default componentRegistry;
// prettier-ignore
const themeContextCreatorRegistry = {
    moh: () => import(/* webpackChunkName: "moh" */ '../SiteLayout/Theming/moh').then(m => m.default),
    oedema: () => import(/* webpackChunkName: "oedema" */ '../SiteLayout/Theming/oedema').then(m => m.default),
    sigvaris: () => import(/* webpackChunkName: "sigvaris" */ '../SiteLayout/Theming/sigvaris').then(m => m.default),
};
// prettier-ignore
setThemeContextCreatorRegistry(themeContextCreatorRegistry);
