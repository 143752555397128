import React, { useEffect, useState } from 'react';
import { styled } from '@glitz/react';
import { translate, useDynamicData } from '@avensia/scope';
import { useDispatch } from 'react-redux';
import { findItem, findParentKey, itemKey } from '../MainMenu/item-helpers';
import Flyout, { Position, Header } from 'Shared/Flyout';
import Nav from './Nav';
import { delta, huge, medium, large, small, linkColor, figma } from 'Shared/Style';
import { useMainMenu } from 'SiteLayout/MainMenu/use-mainmenu';
import { PlainButton } from 'Shared/Button';
import { openAccountPanel } from 'Account/action-creators';
import { getCurrentUser } from 'Shared/dynamic-data';
import { ArrowLeft, Heart, Contact, User, FindRetailer, Store } from 'Shared/Icon';
import { useSelector } from 'Shared/State';
import { isRoot } from 'SiteLayout/MainMenu/Level';
import { contactUsPageUrl, findRetailerUrl, myDetailsPageUrl, dealerPortalPageUrl } from 'Shared/known-urls';
import { toggleCountrySelector } from 'SiteLayout/CountrySelector/action-creators';
import { openWishList } from 'SiteLayout/WishList/action-creators';
import { getFlagIconForLanguage } from 'SiteLayout/CountrySelector';
import { addToDataLayer, FindRetailerId, GA4FindRetailer } from 'TrackingInformation';
import { createFindRetailerClickGa4 } from 'TrackingInformation/ga4';
import { MarketType } from 'Shared/constants';
import CTAButton from 'SiteLayout/Header/CTAButton';
export default function Drawer() {
    const dispatch = useDispatch();
    const { mainMenuItems, isMainMenuOpen, openMenuItem, closeMainMenu, openMainMenu, setHighlightedRowMainMenu, highlightedRowMainMenu, } = useMainMenu();
    const currentUser = useDynamicData(getCurrentUser(), { shouldResolveData: true });
    const currentItem = openMenuItem || mainMenuItems;
    const availableLanguages = useSelector(state => state.appShellData.availableLanguages);
    const currentCountry = useSelector(state => state.appShellData.currentCountry);
    const currentLanguage = availableLanguages.find(language => language.market === currentCountry.alpha3);
    const marketType = useSelector(state => state.appShellData.countrySettings.marketType);
    const hideWishList = useSelector(state => state.appShellData.siteSettings.hideWishListIcon);
    const hideContact = useSelector(state => state.appShellData.siteSettings.hideContactIcon);
    const hideLanguageSelector = useSelector(state => state.appShellData.siteSettings.hideLanguageSelectorIcon);
    const isFindRetailerExternal = useSelector(state => state.appShellData.siteSettings.isFindRetailerPageExternal);
    function setLevel(item) {
        openMainMenu(item || null);
    }
    function popLevel() {
        const parentKey = findParentKey(mainMenuItems, itemKey(openMenuItem));
        setLevel(findItem(mainMenuItems, parentKey));
    }
    function loginClick() {
        dispatch(openAccountPanel());
    }
    function changeLanguageClick() {
        dispatch(toggleCountrySelector());
    }
    function wishListClick() {
        dispatch(openWishList());
    }
    function handleClickFindRetailer() {
        addToDataLayer(createFindRetailerClickGa4(GA4FindRetailer.HEADER));
    }
    /*
      Puts all children of highlighted rows of MainMenuLinkWithTwoRowsBlock in an array so
      that we can keep track of the title color and highlight status below and in Level.tsx
     */
    const [titleColor, setTitleColor] = useState('inherit');
    const [isSecondRowHighlight, setIsSecondRowHighlight] = useState(false);
    const [isFirstRowHighlight, setIsFirstRowHighlight] = useState(false);
    useEffect(() => {
        if ((mainMenuItems === null || mainMenuItems === void 0 ? void 0 : mainMenuItems.length) > 0) {
            const resultMenuItems = mainMenuItems.filter(menuItem => menuItem.isFirstRowHighlight || menuItem.isSecondRowHighlight);
            if (resultMenuItems.length > 0) {
                const highlights = [];
                for (const item of resultMenuItems) {
                    if (item.isFirstRowHighlight) {
                        highlights.push(item.children);
                    }
                    else if (item.isSecondRowHighlight) {
                        highlights.push(item.secondRowChildren);
                    }
                }
                setHighlightedRowMainMenu(highlights.flat());
                setIsSecondRowHighlight(resultMenuItems[0].isSecondRowHighlight);
                setIsFirstRowHighlight(resultMenuItems[0].isFirstRowHighlight);
            }
        }
    }, [mainMenuItems, setHighlightedRowMainMenu]);
    useEffect(() => {
        if ((highlightedRowMainMenu === null || highlightedRowMainMenu === void 0 ? void 0 : highlightedRowMainMenu.length) > 0 && (mainMenuItems === null || mainMenuItems === void 0 ? void 0 : mainMenuItems.length) > 0) {
            function setActiveMenuItem(menuItems) {
                var _a;
                setTitleColor('inherit');
                for (const item of menuItems) {
                    const currentMenuId = !isRoot(currentItem) && (currentItem === null || currentItem === void 0 ? void 0 : currentItem.menuId);
                    if (item !== null && (item === null || item === void 0 ? void 0 : item.menuId) === currentMenuId) {
                        setTitleColor(linkColor);
                        break;
                    }
                    if (((_a = item === null || item === void 0 ? void 0 : item.children) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                        setActiveMenuItem(item === null || item === void 0 ? void 0 : item.children);
                    }
                }
            }
            if ((highlightedRowMainMenu === null || highlightedRowMainMenu === void 0 ? void 0 : highlightedRowMainMenu.length) > 0 && !isRoot(currentItem)) {
                setActiveMenuItem(highlightedRowMainMenu);
            }
        }
    }, [currentItem, mainMenuItems, highlightedRowMainMenu]);
    return (React.createElement(Flyout, { position: Position.Right, onClose: closeMainMenu, isOpen: isMainMenuOpen },
        React.createElement(Header, { onClose: closeMainMenu, css: { padding: { x: isRoot(currentItem) ? medium : huge } } }, isRoot(currentItem) ? (React.createElement(Title, null, translate('/MainMenu/Title'))) : (React.createElement(React.Fragment, null,
            React.createElement(Back, { onClick: popLevel, "aria-label": translate('/Shared/Back') },
                React.createElement(ArrowLeft, null)),
            React.createElement(Title, { css: {
                    color: titleColor,
                } }, currentItem.name)))),
        React.createElement(Nav, { primary: currentItem, setLevel: setLevel, popLevel: popLevel, isSecondRowHighlight: isSecondRowHighlight, isFirstRowHighlight: isFirstRowHighlight },
            React.createElement(ExtraLinks, null,
                dealerPortalPageUrl() && (React.createElement(NavItem, { to: dealerPortalPageUrl(), target: "_blank" },
                    React.createElement(Content, null,
                        React.createElement(Store, null),
                        React.createElement(Label, null, translate('/MainMenu/DealerPortal'))))),
                findRetailerUrl() && (React.createElement(NavItem, { onClick: handleClickFindRetailer, to: findRetailerUrl(), id: FindRetailerId.GEO_LOCATION_BUTTON, target: isFindRetailerExternal ? '_blank' : '_self' },
                    React.createElement(Content, null,
                        React.createElement(FindRetailer, null),
                        React.createElement(Label, null, translate('/MainMenu/FindRetailer'))))),
                !hideWishList && marketType !== MarketType.NO_COMMERCE && (React.createElement(NavItem, { onClick: wishListClick },
                    React.createElement(Content, null,
                        React.createElement(Heart, null),
                        React.createElement(Label, null, translate('/Shared/Favorites'))))),
                marketType !== MarketType.NO_COMMERCE &&
                    ((currentUser === null || currentUser === void 0 ? void 0 : currentUser.isLoggedIn) ? (React.createElement(NavItem, { to: myDetailsPageUrl() },
                        React.createElement(Content, null,
                            React.createElement(User, null),
                            React.createElement(Label, null, translate('/MainMenu/Account'))))) : (React.createElement(NavItem, { onClick: loginClick },
                        React.createElement(Content, null,
                            React.createElement(User, null),
                            React.createElement(Label, null, translate('/MainMenu/Login')))))),
                !hideContact && (React.createElement(NavItem, { to: contactUsPageUrl() },
                    React.createElement(Content, null,
                        React.createElement(Contact, null),
                        React.createElement(Label, null, translate('/MainMenu/ContactUs'))))),
                !hideLanguageSelector && (React.createElement(NavItem, { onClick: changeLanguageClick },
                    React.createElement(Content, null,
                        getFlagIconForLanguage(currentLanguage === null || currentLanguage === void 0 ? void 0 : currentLanguage.culture, currentLanguage === null || currentLanguage === void 0 ? void 0 : currentLanguage.flagOverride),
                        React.createElement(Label, null, currentLanguage === null || currentLanguage === void 0 ? void 0 : currentLanguage.name)))),
                React.createElement(NavItem, null,
                    React.createElement(Content, null,
                        React.createElement(CTAButton, null)))))));
}
const Label = styled.span({
    marginLeft: medium,
    display: 'flex',
    alignItems: 'center',
    fontSize: figma,
});
const Content = styled.div({
    width: '100%',
    display: 'flex',
    alignItems: 'self-end',
});
const Title = styled.span({
    flexGrow: 1,
    flexShrink: 0,
    fontSize: delta,
});
const ExtraLinks = styled.nav({
    marginTop: huge,
});
const NavItem = styled(PlainButton, {
    display: 'block',
    padding: {
        x: medium,
        y: large / 2,
    },
});
const Back = styled(PlainButton, {
    position: 'absolute',
    minWidth: '30%',
    left: small,
    padding: {
        y: small,
    },
});
