// Have properties order and preferred `z-index` order accordingly
// and make sure it always starts with 1
export var ZIndex;
(function (ZIndex) {
    ZIndex[ZIndex["ProductBadge"] = 1] = "ProductBadge";
    ZIndex[ZIndex["Header"] = 2] = "Header";
    ZIndex[ZIndex["QuickOrder"] = 3] = "QuickOrder";
    ZIndex[ZIndex["Tooltip"] = 4] = "Tooltip";
    ZIndex[ZIndex["SkipToContent"] = 5] = "SkipToContent";
    ZIndex[ZIndex["Tray"] = 6] = "Tray";
    ZIndex[ZIndex["Overlay"] = 7] = "Overlay";
    ZIndex[ZIndex["Spinner"] = 8] = "Spinner";
})(ZIndex || (ZIndex = {}));
