import { tiny, small, medium, large, huge, gigantic, humongous } from './values';
export const margin = {
    tiny: () => tiny,
    small: () => small,
    medium: ({ isCompact }) => (isCompact ? small : medium),
    large: ({ isCompact }) => (isCompact ? medium : large),
    huge: ({ isCompact }) => (isCompact ? large : huge),
    gigantic: ({ isCompact }) => (isCompact ? huge : gigantic),
    humongous: ({ isCompact }) => (isCompact ? gigantic : humongous),
};
