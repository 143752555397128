import React, { useCallback, useRef } from 'react';
import { styled, applyClassName } from '@glitz/react';
import { Root, CurrentPage, isBrowser, translate } from '@avensia/scope';
import { ContentArea } from '@avensia/scope-episerver';
import FullHeader from './Header/Full';
import CompactHeader from './Header/Compact';
import MainFooter from './Footer';
import MiniCart from './MiniCart';
import Drawer from './Drawer';
import Loader from './Loader';
import NetworkError from './NetworkError';
import Tray from './Tray';
import { SnackbarProvider, SnackbarContainer } from './Tray/Snackbar';
import { ActionbarProvider, ActionbarContainer } from './Tray/Actionbar';
import { useSelector } from 'Shared/State';
import { useIsCompactHeader } from 'Shared/use-viewport';
import SystemText from 'Shared/SystemText';
import ErrorBoundary from 'Shared/ErrorBoundary';
import CountrySelector from './CountrySelector';
import WishListPanel from './WishList/WishListPanel';
import { RootContextProvider, RootContext } from 'Shared/RootContext';
import { TabbingProvider, createFocusOnlyVisibleElement } from 'Shared/a11y/TabFocus';
import { PlainButton } from 'Shared/Button';
import { margin, ZIndex } from 'Shared/Style';
import { getFocusableElements } from 'Shared/a11y/focusable-elements';
// Prevent font awesome from dynamically adding its css since we did it manually in global.scss
import { config } from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false;
function SiteLayout(props) {
    var _a;
    const currentPage = useSelector(state => state.currentPage);
    renderMeta(currentPage);
    renderFQDNAlternateLinks(currentPage);
    const isCompactHeader = useIsCompactHeader();
    const siteBanner = useSelector(state => state.appShellData.siteBanner);
    const systemText = useSelector(state => state.systemText);
    const isQuickOrderEnabled = useSelector(state => state.appShellData.isQuickOrderEnabled);
    const availableLanguages = useSelector(state => state.appShellData.availableLanguages);
    const contentElementRef = useRef();
    const onSkipToContentClick = useCallback(() => {
        var _a, _b;
        (_b = (_a = getFocusableElements(contentElementRef.current)[0]) === null || _a === void 0 ? void 0 : _a.focus) === null || _b === void 0 ? void 0 : _b.call(_a);
    }, []);
    return (React.createElement(ErrorBoundary, null,
        React.createElement(ActionbarProvider, null,
            React.createElement(SnackbarProvider, null,
                React.createElement(TabbingProvider, null,
                    React.createElement(RootContextProvider, null,
                        React.createElement(Base, null,
                            React.createElement(SkipToContent, { onClick: onSkipToContentClick }, translate('/Shared/SkipToContent')),
                            isCompactHeader ? (React.createElement(CompactHeader, { quickOrderEnabled: isQuickOrderEnabled })) : (React.createElement(FullHeader, { quickOrderEnabled: isQuickOrderEnabled })),
                            isQuickOrderEnabled ? React.createElement(CustomBanner, Object.assign({}, siteBanner)) : React.createElement(Banner, Object.assign({}, siteBanner)),
                            React.createElement(Content, { ref: contentElementRef },
                                React.createElement(CurrentPage, null)),
                            React.createElement(MainFooter, null),
                            React.createElement(SystemText, null, systemText.text),
                            React.createElement(MiniCart, null),
                            React.createElement(WishListPanel, null),
                            availableLanguages && availableLanguages.length > 1 && React.createElement(CountrySelector, null),
                            isCompactHeader && React.createElement(Drawer, null),
                            React.createElement(Loader, null),
                            React.createElement(NetworkError, null),
                            React.createElement(Tray, null,
                                React.createElement(SnackbarContainer, null),
                                React.createElement(ActionbarContainer, null)),
                            React.createElement(RootContext.Consumer, null, context => React.createElement("div", { ref: context.setRootElementRef })), (_a = currentPage.jsonLd) === null || _a === void 0 ? void 0 :
                            _a.map((jsonLd, i) => (React.createElement("script", { key: i, dangerouslySetInnerHTML: {
                                    __html: JSON.stringify(jsonLd),
                                }, type: "application/ld+json" }))))))))));
}
export default SiteLayout;
const Base = styled(applyClassName(Root), {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    maxWidth: '100%',
    overflow: 'hidden',
});
const Content = styled.div({
    display: 'flex',
    flexGrow: 1,
});
const SkipToContent = createFocusOnlyVisibleElement(styled(PlainButton, {
    position: 'absolute',
    top: 0,
    left: margin.medium,
    transform: 'translate(0, -100%)',
    ':focus': {
        transform: 'translate(0, 0)',
        zIndex: ZIndex.SkipToContent,
    },
}));
const Banner = styled(applyClassName(ContentArea));
const CustomBanner = styled(Banner, {
    marginTop: ({ isMobile }) => (isMobile ? 30 : 0),
});
function renderMeta(currentPage) {
    if (isBrowser()) {
        const canonicalResource = currentPage;
        let canonical = document.getElementById('link-canonical');
        if (isCanonicalResource(canonicalResource)) {
            if (canonicalResource.canonicalUrl) {
                if (!canonical) {
                    canonical = document.createElement('link');
                    canonical.rel = 'canonical';
                    canonical.id = 'link-canonical';
                    document.head.appendChild(canonical);
                }
                console.debug('Updating canonical to', canonicalResource.canonicalUrl);
                canonical.href = canonicalResource.canonicalUrl;
            }
            else {
                if (canonical) {
                    console.debug('Removing canonical');
                    document.head.removeChild(canonical);
                }
            }
        }
        else if (canonical) {
            console.debug('Removing canonical');
            document.head.removeChild(canonical);
        }
        // This title formatting also exists in _Layout.cshtml
        const newTitle = ((currentPage.meta && currentPage.meta.title) || '') + ' | ' + formatHost(location.host);
        if (document.title === newTitle) {
            return;
        }
        document.title = newTitle;
        Array.from(document.querySelectorAll('meta[data-dynamic]')).forEach(node => {
            node.parentElement.removeChild(node);
        });
        Object.keys((currentPage.meta && currentPage.meta.elements) || {}).forEach(name => {
            const metaElement = document.createElement('meta');
            metaElement.setAttribute('data-dynamic', '1');
            metaElement.setAttribute(currentPage.meta.elements[name].type, name);
            metaElement.setAttribute('content', currentPage.meta.elements[name].value);
            document.head.appendChild(metaElement);
        });
    }
}
function renderFQDNAlternateLinks(currentPage) {
    if (isBrowser()) {
        Array.from(document.querySelectorAll('link[rel="alternate"]')).forEach(node => {
            node.parentElement.removeChild(node);
        });
        if (currentPage.alternateLinks && currentPage.alternateLinks.length > 0) {
            Array.from(currentPage.alternateLinks).forEach(link => {
                const linkElement = document.createElement('link');
                linkElement.href = link.url;
                linkElement.hreflang = link.lang;
                linkElement.rel = 'alternate';
                document.head.appendChild(linkElement);
            });
        }
    }
}
function formatHost(host) {
    const parts = host.split('.');
    if (parts.length !== 3) {
        return host;
    }
    return parts[1].substring(0, 1).toUpperCase() + parts[1].substring(1) + '.' + parts[2];
}
function isCanonicalResource(page) {
    return page && 'canonicalUrl' in page;
}
