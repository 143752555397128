// Border
export const thin = 1;
export const thick = 2;
export const thicker = 3;
// Margin & padding
export const tiny = 4;
export const petite = 5;
export const small = 8;
export const average = 12;
export const medium = 16;
export const large = 24;
export const huge = 32;
export const gigantic = 40;
export const humongous = 48;
export const enormous = 200;
export const moderate = large; // This is default spacing, e.g. margin between `<p>`
export const radius = 2;
