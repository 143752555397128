import { __awaiter } from "tslib";
import React from 'react';
import { resolveDataSync, invalidateData, updateData, post, postJson, getJson, updateCurrentPage, invalidateAndResolveData, resolveComponentAndChildComponents, } from '@avensia/scope';
import { CheckoutProvider as StandardCheckoutProvider, CheckoutConsumer, CheckoutState, Context as BaseContext, useCheckoutActions as useCheckoutActionsBase, } from '@avensia/avensia-checkout';
import { getCart } from 'Shared/dynamic-data';
import { addToCart as gtmAddToCart, GA4Affiliation, removeFromCart as gtmRemoveFromCart } from '../TrackingInformation';
import currentPageIsCheckout from 'Checkout/current-page-is-checkout';
import { checkoutPageUrl } from 'Shared/known-urls';
import { pushState } from '@avensia/scope';
export { useCheckoutState } from '@avensia/avensia-checkout';
export function useCheckoutActions() {
    return useCheckoutActionsBase();
}
export const Context = BaseContext;
function augument(defaultActions) {
    const augmentedActions = defaultActions;
    const originalAddToCart = defaultActions.addToCart;
    augmentedActions.addToCart = (code, quantity, linkIdentifier, listName, affiliation, additional) => {
        post('/contentindexnotification/addtocart?linkIdentifier=' + linkIdentifier);
        // send events to ga4
        gtmAddToCart([{ code, quantity }], listName, code, 0, affiliation, additional);
        return originalAddToCart(code, { quantity, additional: Object.assign(Object.assign({}, additional), { isQuantityUpdate: true }) });
    };
    const originalUpdateCartItemQuantity = defaultActions.updateCartItemQuantity;
    augmentedActions.updateCartItemQuantity = (code, previousQuantity, newQuantity, listName) => {
        if (newQuantity > previousQuantity) {
            const addedQuantity = newQuantity - previousQuantity;
            gtmAddToCart([{ code, quantity: newQuantity }], listName, code, addedQuantity, GA4Affiliation.CHANGED_QUANTITY);
        }
        else {
            gtmRemoveFromCart([{ code, quantity: previousQuantity - newQuantity }], listName, {
                code,
                quantity: previousQuantity - newQuantity,
            });
        }
        return originalUpdateCartItemQuantity(code, {
            quantity: newQuantity,
            additional: {
                customerComment: null,
                patientName: null,
                mtmMeasurements: null,
                unit: null,
                isQuantityUpdate: true,
            },
        });
    };
    const originalRemoveFromCart = defaultActions.removeFromCart;
    augmentedActions.removeFromCart = (code, previousQuantity, listName) => {
        gtmRemoveFromCart([{ code, quantity: previousQuantity }], listName, { code, quantity: previousQuantity });
        return originalRemoveFromCart(code);
    };
    augmentedActions.updateCartItemCustomerComment = (code, quantity, additional) => {
        return originalUpdateCartItemQuantity(code, {
            quantity,
            additional,
        });
    };
    return defaultActions;
}
let checkoutParameters = null;
function createCheckoutParameters(store) {
    if (!checkoutParameters) {
        checkoutParameters = {
            getCart: () => {
                return resolveDataSync(getCart());
            },
            loadCart: () => __awaiter(this, void 0, void 0, function* () {
                return yield invalidateAndResolveData(getCart());
            }),
            setCart: cart => {
                if (cart) {
                    updateData(getCart(), cart);
                }
                else {
                    invalidateData(getCart());
                }
            },
            getCheckoutData: () => {
                const currentPage = store.getState().currentPage;
                if (currentPageIsCheckout(currentPage)) {
                    return currentPage.checkoutData;
                }
                return null;
            },
            loadCheckoutData: () => __awaiter(this, void 0, void 0, function* () {
                return yield getJson(checkoutPageUrl() + '/checkoutdata');
            }),
            setCheckoutData: (checkoutData) => __awaiter(this, void 0, void 0, function* () {
                yield resolveComponentAndChildComponents(checkoutData);
                const currentPage = store.getState().currentPage;
                if (currentPageIsCheckout(currentPage)) {
                    store.dispatch(updateCurrentPage((existingCheckoutPage) => (Object.assign(Object.assign({}, existingCheckoutPage), { checkoutData }))));
                }
            }),
            postUpdate: body => postJson(checkoutPageUrl() + '/update', body),
            postCompletePurchase: body => postJson(checkoutPageUrl() + '/completepurchase', body),
            pushState,
        };
    }
    return checkoutParameters;
}
export class CheckoutProvider extends React.Component {
    constructor(props) {
        super(props);
        this.standardProvider = React.createRef();
    }
    componentDidMount() {
        // This is a good place if you need to perform side effects on the initial page load,
        // such as adding something to the cart
        //
        // this.standardProvider.current.actions.addToCart(...);
    }
    render() {
        return (React.createElement(StandardCheckoutProvider, { ref: this.standardProvider, parameters: createCheckoutParameters(this.props.store), augumentActions: augument }, this.props.children));
    }
}
const augumentedConsumer = CheckoutConsumer;
export { augumentedConsumer as CheckoutConsumer };
