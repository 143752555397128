import { __rest } from "tslib";
import React from 'react';
import { styled } from '@glitz/react';
import Link from 'Shared/Link';
import { createTruncateDecorator, figma, tiny, small, sourceSansPro, sourceSansProSemiBold, delta } from 'Shared/Style';
export default styled(function Item(_a) {
    var restProps = __rest(_a, []);
    return (React.createElement(LinkStyled, { to: restProps.url },
        React.createElement(Label, null, isItem(restProps) ? React.createElement(Parent, null, restProps.name) : React.createElement(Child, null, restProps.children))));
});
function isItem(props) {
    return 'name' in props;
}
const LinkStyled = styled(Link, {
    display: 'table',
    color: 'inherit',
    textDecoration: 'none',
    padding: { xy: small },
    ':hover': {
        color: 'inherit',
        backgroundColor: ({ siteTheme }) => siteTheme.navbar.hoverBackgroundColor,
        borderRadius: tiny,
    },
});
const Label = styled.span({
    display: 'block',
});
const Parent = styled.span(createTruncateDecorator(), sourceSansPro(figma));
const Child = styled(Parent, sourceSansProSemiBold(delta));
