import { __rest } from "tslib";
import React, { useRef, useState, useEffect } from 'react';
import { styled } from '@glitz/react';
import { useLoadResource } from './utils';
import { ImageBase, Placeholder } from './components';
import noImage from './noimage';
import useSubscribeToBrowserCache from './use-subscribe-to-browser-cache';
var Status;
(function (Status) {
    Status[Status["Pending"] = 0] = "Pending";
    Status[Status["Rejected"] = 1] = "Rejected";
    Status[Status["Fulfilled"] = 2] = "Fulfilled";
})(Status || (Status = {}));
export default styled(function EagerImage(_a) {
    var { originalSrc, subscribeToCache } = _a, restProps = __rest(_a, ["originalSrc", "subscribeToCache"]);
    const currentSrc = restProps.src;
    const previousSrcRef = useRef();
    const currentSrcSet = restProps.srcSet;
    const previousSrcSetRef = useRef();
    const [status, setStatus] = useState(currentSrc ? Status.Pending : Status.Rejected);
    const cachedResource = useSubscribeToBrowserCache(originalSrc, subscribeToCache);
    const loadResource = useLoadResource();
    const mountedRef = useRef(true);
    if (__BROWSER__) {
        if (previousSrcRef.current !== currentSrc || previousSrcSetRef.current !== currentSrcSet) {
            if (currentSrc) {
                setStatus(Status.Pending);
                loadResource(originalSrc, currentSrc, currentSrcSet).then(({ isFulfilled, isCurrentLoad }) => {
                    if (isCurrentLoad && mountedRef.current) {
                        setStatus(isFulfilled ? Status.Fulfilled : Status.Rejected);
                    }
                });
            }
            else {
                setStatus(Status.Rejected);
            }
            previousSrcRef.current = currentSrc;
            previousSrcSetRef.current = currentSrcSet;
        }
    }
    useEffect(() => () => {
        mountedRef.current = false;
    }, []);
    if (currentSrc) {
        if (status === Status.Fulfilled) {
            return React.createElement(ImageBase, Object.assign({}, restProps, { src: currentSrc }));
        }
        if (cachedResource) {
            // Prevent `srcset` to interfere with cached image
            delete restProps.srcSet;
            return React.createElement(ImageBase, Object.assign({}, restProps, { src: cachedResource.src }));
        }
        if (status === Status.Pending) {
            return React.createElement(ImageBase, Object.assign({}, restProps, { src: currentSrc }));
        }
    }
    // Prevent `srcset` to interfere with no image image
    delete restProps.srcSet;
    delete restProps.src;
    return React.createElement(Placeholder, Object.assign({}, restProps, { src: noImage }));
});
