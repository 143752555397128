export function formatPriceForGtm(price) {
    let decimals = Math.round((price % 1) * 100)
        .toString()
        .substring(0, 2);
    while (decimals.length < 2) {
        decimals = '0' + decimals;
    }
    const nonDecimals = Math.floor(price);
    return `${nonDecimals}.${decimals}`;
}
