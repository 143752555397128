import { query } from '@glitz/core';
import transformers from '@glitz/transformers';
// Copied from Scope config because we don't cannot import from Scope
// since this needs to be executed on both client and server
const mediaQueries = [
    query({ minWidth: '320px' }),
    query({ minWidth: '412px' }),
    query({ minWidth: '768px' }),
    query({ minWidth: '1024px' }),
    query({ minWidth: '1366px' }),
    query({ minWidth: '1600px' }),
    query({ minWidth: '1920px' }),
];
function mediaOrder(a, b) {
    const indexA = mediaQueries.indexOf(a);
    const indexB = mediaQueries.indexOf(b);
    return indexA - indexB;
}
export const glitzCoreOptions = {
    mediaOrder,
    transformer: transformers({
        numberToLengthOptions: {
            animationDelay: 'ms',
            animationDuration: 'ms',
            transitionDelay: 'ms',
            transitionDuration: 'ms',
        },
    }),
};
