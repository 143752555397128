import React, { useEffect, useRef } from 'react';
import connect from 'Shared/connect';
import { AccountPanelMode, closeAccountPanel, openAccountPanel } from './action-creators';
import Account from './index';
import Snackbar, { useSnackbar } from 'SiteLayout/Tray/Snackbar';
import Flyout from 'Shared/Flyout';
import { URLX, currentUrl, useDynamicData } from '@avensia/scope';
import { useDispatch } from 'react-redux';
import { QUERY_LOGIN_PARAM, QUERY_LOGIN_PARAM_RETURN_URL, QUERY_LOGIN_VALUE_REQUIRED } from './utils';
import { getCurrentUser } from 'Shared/dynamic-data';
function AccountPanel(props) {
    const pushSnackbar = useSnackbar();
    const lastMessageRef = useRef(props.accountMessage);
    const lastRemoveRef = useRef(null);
    const dispatch = useDispatch();
    const url = currentUrl();
    const currentUser = useDynamicData(getCurrentUser(), { shouldResolveData: true });
    const isLoginRequired = url.searchParams.get(QUERY_LOGIN_PARAM) === QUERY_LOGIN_VALUE_REQUIRED;
    useEffect(() => {
        // Will dispatch flyout login if required when we have the data for the current user
        // and if a user is not logged in and the account panel is still not opened
        if (currentUser !== null) {
            const url = currentUrl();
            const returnUrl = url.searchParams.has(QUERY_LOGIN_PARAM_RETURN_URL)
                ? new URLX(url.searchParams.get(QUERY_LOGIN_PARAM_RETURN_URL))
                : null;
            if (isLoginRequired && !currentUser.isLoggedIn && !props.accountPanelIsOpen) {
                dispatch(openAccountPanel(AccountPanelMode.Login, returnUrl));
            }
        }
    }, [currentUser, dispatch, isLoginRequired, props.accountPanelIsOpen]);
    if (lastMessageRef.current !== props.accountMessage) {
        if (lastRemoveRef.current) {
            lastRemoveRef.current();
            lastRemoveRef.current = null;
        }
        if (props.accountMessage) {
            lastRemoveRef.current = pushSnackbar(React.createElement(Snackbar, null, props.accountMessage));
        }
        lastMessageRef.current = props.accountMessage;
    }
    return (React.createElement(Flyout, { isOpen: props.accountPanelIsOpen, onClose: props.closeAccountPanel },
        React.createElement(Account, null)));
}
export default connect((state) => ({
    accountPanelIsOpen: state.userAccount.accountPanelIsOpen,
    accountMessage: state.userAccount.accountMessage,
}), (dispatch) => ({
    closeAccountPanel() {
        return dispatch(closeAccountPanel());
    },
}))(AccountPanel);
