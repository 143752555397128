import { useContext, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { RootContext } from './RootContext';
import { useForceUpdate } from 'Shared/use-force-update';
/**
 * Can be used to render components in the site layout root instead of in place in the component tree.
 * This is handy with positioned fixed components like modals, flyouts, popins etc to avoid them affecting
 * layout within a flex container.
 * Also if position fixed elements have any parent element with a transform, the position is counted against
 * that element instead of body. If you wrap it in RootPortal you avoid this issue and you can render
 * positioned fixed elements inside header components etc without worry.
 */
export function RootPortal({ children }) {
    const rootContext = useContext(RootContext);
    const forceUpdate = useForceUpdate();
    const rootElementRef = rootContext.rootElementRef;
    useEffect(() => {
        /**
         * Since portals doesn't render on server we need a state update to avoid diff
         * between server and client render since React will not patch up all differences.
         * */
        forceUpdate();
    }, [forceUpdate]);
    if (!rootElementRef.current) {
        return null;
    }
    return createPortal(children, rootElementRef.current);
}
