import React from 'react';
import { Motion, spring } from 'react-motion';
const DEFAULT_TRANSLATE_Y = 1;
const DEFAULT_OPACITY = 0;
export default function SlideIn(props) {
    const { open, children } = props;
    const defaultStyle = {
        translateY: DEFAULT_TRANSLATE_Y,
        opacity: DEFAULT_OPACITY,
    };
    const style = {
        translateY: spring(open ? 0 : DEFAULT_TRANSLATE_Y),
        opacity: spring(open ? 1 : DEFAULT_OPACITY),
    };
    return (React.createElement(Motion, { defaultStyle: defaultStyle, style: style }, ({ translateY: translateY, opacity }) => {
        const isPresent = open || (translateY < DEFAULT_TRANSLATE_Y && opacity > DEFAULT_OPACITY);
        return children(Object.assign(Object.assign({ willChange: 'transform, opacity' }, (isPresent && {
            transform: `translateY(${translateY * 100}%)`,
            opacity,
        })), (!open && { pointerEvents: 'none' })), isPresent);
    }));
}
