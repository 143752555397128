import { __awaiter } from "tslib";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { onDataUpdate, resolveDataSync } from '@avensia/scope';
let appInsights = undefined;
export function initApplicationInsights(appShellData) {
    if (appShellData.siteSettings.applicationInsightsInstrumentationKey) {
        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: appShellData.siteSettings.applicationInsightsInstrumentationKey,
                disableAjaxTracking: false,
                disableFetchTracking: false,
            },
        });
        appInsights.loadAppInsights();
        appInsights.context.application.ver = CURRENT_VERSION;
        const setAIUser = () => {
            const currentUser = resolveDataSync(appShellData.currentUser);
            if (currentUser) {
                if (currentUser.isLoggedIn) {
                    appInsights.context.user.setAuthenticatedUserContext(currentUser.id);
                }
                else {
                    appInsights.context.user.clearAuthenticatedUserContext();
                }
            }
        };
        onDataUpdate(appShellData.currentUser, setAIUser);
        setAIUser();
    }
}
export function trackException(e) {
    return __awaiter(this, void 0, void 0, function* () {
        if (appInsights) {
            appInsights.trackException({ exception: e });
        }
    });
}
export function trackPageview(page) {
    return __awaiter(this, void 0, void 0, function* () {
        if (appInsights) {
            appInsights.trackPageView({ pageType: page.componentName });
        }
    });
}
/*
// Example of tracking an event to AI
export async function trackAddToCart(code: string) {
  if (appInsights) {
    appInsights.trackEvent({ name: 'AddToCart', properties: { code } });
  }
}
*/
