import React, { useState, createContext, useMemo, useContext, useCallback } from 'react';
import { forwardStyle, styled } from '@glitz/react';
import { createGeneralDecorator, General, margin } from 'Shared/Style';
import { DarkThemeBlock } from 'Shared/Theme';
import SlideIn from 'Shared/SlideIn';
const Context = createContext({ snackbars: [], setSnackbars: () => { } });
export function SnackbarProvider(props) {
    const [snackbars, setSnackbars] = useState([]);
    const value = useMemo(() => ({ snackbars, setSnackbars }), [snackbars]);
    return React.createElement(Context.Provider, { value: value }, React.Children.toArray(props.children));
}
export const SnackbarContainer = styled(() => {
    const { snackbars } = useContext(Context);
    return snackbars.length > 0 ? React.createElement(styled.Div, null, React.Children.toArray(snackbars)) : null;
}, {
    display: 'flex',
    flexDirection: 'column',
    pointerEvents: 'none',
});
export function useSnackbar() {
    const { setSnackbars } = useContext(Context);
    return useCallback(function push(node) {
        let realNode;
        function remove() {
            setSnackbars(snackbars => snackbars.filter(node => node !== realNode));
        }
        if (typeof node === 'function') {
            realNode = node(remove);
        }
        else {
            realNode = node;
        }
        setSnackbars(snackbars => [...snackbars, realNode]);
        return remove;
    }, [setSnackbars]);
}
export default styled(forwardStyle(function Snackbar({ compose, children }) {
    return (React.createElement(SlideIn, { open: true }, style => (React.createElement(DarkThemeBlock, { css: compose(), style: style }, React.Children.toArray(children)))));
}), createGeneralDecorator(General.Small, false), {
    alignSelf: 'flex-start',
    margin: { x: margin.small, bottom: margin.small },
    pointerEvents: 'auto',
});
