import React from 'react';
import { styled } from '@glitz/react';
import { createTransitionDecorator, depthDecorator, eta } from 'Shared/Style';
import { translate } from '@avensia/scope';
import { useTextHighlight } from 'Shared/use-text-highlight';
import { testIdProps } from 'test-automation';
const QuickOrderList = styled((props) => {
    return (React.createElement(styled.Div, { css: __$hoisted_o0 }, props.orders.map((order, i) => {
        let searchProperty = `${translate('/QuickOrder/sku')}#: ${order.sku}`;
        if (order.pharmacode) {
            searchProperty = searchProperty.concat(' / ', `${translate('/QuickOrder/pharmaCode')}: ${order.pharmacode}`);
        }
        if (order.ean) {
            searchProperty = searchProperty.concat(' / ', `${translate('/QuickOrder/Ean')}: ${order.ean}`);
        }
        if (order.miGel) {
            searchProperty = searchProperty.concat(' / ', `${translate('/QuickOrder/MiGel')}: ${order.miGel}`);
        }
        return (React.createElement(Item, Object.assign({}, testIdProps.QuickOrderSearchResult, { key: i, onClick: () => props.onSelectOrder(order) }),
            React.createElement("div", { dangerouslySetInnerHTML: {
                    __html: useTextHighlight(order.displayName, props.orderSearchText),
                } }),
            React.createElement("div", { dangerouslySetInnerHTML: {
                    __html: useTextHighlight(searchProperty, props.orderSearchText),
                } })));
    })));
}, Object.assign(Object.assign({}, depthDecorator()), createTransitionDecorator({ property: 'opacity' })));
export default QuickOrderList;
const Item = styled.div({
    ':hover': {
        backgroundColor: `rgba(0, 180, 230, 0.08)`,
    },
    padding: {
        xy: eta,
    },
});
const __$hoisted_o0 = {
    pointerEvents: 'auto',
    cursor: 'pointer',
};
