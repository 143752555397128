import { useEffect, useState } from 'react';
import useIsomorphicLayoutEffect from './use-isomorphic-layout-effect';
function useWindowSize() {
    useEffect(() => {
        window.addEventListener('resize', handleSize);
    }, []);
    const [windowSize, setWindowSize] = useState({
        width: 0,
        height: 0,
    });
    const handleSize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };
    // Set size at the first client-side load
    useIsomorphicLayoutEffect(() => {
        handleSize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return windowSize;
}
export default useWindowSize;
