import React from 'react';
import { styled } from '@glitz/react';
import Overlay from 'Shared/Overlay';
import * as style from 'Shared/Style';
import ErrorBoundary from 'Shared/ErrorBoundary';
import { useIsCompact } from 'Shared/use-viewport';
import { mediaMinMicro } from 'Shared/Style';
import { media } from '@glitz/core';
import { HEADER_HEIGHT_COMPACT, HEADER_HEIGHT_FULL } from 'SiteLayout/Header/use-header-height';
const Base = styled.div({
    position: theme => (theme.isCompact ? 'inherit' : 'relative'),
    display: 'flex',
    alignItems: 'center',
    backgroundColor: style.white,
});
const InnerContainer = styled.div({
    position: 'relative',
});
const StyledOverlay = styled(Overlay, Object.assign({}, media(mediaMinMicro, {
    position: 'absolute',
    top: '100%',
    width: '100%',
    display: 'flex',
    left: 0,
})));
export default function SearchOverlay(props) {
    const isCompact = useIsCompact();
    const marginOffset = isCompact ? HEADER_HEIGHT_COMPACT : HEADER_HEIGHT_FULL;
    return (React.createElement(StyledOverlay, { enabled: props.isOpen, marginTop: marginOffset, onClose: props.onClose }, props.isOpen && (React.createElement(Base, { css: props.maxWidth && { maxWidth: props.maxWidth } },
        React.createElement(InnerContainer, null,
            React.createElement(ErrorBoundary, { reloadCurrentPage: true }, props.children))))));
}
