import React, { createContext, useState, useEffect, useContext, forwardRef } from 'react';
import { styled } from '@glitz/react';
import { TAB_KEY, ESC_KEY, ENTER_KEY, isBrowser } from '@avensia/scope';
import { lightBlue } from 'Shared/Style';
/**
 * By default browsers show an outline on focused element which is necessary for a11y.
 * However this will show even when you click on elements and customers dislike that. Who doesn't?
 * A newer CSS selector :focus-visible will solve that and only target tabbing focus.
 * Until that selecor has better support we use this component to enhance our focusable components
 * with a tabbing only focus style.
 */
const TabbingContext = createContext(false);
export function useTabbing() {
    return useContext(TabbingContext);
}
export function TabbingProvider(props) {
    const [isTabbing, setTabbing] = useState(false);
    useEffect(() => {
        function navigatingOnButtonOrLink(e) {
            /** When user hits enter on a button or link we want to stay/enter in tabbed mode */
            const tagNameUpperCase = e.target.tagName.toUpperCase();
            return e.keyCode === ENTER_KEY && (tagNameUpperCase === 'BUTTON' || tagNameUpperCase === 'A');
        }
        const keyPressListener = (e) => {
            if (e.keyCode === TAB_KEY || e.keyCode === ESC_KEY || navigatingOnButtonOrLink(e)) {
                if (isTabbing === false) {
                    setTabbing(true);
                }
            }
            else {
                if (isTabbing === true) {
                    setTabbing(false);
                }
            }
        };
        const clickListener = (e) => {
            if (isTabbing === true) {
                setTabbing(false);
            }
        };
        if (isBrowser()) {
            document.addEventListener('keydown', keyPressListener);
            document.addEventListener('mouseup', clickListener);
        }
        return () => {
            if (isBrowser()) {
                document.removeEventListener('keydown', keyPressListener);
                document.removeEventListener('mouseup', clickListener);
            }
        };
    }, [isTabbing]);
    return React.createElement(TabbingContext.Provider, { value: isTabbing }, props.children);
}
export function createTabbingFocusElement(Component) {
    return styled(forwardRef((props, ref) => {
        const isTabbing = useTabbing();
        return (React.createElement(Component, Object.assign({}, props, { ref: ref, css: {
                ':focus': {
                    outlineWidth: isTabbing ? 1 : 0,
                    outlineStyle: 'solid',
                    outlineColor: lightBlue,
                },
            } })));
    }));
}
export function createTabbingFocusWithinElement(Component) {
    return styled(forwardRef((props, ref) => {
        const isTabbing = useTabbing();
        return (React.createElement(Component, Object.assign({}, props, { ref: ref, css: {
                ':focus-within': {
                    outlineWidth: isTabbing ? 1 : 0,
                    outlineStyle: 'solid',
                    outlineColor: theme => [theme.mainTextColor, '-webkit-focus-ring-color'],
                },
            } })));
    }));
}
/** When you want to make the element visible only for tabbing user */
export function createFocusOnlyVisibleElement(Component) {
    return styled(forwardRef((props, ref) => {
        const isTabbing = useTabbing();
        return (React.createElement(Component, Object.assign({}, props, { ref: ref, css: {
                opacity: 0,
                pointerEvents: 'none',
                ':focus': {
                    opacity: isTabbing ? 1 : 0,
                    pointerEvents: isTabbing ? 'auto' : 'none',
                },
            } })));
    }));
}
