import { OPEN_WISHLIST, CLOSE_WISHLIST, ADD_WISHLIST_ITEM, REMOVE_WISHLIST_ITEM, } from './action-creators';
import { PAGE_LOAD, UPDATE_APP_SHELL_DATA } from '@avensia/scope';
export default function (state = null, action, appShellData) {
    state = state || {
        isOpen: false,
        codes: [...appShellData.wishListItems],
    };
    switch (action.type) {
        case OPEN_WISHLIST:
            return Object.assign(Object.assign({}, state), { isOpen: !state.isOpen });
        case CLOSE_WISHLIST:
            return Object.assign(Object.assign({}, state), { isOpen: false });
        case PAGE_LOAD: {
            if (state.isOpen) {
                return Object.assign(Object.assign({}, state), { isOpen: false });
            }
            else {
                return state;
            }
        }
        case ADD_WISHLIST_ITEM: {
            const { productCode } = action;
            return Object.assign(Object.assign({}, state), { codes: [...state.codes, productCode] });
        }
        case REMOVE_WISHLIST_ITEM: {
            const { productCode } = action;
            return Object.assign(Object.assign({}, state), { codes: state.codes.filter(c => c !== productCode) });
        }
        case UPDATE_APP_SHELL_DATA: {
            const updateAppShellAction = action;
            return Object.assign(Object.assign({}, state), { codes: updateAppShellAction.appShellData.wishListItems });
        }
        default:
            return state;
    }
}
