import React from 'react';
import { styled } from '@glitz/react';
import { fullButtonDecorator, LinkButton } from 'Shared/Button';
import { createTransitionDecorator, depthDecorator, white, huge, figma, large, small, tiny, primary, average, } from 'Shared/Style';
import { translate } from '@avensia/scope';
import { testIdProps } from 'test-automation';
const Suggestions = styled((props) => {
    var _a, _b, _c;
    const display = props.open && props.items.length > 0;
    return (React.createElement(Base, Object.assign({}, testIdProps.SearchHistory, { css: { opacity: display ? 1 : 0, pointerEvents: display ? 'auto' : 'none' } }),
        ((_a = props.mostCommonSearchLinks) === null || _a === void 0 ? void 0 : _a.links) && (React.createElement(LinkWrapper, null,
            React.createElement(Title, null, `${translate('/Shared/MostSearched')}:`), (_b = props.mostCommonSearchLinks) === null || _b === void 0 ? void 0 :
            _b.links.map((link, index) => (React.createElement(Item, { key: index, to: link.href }, link.text))))),
        React.createElement(LinkWrapper, null,
            React.createElement(Title, null, `${translate('/Shared/RecentlySearched')}:`), (_c = props.items) === null || _c === void 0 ? void 0 :
            _c.map((item, index) => (React.createElement(Item, { key: index, onClick: () => props.applySuggestion(item.text) }, item.text))))));
}, depthDecorator, createTransitionDecorator({ property: 'opacity' }));
const Base = styled.div({
    padding: { x: large, top: large, bottom: large - tiny },
    marginTop: theme => (theme.isCompact ? 0 : 17),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: white,
});
const Title = styled.div({
    fontWeight: 'bold',
    fontSize: figma,
});
const LinkWrapper = styled.div({
    paddingBottom: average,
});
export default Suggestions;
const Item = styled(LinkButton, fullButtonDecorator, {
    textTransform: 'capitalize',
    ':first-of-type': {
        marginTop: small,
        fontSize: figma,
        textDecoration: 'none',
        color: 'inherit',
        ':hover': { color: primary },
    },
    fontSize: figma,
    ':hover': {
        fontSize: figma,
        textDecoration: 'none',
    },
    ':active': { fontSize: figma, textDecoration: 'none' },
    ':focus': { fontSize: figma, textDecoration: 'none' },
    justifyContent: 'flex-start',
    padding: { x: 0 },
    height: huge,
    border: { xy: { color: 'transparent' } },
    textDecoration: 'none',
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});
