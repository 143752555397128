import React, { useEffect, useState } from 'react';
import { translate } from '@avensia/scope';
import Snackbar, { useSnackbar } from 'SiteLayout/Tray/Snackbar';
import { LinkButton } from 'Shared/Button';
import { useSelector } from 'Shared/State';
export default function NetworkError() {
    const { loadFailure, reload } = useSelector(state => state.currentPage);
    const pushSnackbar = useSnackbar();
    const [showOffline, setShowOffline] = useState(navigator.onLine === false ? true : false);
    const [dismissed, setDismissed] = useState();
    useEffect(() => {
        function isOnline() {
            setShowOffline(false);
        }
        function isOffline() {
            setShowOffline(true);
        }
        window.addEventListener('online', isOnline);
        window.addEventListener('offline', isOffline);
        return () => {
            window.removeEventListener('online', isOnline);
            window.removeEventListener('offline', isOffline);
        };
    }, []);
    useEffect(() => {
        if (showOffline) {
            return pushSnackbar(React.createElement(Snackbar, null,
                translate('/Errors/Offline'),
                ' ',
                React.createElement(LinkButton, { onClick: () => setShowOffline(false) }, translate('/Shared/Dismiss'))));
        }
        if (loadFailure &&
            // Message has been dismissed
            dismissed !== loadFailure &&
            // AccountButton handles failed requests that requires auth
            loadFailure.status !== 401 &&
            // Don't bother the user if we're showing a full version of the page
            (!loadFailure.isShowingFullCachedPage || loadFailure.status <= 500)) {
            return pushSnackbar(React.createElement(Snackbar, null,
                translate('/Errors/PageLoad'),
                " ",
                React.createElement(LinkButton, { onClick: reload }, translate('/Errors/Retry')),
                ' ',
                React.createElement(LinkButton, { onClick: () => setDismissed(loadFailure) }, translate('/Shared/Dismiss'))));
        }
    }, [dismissed, loadFailure, showOffline, pushSnackbar, reload]);
    return null;
}
