import React from 'react';
import { styled, applyClassName } from '@glitz/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { black } from '../Style';
import { isEmpty } from 'Shared/utils';
export default function fontAwesomeFactory(props) {
    return styled(applyClassName((iconProps) => (React.createElement(FontAwesomeIcon, Object.assign({ color: black }, props, iconProps)))));
}
export function requireIfExists(iconFile) {
    try {
        return require(`!!file!Shared/Icon/fontawesome/${iconFile}.svg?limit=false`);
    }
    catch (error) {
        return null;
    }
}
export function formatIcon(icon) {
    if (isEmpty(icon)) {
        return null;
    }
    const result = icon.replace(/([A-Z])/g, ' $1');
    return result.split(' ').join('-').toLowerCase();
}
