import { __awaiter } from "tslib";
import { useRef } from 'react';
import { URLX } from '@avensia/scope';
function assumeCachedByBrowser(image) {
    if (!(image && image.currentSrc)) {
        return false;
    }
    const pathname = new URLX(image.currentSrc).pathname;
    if (pathname.endsWith('.svg')) {
        return false;
    }
    return image.complete && image.naturalWidth > 0;
}
const browserCacheSubscriptions = {};
export function subscribeToBrowserCache(src, cb) {
    if (browserCacheSubscriptions[src]) {
        browserCacheSubscriptions[src].push(cb);
    }
    else {
        browserCacheSubscriptions[src] = [cb];
    }
    return () => {
        var _a;
        const index = (_a = browserCacheSubscriptions[src]) === null || _a === void 0 ? void 0 : _a.indexOf(cb);
        if (index > -1) {
            browserCacheSubscriptions[src].splice(index, 1);
        }
    };
}
const browserCachedResources = {};
export function findBrowserCachedResource(originalSrc) {
    return browserCachedResources[originalSrc];
}
function rememberBrowserCachedResource(image, originalSrc) {
    // Firefox has an ugly tendency to report naturalWidth as 0 when a SW is active.
    // But if we wait just a little bit, the sizes have been calculated. Broken
    // images should be uncommon, so we can afford the setTimeout here
    requestAnimationFrame(() => {
        if (image && assumeCachedByBrowser(image)) {
            const cached = findBrowserCachedResource(originalSrc);
            if (!cached || image.naturalWidth > cached.width) {
                browserCachedResources[originalSrc] = {
                    width: image.naturalWidth,
                    height: image.naturalHeight,
                    src: image.currentSrc,
                };
                const subscription = browserCacheSubscriptions[originalSrc];
                if (subscription) {
                    for (let i = 0; i < subscription.length; i++) {
                        subscription[i]();
                    }
                    delete browserCacheSubscriptions[originalSrc];
                }
            }
        }
    });
}
function loadImage(src, srcset) {
    return new Promise((resolve, reject) => {
        const image = new Image();
        if (srcset) {
            Object.assign(image, {
                onload: () => resolve(image),
                onerror: () => reject(),
                src,
                srcset,
            });
        }
        else {
            Object.assign(image, {
                onload: () => resolve(image),
                onerror: () => reject(),
                src,
                // Defining `srcset` as `undefined` causes the image to crash
            });
        }
        if (image.complete) {
            if (image.naturalWidth > 0) {
                resolve(image);
            }
            else {
                reject();
            }
        }
    });
}
export function useLoadResource() {
    const currentLoadRef = useRef(null);
    return function loadResource(originalSrc, src, srcset) {
        return __awaiter(this, void 0, void 0, function* () {
            const promise = (currentLoadRef.current = loadImage(src, srcset));
            try {
                const image = yield promise;
                rememberBrowserCachedResource(image, originalSrc);
                return { isFulfilled: true, isCurrentLoad: currentLoadRef.current === promise };
            }
            catch (e) {
                return { isFulfilled: false, isCurrentLoad: currentLoadRef.current === promise };
            }
        });
    };
}
export function isImageUrl(url) {
    const imageUrl = url;
    return !!imageUrl.height && !!imageUrl.width;
}
export function isMediageImage(url) {
    if (!url)
        return false;
    const SUPPORTED_FORMATS = ['jpg', 'jpeg', 'png', 'gif', 'tif'];
    const isImage = SUPPORTED_FORMATS.indexOf(getUrlExtension(url)) !== -1;
    return isImage;
}
export function getUrlExtension(url) {
    return url.split(/[#?]/)[0].split('.').pop().trim();
}
export function getFilename(url) {
    var _a, _b;
    const filename = (_b = (_a = url === null || url === void 0 ? void 0 : url.substring(url.lastIndexOf('/') + 1)) === null || _a === void 0 ? void 0 : _a.split(/[#?]/)[0]) !== null && _b !== void 0 ? _b : url;
    return filename;
}
