import { PAGE_LOAD_DONE, PAGE_LOAD, equalsUrl } from '@avensia/scope';
import currentPageIsSearch from 'Search/current-page-is-search';
import { Suggestion, SEARCH_QUERY_NAME } from './action-creators';
import { CLICK_LINK_ITEM, NO_SEARCH_RESULTS, SEARCH_PAGE_LOAD_QUERY_NAME, UNCLICK_LINK_ITEM, } from './nohits-action-creators';
export default function (state = null, action, currentPage) {
    if (state === null) {
        state = {
            open: false,
            isClickLink: false,
            searchText: getSearchTextFromCurrentPage(currentPage) || '',
            [Suggestion.History]: [],
            [Suggestion.Popular]: [],
            [Suggestion.Related]: [],
        };
    }
    switch (action.type) {
        case UNCLICK_LINK_ITEM: {
            return Object.assign(Object.assign({}, state), { isClickLink: false });
        }
        case CLICK_LINK_ITEM: {
            return Object.assign(Object.assign({}, state), { isClickLink: true });
        }
        case PAGE_LOAD: {
            const loadAction = action;
            const isTriggeredBySearch = loadAction.url.hiddenParams.get(SEARCH_PAGE_LOAD_QUERY_NAME) === 'true';
            const isRefresh = equalsUrl(loadAction.urlBeforeLoad, loadAction.url);
            if (!(isTriggeredBySearch || isRefresh) && state.open) {
                return Object.assign(Object.assign({}, state), { open: false });
            }
            break;
        }
        case PAGE_LOAD_DONE: {
            const loadDoneAction = action;
            const query = getSearchTextFromCurrentPage(currentPage);
            if (query !== null && !state.open) {
                return Object.assign(Object.assign({}, state), { searchText: query });
            }
            else if (!currentPageIsSearch(currentPage) && !loadDoneAction.serverRedirected) {
                // We don't want to clear the search text if the current component isn't the search page
                // because the server might redirect us from the search page to a brand page if the user
                // searched for a brand
                return Object.assign(Object.assign({}, state), { searchText: '' });
            }
            break;
        }
        case NO_SEARCH_RESULTS: {
            const completionsAction = action;
            return Object.assign(Object.assign({}, state), { [completionsAction.id]: completionsAction.items });
        }
        default:
            break;
    }
    return state;
}
export function getQuerySuggestion(currentQuery, lists) {
    let firstNonEmptyList;
    let type;
    for (type in Suggestion) {
        if (lists[Suggestion[type]] && lists[Suggestion[type]].length > 0) {
            firstNonEmptyList = lists[Suggestion[type]];
            break;
        }
    }
    if (!firstNonEmptyList) {
        return null;
    }
    const firstAutocomplete = firstNonEmptyList[0].text;
    if (!firstAutocomplete || !currentQuery || currentQuery[currentQuery.length - 1] === ' ') {
        return currentQuery;
    }
    const currentWords = currentQuery.split(' ').filter(s => !!s);
    const autocompleteWords = firstAutocomplete.split(' ').filter(s => !!s);
    if (currentWords.length > autocompleteWords.length) {
        return currentQuery;
    }
    const lastIndex = currentWords.length - 1;
    for (let i = 0; i < lastIndex; i++) {
        if (currentWords[i].toLowerCase() !== autocompleteWords[i].toLowerCase()) {
            return currentQuery;
        }
    }
    if (!autocompleteWords[lastIndex].toLowerCase().startsWith(currentWords[lastIndex].toLowerCase())) {
        return currentQuery;
    }
    return currentQuery + autocompleteWords[lastIndex].substr(currentWords[lastIndex].length);
}
function getSearchTextFromCurrentPage(currentPage) {
    if (currentPageIsSearch(currentPage)) {
        return currentPage.searchPhrase;
    }
    else {
        return null;
    }
}
export function getSearchTextFromUrl(url) {
    return url.searchParams.get(SEARCH_QUERY_NAME) || '';
}
