import { SHOW_SYSTEM_TEXT, HIDE_SYSTEM_TEXT } from './action-creator';
export default function systemTextReducer(state = { text: null }, action) {
    switch (action.type) {
        case SHOW_SYSTEM_TEXT: {
            const { text } = action;
            return { text };
        }
        case HIDE_SYSTEM_TEXT: {
            return { text: null };
        }
    }
    return state;
}
