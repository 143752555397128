export var HorizontalPosition;
(function (HorizontalPosition) {
    HorizontalPosition["Left"] = "left";
    HorizontalPosition["Right"] = "right";
    HorizontalPosition["Center"] = "center";
})(HorizontalPosition || (HorizontalPosition = {}));
export var VerticalPosition;
(function (VerticalPosition) {
    VerticalPosition["Top"] = "top";
    VerticalPosition["Bottom"] = "bottom";
    VerticalPosition["Center"] = "center";
})(VerticalPosition || (VerticalPosition = {}));
export var Alignment;
(function (Alignment) {
    Alignment["Left"] = "left";
    Alignment["Right"] = "right";
    Alignment["Center"] = "center";
    Alignment["FlexStart"] = "flex-start";
    Alignment["FlexEnd"] = "flex-end";
})(Alignment || (Alignment = {}));
