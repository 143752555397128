import { styled } from '@glitz/react';
import { createLinkDecorator } from 'Shared/Generic/A';
import { PlainButton } from 'Shared/Button';
import { medium, small, tiny } from 'Shared/Style';
import { createTransitionDecorator } from 'Shared/Style';
export const HeaderItem = styled(PlainButton, createLinkDecorator({ color: ({ siteTheme }) => siteTheme.navbar.textColor, textDecoration: 'inherit' }), createTransitionDecorator({ property: 'background-color', duration: '200ms' }), {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    padding: { x: medium, y: small },
    borderRadius: tiny,
    ':hover': {
        backgroundColor: ({ siteTheme }) => siteTheme.navbar.hoverBackgroundColor,
    },
});
export const selectedHeaderItemDecorator = styled({});
