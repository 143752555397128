import { __rest } from "tslib";
import React, { useLayoutEffect, useState, useCallback } from 'react';
import { styled } from '@glitz/react';
import { isIOS, isBrowser } from '@avensia/scope';
import { createGeneralDecorator, monochromeDark, createTransitionDecorator, placeholderGrey, delta, General, eta, tiny, lightBlue, thin, medium, outlineGrey, stateRed, } from '../Style';
import { createTabbingFocusWithinElement, createTabbingFocusElement } from 'Shared/a11y/TabFocus';
const UNIMPORTANT_TEXT_COLOR = placeholderGrey;
const textAreaStyles = {
    marginLeft: medium,
    border: {
        xy: {
            color: outlineGrey,
            style: 'solid',
            width: thin,
        },
        radius: 2,
    },
    ':focus-within': {
        border: {
            xy: {
                color: lightBlue,
            },
        },
    },
    resize: 'none',
    maxWidth: '100%',
};
const Text = createTabbingFocusWithinElement(styled((_a) => {
    var { onFocus, onBlur, onChange, placeholder, children, uncontrolled, error } = _a, restProps = __rest(_a, ["onFocus", "onBlur", "onChange", "placeholder", "children", "uncontrolled", "error"]);
    const elementRef = React.useRef();
    const value = (restProps.value = restProps.value || '');
    const [focused, setFocused] = useState(false);
    const [filled, setFilled] = useState(isFilled(value));
    const internalOnFocus = useCallback((e) => {
        setFocused(true);
        if (onFocus) {
            onFocus(e);
        }
    }, [setFocused, onFocus]);
    const internalOnBlur = useCallback((e) => {
        setFocused(false);
        if (onBlur) {
            onBlur(e);
        }
    }, [setFocused, onBlur]);
    const internalOnChange = useCallback((e) => {
        const el = e.currentTarget;
        setFilled(isFilled(el.value));
        if (onChange) {
            onChange(e);
        }
    }, [setFilled, onChange]);
    if (uncontrolled) {
        restProps.defaultValue = value;
        delete restProps.value;
    }
    useLayoutEffect(() => {
        if (uncontrolled) {
            const currentPropIsInSync = value === elementRef.current.value;
            const nextValue = value || '';
            // We don't want to allow clearing if the current value is not in sync with the current
            // prop because we might end up clearing during the time the user is typing.
            const shouldClear = nextValue === '' && currentPropIsInSync;
            if (elementRef.current && (!focused || shouldClear)) {
                elementRef.current.value = nextValue;
            }
        }
    }, [focused, uncontrolled, value]);
    const realPlaceholder = placeholder || children;
    const { disabled, readOnly } = restProps;
    const editable = !(disabled || readOnly);
    const defaultStyle = Object.assign({ backgroundColor: disabled ? monochromeDark : theme => theme.mainBackgroundColor }, textAreaStyles);
    const borderErrorStyle = Object.assign(Object.assign({}, defaultStyle), { border: { xy: { color: stateRed, width: thin, style: 'solid' } } });
    return (React.createElement(styled.Label, { css: error ? borderErrorStyle : defaultStyle },
        React.createElement(Field, null,
            React.createElement(Placeholder, { css: filled && placeholderFilledStyle }, realPlaceholder),
            React.createElement(BareInputWithoutFocusVisuals, Object.assign({}, restProps, { onFocus: internalOnFocus, onBlur: internalOnBlur, onChange: internalOnChange, ref: elementRef, css: !editable && {
                    color: UNIMPORTANT_TEXT_COLOR,
                } })))));
}, createTransitionDecorator({ property: 'background' })));
const DefaultText = createGeneralDecorator(General.Small, false)(Text, { minWidth: '100%' });
export default DefaultText;
function isFilled(value) {
    return !!value && /[\S]/.test(value);
}
const FIELDS_GUTTER_ROW = tiny;
const Field = styled.div({
    position: 'relative',
    flex: {
        grow: 1,
        shrink: 0,
    },
    maxWidth: '100%',
});
const Placeholder = styled.span({
    position: 'absolute',
    overflow: 'hidden',
    width: '100%',
    color: UNIMPORTANT_TEXT_COLOR,
    pointerEvents: 'none',
});
const placeholderFilledStyle = styled({
    opacity: 0,
    pointerEvents: 'none',
});
export const resetInputStyled = styled({
    width: '100%',
    padding: { y: eta, left: eta - 2, right: 0 },
    border: { xy: { width: 0 } },
    borderRadius: 2,
    color: 'inherit',
    backgroundColor: 'transparent',
    ':focus': {
        outlineWidth: 0,
    },
    outlineStyle: 'none',
    cursor: 'inherit',
    resize: 'none',
    overflow: 'auto',
});
const BareInputWithoutFocusVisuals = resetInputStyled(styled.Textarea, Object.assign(Object.assign({}, createTransitionDecorator({ property: 'color' })), (isBrowser() && isIOS() && { fontSize: 16 })));
export const BareInput = createTabbingFocusElement(BareInputWithoutFocusVisuals);
export const fieldStyled = styled({
    flexGrow: 1,
    margin: { top: FIELDS_GUTTER_ROW },
    minWidth: ({ isCompact }) => (!isCompact ? 250 : `90%`),
});
export const TextArea = fieldStyled(DefaultText, {
    fontSize: delta,
    width: '100%',
});
