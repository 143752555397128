import { __awaiter, __rest } from "tslib";
import React, { useState, useRef, useMemo } from 'react';
import { styled } from '@glitz/react';
import { Spinner } from 'Shared/Icon';
import { Check as FulfilledIcon } from 'Shared/Icon';
import { Error as RejectedIcon } from 'Shared/Icon';
import Button, { Theme } from '.';
import timeout from 'Shared/timeout';
import { createTruncateDecorator, createTransitionDecorator, linkColor, mediaMinMicro } from 'Shared/Style';
import { media } from '@glitz/core';
export { Theme } from './';
export var Behavior;
(function (Behavior) {
    /** Only clickable while idle, will never leave fulfilled state once reached. Default */
    Behavior[Behavior["SingleSuccess"] = 0] = "SingleSuccess";
    /** Only clickable while idle or fulfilled. Will revert back from fulfilled to idle */
    Behavior[Behavior["MultipleSuccesses"] = 1] = "MultipleSuccesses";
    /** Always clickable. Will revert back from fulfilled to idle. */
    Behavior[Behavior["KeepEnabled"] = 2] = "KeepEnabled";
})(Behavior || (Behavior = {}));
export var Status;
(function (Status) {
    Status[Status["Default"] = 0] = "Default";
    Status[Status["Pending"] = 1] = "Pending";
    Status[Status["Fulfilled"] = 2] = "Fulfilled";
    Status[Status["Rejected"] = 3] = "Rejected";
})(Status || (Status = {}));
export function useFeedback(options = {}) {
    const { minimumPending = 500, maximumFulfilled = options.behavior === Behavior.SingleSuccess ? Infinity : 2000, maximumRejected = 2000, behavior = Behavior.MultipleSuccesses, } = options;
    const [status, setStatus] = useState(Status.Default);
    const [text, setText] = useState(null);
    const [enabled, setEnabled] = useState(true);
    const asyncOperationQueueRef = useRef(Promise.resolve());
    function push(asyncOperation) {
        return __awaiter(this, void 0, void 0, function* () {
            const queue = (asyncOperationQueueRef.current = Promise.all([asyncOperation, asyncOperationQueueRef.current]));
            const isLastOperation = () => queue === asyncOperationQueueRef.current;
            setStatus(Status.Pending);
            setEnabled(behavior === Behavior.KeepEnabled);
            // Sometimes you want the spinner to be visible so the user has a chance
            // to notice that something happen. Studies shows that users today expects
            // that things like this take some time. So if it's to quick they
            // assume that something went wrong. I know... stupid... right?
            const minimumPendingTimer = timeout(minimumPending);
            try {
                const [[nextText = null]] = [yield queue, yield minimumPendingTimer];
                if (isLastOperation()) {
                    const nextEnabled = behavior !== Behavior.SingleSuccess;
                    if (maximumFulfilled > 0) {
                        setStatus(Status.Fulfilled);
                        setEnabled(nextEnabled);
                        if (typeof nextText === 'string') {
                            setText(nextText);
                        }
                    }
                    if (maximumFulfilled < Infinity) {
                        yield timeout(maximumFulfilled);
                        if (isLastOperation()) {
                            setStatus(Status.Default);
                            setText(null);
                            setEnabled(nextEnabled);
                        }
                    }
                }
            }
            catch (errorText) {
                if (isLastOperation()) {
                    if (maximumRejected > 0) {
                        setStatus(Status.Rejected);
                        setText(errorText || null);
                        setEnabled(true);
                    }
                    if (maximumRejected < Infinity) {
                        yield timeout(maximumRejected);
                        if (isLastOperation()) {
                            setStatus(Status.Default);
                            setText(null);
                            setEnabled(true);
                        }
                    }
                }
            }
            if (isLastOperation()) {
                asyncOperationQueueRef.current = Promise.resolve();
            }
            return Promise.resolve();
        });
    }
    const state = useMemo(() => ({ status, enabled, text }), [status, enabled, text]);
    return [state, push];
}
export function createFeedbackSlaveButton(Component = Button) {
    return styled((_a) => {
        var { state: { status, text, enabled }, as, disabled, children } = _a, restProps = __rest(_a, ["state", "as", "disabled", "children"]);
        const isDefault = status === Status.Default;
        const isPending = status === Status.Pending;
        const isFulFilled = status === Status.Fulfilled;
        const isRejected = status === Status.Rejected;
        let theme = as;
        // Always keep `None` theme
        if (theme !== Theme.None) {
            if (isRejected) {
                theme = Theme.Negative;
            }
            else if (isFulFilled) {
                theme = Theme.Positive;
            }
        }
        return (React.createElement(Component, Object.assign({ css: __$hoisted_o0 }, restProps, { disabled: !enabled || disabled, as: theme }),
            React.createElement(Text, { visible: isDefault }, children),
            !isDefault && (React.createElement(Layer, { visible: isFulFilled || isRejected },
                isFulFilled && (typeof text === 'string' ? text : React.createElement(CheckIcon, { color: 'inherit', size: 'lg' })),
                isRejected && (typeof text === 'string' ? text : React.createElement(RejectedIcon, { color: 'inherit', size: 'lg' })))),
            !isDefault && React.createElement(Layer, { visible: isPending }, React.createElement(SpinnerIcon, null))));
    });
}
export const FeedbackSlaveButton = createFeedbackSlaveButton();
const Text = styled((_a) => {
    var { visible } = _a, restProps = __rest(_a, ["visible"]);
    return (React.createElement(styled.Span, Object.assign({}, restProps, { css: styled(createTruncateDecorator(), createTransitionDecorator({ property: ['opacity', 'transform'] }), Object.assign({ display: 'flex', alignItems: 'center', justifyContent: 'center', 
            // There's a bug in Chrome that doesn't change the appearance of text color when
            // the browsers is performance optimizing the element and is completely hidden, that's
            // why the value is set to `0.01`
            opacity: visible ? 1 : 0.01, transform: visible ? 'scale(1)' : 'scale(0.01)' }, media(mediaMinMicro, Object.assign({ display: 'inline-block', maxWidth: '100%', whiteSpace: 'nowrap', justifyContent: 'start' }, createTruncateDecorator())))) })));
});
const SpinnerIcon = styled(Spinner, {
    color: linkColor,
    fill: linkColor,
});
const CheckIcon = styled(FulfilledIcon, {});
const Layer = styled(Text, {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    textAlign: 'center',
    paddingTop: 'inherit',
    paddingBottom: 'inherit',
});
export function createFeedbackStandaloneButton(Component = Button) {
    const Slave = createFeedbackSlaveButton(Component);
    return styled((_a) => {
        var { minimumPending, maximumFulfilled, maximumRejected, behavior, onClick } = _a, restProps = __rest(_a, ["minimumPending", "maximumFulfilled", "maximumRejected", "behavior", "onClick"]);
        const [state, push] = useFeedback({ minimumPending, maximumFulfilled, maximumRejected, behavior });
        function pushOnClick(e) {
            if (onClick) {
                push(onClick(e));
            }
        }
        return React.createElement(Slave, Object.assign({}, restProps, { onClick: pushOnClick, state: state }));
    });
}
const FeedbackStandaloneButton = createFeedbackStandaloneButton();
export default FeedbackStandaloneButton;
const __$hoisted_o0 = { position: 'relative' };
