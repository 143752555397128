import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useIsMobile } from 'Shared/use-viewport';
import { GA4Affiliation, addToDataLayer, listNames } from 'TrackingInformation';
import { useCheckoutActions } from 'Checkout/Context';
import { getJson, ENTER_KEY, URLX, pathCombine, translate, useDynamicData } from '@avensia/scope';
import FullQuickOrder from './Full';
import CompactQuickOrder from './Compact';
import { searchPageUrl } from 'Shared/known-urls';
import { useMiniCart } from 'SiteLayout/MiniCart/use-minicart';
import { getCurrentUser } from 'Shared/dynamic-data';
import { useSelector } from '../../Shared/State';
import { createQuickOrderSearchGa4 } from 'TrackingInformation/ga4';
const SEARCH_DEBOUNCE = 200;
const SEARCH_MIN_CHAR = 3;
const QuickOrderSearch = () => {
    const isMobile = useIsMobile();
    const searchTimeoutRef = useRef();
    const actions = useCheckoutActions();
    const [showLoader, setShowLoader] = useState(false);
    const [message, setMessage] = useState('');
    const [searchText, setSearchText] = useState('');
    const [customerComment, setCustomerComment] = useState('');
    const [selectedOrder, setSelectedOrder] = useState();
    const [quantityValue, setQuantityValue] = useState(1);
    const [quickOrders, setQuickOrders] = useState();
    const [isQuantityValid, setIsQuantityValid] = useState(true);
    const currentUser = useDynamicData(getCurrentUser());
    const isB2BUser = (currentUser === null || currentUser === void 0 ? void 0 : currentUser.isLoggedIn) && (currentUser === null || currentUser === void 0 ? void 0 : currentUser.isB2B);
    const clearTimeouts = useCallback(() => {
        clearTimeout(searchTimeoutRef.current);
    }, []);
    const onCommentChange = useCallback((e) => {
        const value = e.currentTarget.value;
        setCustomerComment(value);
    }, []);
    const onUpdateQuantity = useCallback((newValue) => {
        setQuantityValue(newValue);
    }, []);
    const onSelectProduct = useCallback((order) => {
        setSelectedOrder(order);
        setSearchText(order.sku);
    }, []);
    const orderSearchResults = useCallback((query) => {
        const url = new URLX(pathCombine(searchPageUrl(), 'quickordersearch'));
        url.searchParams.set('searchphrase', query.trim());
        getJson(url).then((result) => {
            if (result) {
                if ((result === null || result === void 0 ? void 0 : result.length) === 0) {
                    setMessage(translate('/QuickOrder/NoMatchingRecords'));
                }
                setQuickOrders(result);
                setShowLoader(false);
                // triggers ga4 event if there are a return matched items.
                if (isB2BUser) {
                    addToDataLayer(createQuickOrderSearchGa4(query));
                }
                return Promise.resolve();
            }
            else {
                return Promise.reject();
            }
        }, () => {
            return Promise.reject();
        });
    }, [isB2BUser]);
    const countrySettings = useSelector(state => state.appShellData.countrySettings);
    const [selectedPair, setSelectedPair] = useState(countrySettings.defaultUnit);
    const onPairChange = useCallback((value) => {
        setSelectedPair(value);
    }, []);
    const { toggleMiniCart } = useMiniCart();
    const addToCart = useCallback(() => {
        if (selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder.code) {
            if ((countrySettings === null || countrySettings === void 0 ? void 0 : countrySettings.isHalfPairEnabled) && (selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder.halfPairAvailable)) {
                const codeSuffix = '¤' + selectedPair;
                actions.addToCart((selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder.code) + codeSuffix, quantityValue, null, listNames.quickOrder, GA4Affiliation.QUICK_ORDER, {
                    patientName: null,
                    customerComment: customerComment,
                    unit: selectedPair,
                    mtmMeasurements: [],
                });
                return toggleMiniCart();
            }
            else {
                actions.addToCart(selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder.code, quantityValue, null, listNames.quickOrder, GA4Affiliation.QUICK_ORDER, {
                    patientName: null,
                    customerComment: customerComment,
                    unit: null,
                    mtmMeasurements: null,
                });
                return toggleMiniCart();
            }
        }
    }, [
        actions,
        countrySettings === null || countrySettings === void 0 ? void 0 : countrySettings.isHalfPairEnabled,
        customerComment,
        quantityValue,
        selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder.code,
        selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder.halfPairAvailable,
        selectedPair,
        toggleMiniCart,
    ]);
    const debounceSearch = useCallback((query) => {
        clearTimeouts();
        searchTimeoutRef.current = setTimeout(() => {
            orderSearchResults(query);
        }, SEARCH_DEBOUNCE);
    }, [clearTimeouts, orderSearchResults]);
    const reset = useCallback(() => {
        setSearchText('');
        setQuickOrders([]);
        setMessage('');
        setSelectedOrder(null);
    }, []);
    const pairOptions = useMemo(() => {
        const options = countrySettings.halfPairUnits.slice();
        return options.map((option) => {
            return { text: option.text, value: option.key };
        });
    }, [countrySettings]);
    const onSearchChange = useCallback((e) => {
        const value = e.currentTarget.value;
        setSearchText(value);
        if (value.length > 0) {
            if (value.length >= SEARCH_MIN_CHAR) {
                setMessage('');
                setQuickOrders([]);
                setShowLoader(true);
                debounceSearch(value);
            }
        }
        else {
            clearTimeouts();
            reset();
        }
    }, [clearTimeouts, debounceSearch, reset]);
    const onClickClear = useCallback(() => {
        reset();
    }, [reset]);
    const onKeyPress = useCallback((e) => {
        if (e.keyCode === ENTER_KEY) {
            if ((quickOrders === null || quickOrders === void 0 ? void 0 : quickOrders.length) === 1) {
                onSelectProduct(quickOrders[0]);
            }
        }
    }, [onSelectProduct, quickOrders]);
    return isMobile ? (React.createElement(CompactQuickOrder, { searchText: searchText, onSearchChange: onSearchChange, onCustomerCommentChange: onCommentChange, customerComment: customerComment, quantityValue: quantityValue, isQuantityValid: isQuantityValid, updateIsQuantityValid: setIsQuantityValid, onSelectProduct: onSelectProduct, selectedOrder: selectedOrder, onUpdateQuantity: onUpdateQuantity, quickOrders: quickOrders, onClickAdd: addToCart, message: message, showLoader: showLoader, pairOptions: pairOptions, selectedPair: selectedPair, isHalfPairEnabled: countrySettings.isHalfPairEnabled, onClickClear: onClickClear, onChangePair: onPairChange, onKeyPress: onKeyPress, isB2B: isB2BUser })) : (React.createElement(FullQuickOrder, { searchText: searchText, onSearchChange: onSearchChange, onCustomerCommentChange: onCommentChange, customerComment: customerComment, quantityValue: quantityValue, isQuantityValid: isQuantityValid, updateIsQuantityValid: setIsQuantityValid, onSelectProduct: onSelectProduct, selectedOrder: selectedOrder, onUpdateQuantity: onUpdateQuantity, quickOrders: quickOrders, onClickAdd: addToCart, message: message, showLoader: showLoader, pairOptions: pairOptions, selectedPair: selectedPair, isHalfPairEnabled: countrySettings.isHalfPairEnabled, onClickClear: onClickClear, onChangePair: onPairChange, onKeyPress: onKeyPress, isB2B: isB2BUser }));
};
export default QuickOrderSearch;
