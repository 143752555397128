export function loadIntersectionObserverPolyfillIfNeeded() {
    if (__BROWSER__ && (typeof __TEST__ === 'undefined' || !__TEST__)) {
        if (!('IntersectionObserver' in window) &&
            !('IntersectionObserverEntry' in window) &&
            !(window.IntersectionObserverEntry && 'intersectionRatio' in window.IntersectionObserverEntry.prototype)) {
            return import(/* webpackChunkName: "intersection-observer-polyfill" */ 'intersection-observer');
        }
    }
    return Promise.resolve();
}
