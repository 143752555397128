import { __rest } from "tslib";
import * as React from 'react';
import { styled } from '@glitz/react';
export var Ratio;
(function (Ratio) {
    Ratio[Ratio["OneToOne"] = 1] = "OneToOne";
    Ratio[Ratio["TwoToThree"] = 1.5] = "TwoToThree";
    Ratio[Ratio["ThreeToTwo"] = 0.6666666666666666] = "ThreeToTwo";
    Ratio[Ratio["ThreeToFour"] = 1.3333333333333333] = "ThreeToFour";
    Ratio[Ratio["FourToThree"] = 0.75] = "FourToThree";
    Ratio[Ratio["SixteenToNine"] = 0.5625] = "SixteenToNine";
    Ratio[Ratio["NineToTwelve"] = 1.3333333333333333] = "NineToTwelve";
    Ratio[Ratio["ThirteenEight"] = 0.6153846153846154] = "ThirteenEight";
    Ratio[Ratio["EightThirteen"] = 1.625] = "EightThirteen";
})(Ratio || (Ratio = {}));
const AspectRatio = styled((_a) => {
    var { ratio } = _a, restProps = __rest(_a, ["ratio"]);
    const baseDecorator = React.useMemo(() => styled({
        position: 'relative',
        overflow: 'hidden',
        ':before': {
            content: '""',
            float: 'left',
            paddingTop: `${ratio * 100}%`,
        },
    }), [ratio]);
    return React.createElement(styled.Div, Object.assign({}, restProps, { css: baseDecorator }));
});
export default AspectRatio;
