import React, { useRef, useEffect, useState } from 'react';
import { styled } from '@glitz/react';
import * as style from 'Shared/Style';
import { useCurrentBreakpoint } from 'Shared/use-viewport';
const DURATION = 150;
const Body = styled.div(Object.assign({ position: 'relative', background: {
        color: 'inherit',
    }, overflow: 'hidden', margin: {
        x: 'auto',
    } }, style.createTransitionDecorator({
    property: 'height',
    duration: `${DURATION}ms`,
    willChange: true,
})));
const Panel = styled.div({
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
});
const DropDown = props => {
    const { isOpen, children } = props;
    const panelRef = useRef();
    const [height, setHeight] = useState(0);
    const breakpoint = useCurrentBreakpoint();
    useEffect(() => {
        if (panelRef && isOpen) {
            const refHeight = panelRef.current.offsetHeight;
            requestAnimationFrame(() => {
                if (height !== refHeight) {
                    setHeight(refHeight);
                }
            });
        }
    }, [isOpen, panelRef, breakpoint, height]);
    return (React.createElement(styled.Div, null,
        React.createElement(Body, { css: { height: isOpen ? height : 0 } },
            React.createElement(Panel, { ref: panelRef }, children))));
};
export default styled(DropDown);
