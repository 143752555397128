import { TOGGLE_MADE_TO_MEASURE, SET_MODAL_TAB_INDEX } from './action-creators';
const defaultState = {
    isOpen: false,
    modalTabIndex: 0,
};
export default function (state = defaultState, action) {
    switch (action.type) {
        case SET_MODAL_TAB_INDEX:
            return Object.assign(Object.assign({}, state), { modalTabIndex: action.modalTabIndex });
        case TOGGLE_MADE_TO_MEASURE:
            return Object.assign(Object.assign({}, state), { isOpen: !state.isOpen });
        default:
            return state;
    }
}
