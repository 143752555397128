import constants from './constants';
import { floatToCSSNumber } from './unit';
import { styled } from '@glitz/react';
const { BASE_FONT_SIZE, BASE_TEXT_COLOR, BASE_LINE_HEIGHT } = constants;
export const defaultFontSize = BASE_FONT_SIZE;
export const defaultTextColor = BASE_TEXT_COLOR;
export const defaultLineHeight = BASE_LINE_HEIGHT * BASE_FONT_SIZE;
export const alternativeTextColor = '#FFFFFF';
export var FontWeight;
(function (FontWeight) {
    FontWeight[FontWeight["Normal"] = 400] = "Normal";
    FontWeight[FontWeight["SemiBold"] = 600] = "SemiBold";
    FontWeight[FontWeight["Bold"] = 700] = "Bold";
})(FontWeight || (FontWeight = {}));
// Large font sizes
export const giga = 72;
export const mega = 60;
export const kilo = 44;
export const omega = 48;
// Heading font sizes
export const charlie = 40;
export const iota = 36;
export const alpha = 32;
export const fox = 28;
export const beta = 24;
export const gamma = 20;
export const kappa = 22;
export const chi = 21;
export const delta = 18;
export const figma = 16;
export const epsilon = 14;
export const rhi = 13;
export const zeta = 12;
export const eta = 10;
export const lineHeight = floatToCSSNumber(BASE_LINE_HEIGHT);
export function sourceSansProCaps(fontSize = delta) {
    return styled({
        fontSize,
        textTransform: 'uppercase',
    });
}
export function sourceSansPro(fontSize = delta) {
    return styled({
        fontSize,
        fontWeight: FontWeight.Normal,
    });
}
export function sourceSansProSemiBold(fontSize = delta) {
    return styled({
        fontSize,
        fontWeight: FontWeight.SemiBold,
    });
}
export function sourceSansProBold(fontSize = delta) {
    return styled({
        fontSize,
        fontWeight: FontWeight.Bold,
    });
}
export function calculateLineHeightInPixels(fontSize) {
    return floatToCSSNumber(fontSize * lineHeight);
}
export function createTruncateDecorator(overflow = 'ellipsis') {
    return styled({
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: overflow,
    });
}
export const preserveEmptyDecorator = styled({
    // If breadcrumbs is an empty list we want to preserve the height because an
    // empty list means that breadcrumbs will be loaded and displayed eventually
    ':empty': {
        ':before': {
            content: '" "',
            whiteSpace: 'pre',
        },
    },
});
export function multilineEllipsis(_lineCount = 1, _lineHeight = 1.2) {
    return styled({
        overflow: 'hidden',
        lineHeight: _lineHeight,
        maxHeight: `${_lineHeight * _lineCount}em`,
        textOverflow: 'ellipsis',
        WebkitBoxOrient: 'vertical',
        display: '-webkit-box',
        WebkitLineClamp: _lineCount,
    });
}
