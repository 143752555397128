import { useContext } from 'react';
import { StartPageContext } from 'Start/current-page-is-start';
import { Breakpoint, widthFromBreakpoint } from '@avensia/scope';
export var CtaStyle;
(function (CtaStyle) {
    CtaStyle["Solid"] = "solid";
    CtaStyle["Outlined"] = "outlined";
    CtaStyle["Link"] = "link";
})(CtaStyle || (CtaStyle = {}));
export var SigvarisPageWidth;
(function (SigvarisPageWidth) {
    SigvarisPageWidth[SigvarisPageWidth["ContentPageWidth"] = 1080] = "ContentPageWidth";
    SigvarisPageWidth[SigvarisPageWidth["StartPageWidth"] = widthFromBreakpoint(Breakpoint.Large)] = "StartPageWidth";
})(SigvarisPageWidth || (SigvarisPageWidth = {}));
export const SetPageWidth = () => {
    const { isStartPage } = useContext(StartPageContext);
    if (isStartPage)
        return SigvarisPageWidth.StartPageWidth;
    else
        return SigvarisPageWidth.ContentPageWidth;
};
