import React from 'react';
import { Provider } from 'react-redux';
import SiteLayout from './';
import { CheckoutProvider } from 'Checkout/Context';
import { WhiteTheme } from 'Shared/Theme';
export default function Container(props) {
    return (React.createElement(Provider, { store: props.store },
        React.createElement(CheckoutProvider, { store: props.store },
            React.createElement(WhiteTheme, null,
                React.createElement(SiteLayout, null)))));
}
