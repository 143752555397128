import { styled } from '@glitz/react';
import { widthFromBreakpoint } from '@avensia/scope';
import { pageNormalBreakpoint, ZIndex } from 'Shared/Style';
export default styled.div({
    position: 'fixed',
    left: '50%',
    bottom: 0,
    width: widthFromBreakpoint(pageNormalBreakpoint),
    maxWidth: '100%',
    transform: 'translateX(-50%)',
    pointerEvents: 'none',
    zIndex: ZIndex.Tray,
});
