import React, { useRef, useEffect, useState, useContext } from 'react';
import { styled } from '@glitz/react';
import { ArrowDown, ArrowRight } from 'Shared/Icon';
import { fullButtonDecorator, PlainButton } from 'Shared/Button';
import { contentHeaderLinkGrey, createTransitionDecorator, large, thick, small, delta, average } from 'Shared/Style';
import { selector } from '@glitz/core';
import { CampaignBrandContext } from 'Pages/Campaign/CampaignBrandPageContext';
import { useCurrentBreakpoint } from 'Shared/use-viewport';
import { useCurrentTheme } from 'SiteLayout/Theming';
const DURATION = 300;
const HeaderLayout = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: delta,
    margin: {
        x: 'auto',
    },
});
const Body = styled.div(Object.assign({ position: 'relative', background: {
        color: 'inherit',
    }, overflow: 'hidden', margin: {
        x: 'auto',
    } }, createTransitionDecorator({
    property: 'height',
    duration: `${DURATION}ms`,
    willChange: true,
})));
const Panel = styled.div({
    position: 'absolute',
    right: 0,
    bottom: 0,
    left: 0,
});
const Accordion = (props) => {
    const { themeColor, themeColorLight } = useContext(CampaignBrandContext);
    const { isGreyArrow, header, isOpen, children, onToggle } = props;
    const panelRef = useRef();
    const [height, setHeight] = useState(0);
    const breakpoint = useCurrentBreakpoint();
    const siteTheme = useCurrentTheme();
    useEffect(() => {
        if (panelRef && isOpen) {
            requestAnimationFrame(() => {
                const refHeight = panelRef.current.offsetHeight;
                if (height !== refHeight) {
                    setHeight(refHeight);
                }
            });
        }
    }, [isOpen, panelRef, breakpoint, height]);
    const headerLayoutHoverStyle = Object.assign(Object.assign({ margin: { y: small }, borderRadius: thick }, selector(':not([disabled]):hover', {
        backgroundColor: themeColor ? themeColorLight : siteTheme.accordion.hoverColor,
    })), createTransitionDecorator({ property: 'background' }));
    return (React.createElement(styled.Div, null,
        React.createElement(FullButton, { css: isGreyArrow && headerLayoutHoverStyle, onClick: onToggle },
            React.createElement(HeaderLayout, null,
                header,
                isOpen ? (React.createElement(ArrowDown, { css: arrowStyle, color: isGreyArrow ? contentHeaderLinkGrey : 'inherit' })) : (React.createElement(ArrowRight, { css: arrowStyle, color: isGreyArrow ? contentHeaderLinkGrey : 'inherit' })))),
        React.createElement(Body, { css: {
                height: isOpen ? height : 0,
            } },
            React.createElement(Panel, { ref: panelRef }, children))));
};
const FullButton = styled(PlainButton, fullButtonDecorator);
const arrowStyle = {
    marginRight: ({ isCompact }) => (isCompact ? average : large),
};
export default styled(Accordion);
