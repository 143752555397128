import { __awaiter } from "tslib";
import React from 'react';
import { styled } from '@glitz/react';
import { translate } from '@avensia/scope';
import { LargeSquareButton } from 'Shared/Button';
import { useFeedback, createFeedbackSlaveButton, Theme as ButtonTheme } from 'Shared/Button/Feedback';
import { Heart, HeartFilled } from 'Shared/Icon';
import { linkColor } from 'Shared/Style';
import { testIdProps } from '../test-automation';
import { useDispatch } from 'react-redux';
import { removeFromWishList, addToWishList } from 'SiteLayout/WishList/action-creators';
import { useSelector } from 'Shared/State';
export default styled(function Wishlist(props) {
    const [addState, pushAdd] = useFeedback();
    const dispatch = useDispatch();
    const wishListItems = useSelector(state => state.wishList.codes);
    const isSelected = wishListItems.filter(i => (props.variationCode ? i === props.variationCode : i === props.code)).length > 0;
    function handleClick() {
        return __awaiter(this, void 0, void 0, function* () {
            const action = () => __awaiter(this, void 0, void 0, function* () {
                if (isSelected) {
                    props.onFavoriteClick && props.onFavoriteClick('remove');
                    dispatch(removeFromWishList(props.code, props.variationCode));
                }
                else {
                    props.onFavoriteClick && props.onFavoriteClick('add');
                    dispatch(addToWishList(props.code, props.variationCode));
                }
                return Promise.resolve();
            });
            yield pushAdd(action());
        });
    }
    return (React.createElement(AddToWishlistButton, Object.assign({}, testIdProps.ToggleWishlistItemButton, { state: addState, title: isSelected ? translate('/Account/Wishlist/ExistsInWishList') : translate('/Account/Wishlist/AddToList'), as: props.transparent ? ButtonTheme.None : ButtonTheme.Secondary, onClick: handleClick }), isSelected ? React.createElement(HeartFilled, { size: 'lg', color: linkColor }) : React.createElement(Heart, { size: 'lg', color: linkColor })));
});
const AddToWishlistButton = createFeedbackSlaveButton(LargeSquareButton);
