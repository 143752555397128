import { __awaiter } from "tslib";
import { invalidateAndResolveData } from '@avensia/scope';
let appShellData = null;
export function setAppShellData(data) {
    appShellData = data;
}
export function getCart() {
    return appShellData.cart;
}
export function getCurrentUser() {
    return appShellData.currentUser;
}
export function forceUpdateDynamicCurrentUserData() {
    return __awaiter(this, void 0, void 0, function* () {
        yield invalidateAndResolveData(appShellData.currentUser);
    });
}
export function forceUpdateDynamicData() {
    return __awaiter(this, void 0, void 0, function* () {
        yield invalidateAndResolveData(appShellData.cart);
        yield invalidateAndResolveData(appShellData.currentUser);
    });
}
export function getMainMenu() {
    return appShellData.mainMenu.mainMenuTree;
}
