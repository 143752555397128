import { SET_RETAILERS, SET_RETAILERS_FETCH_DONE, SET_STARTRECORD, FETCH_FIND_RETAILERS, SET_CURRENT_RETAILERS, RESET_FIND_RETAILERS, } from './action-creators';
export default function (state = null, action) {
    if (state === null) {
        state = {
            retailers: [],
            startRecord: 0,
            isRetailerFetchDone: false,
            currentRetailers: [],
            isResetFindRetailer: false,
        };
    }
    switch (action.type) {
        case SET_RETAILERS:
            return Object.assign(Object.assign({}, state), { retailers: action.retailers });
        case SET_RETAILERS_FETCH_DONE:
            return Object.assign(Object.assign({}, state), { isRetailerFetchDone: action.isRetailerFetchDone });
        case RESET_FIND_RETAILERS:
            return Object.assign(Object.assign({}, state), { isResetFindRetailer: action.isResetFindRetailer });
        case SET_STARTRECORD:
            return Object.assign(Object.assign({}, state), { startRecord: action.startRecord });
        case SET_CURRENT_RETAILERS:
            return Object.assign(Object.assign({}, state), { currentRetailers: action.currentRetailers, retailers: action.retailers });
        case FETCH_FIND_RETAILERS:
            return Object.assign(Object.assign({}, state), { retailers: action.retailers });
        default:
            break;
    }
    return state;
}
