import React, { useRef, useEffect, useContext } from 'react';
import { styled } from '@glitz/react';
import { TransitionMotion, spring } from 'react-motion';
import { itemKey, ROOT_KEY } from '../MainMenu/item-helpers';
import Level, { isRoot } from './Level';
import { pixelsToUnit } from 'Shared/Style';
import { TabTrappingContext } from 'Shared/a11y/TabTrap';
const TRANSLATE_X_LEFT = -100;
const TRANSLATE_X_RIGHT = 100;
export default function Nav(props) {
    const { isSecondRowHighlight, isFirstRowHighlight } = props;
    const previousPrimaryRef = useRef(props.primary);
    const enumerateRef = useRef(0);
    let pushing = false;
    if (previousPrimaryRef.current !== props.primary) {
        pushing = isPushing(previousPrimaryRef.current, props.primary);
        enumerateRef.current++;
    }
    previousPrimaryRef.current = props.primary;
    const currentKeyRef = useRef();
    function enumeratedKey(key) {
        return `${key}${enumerateRef.current}`;
    }
    function getStyles() {
        return [
            {
                key: (currentKeyRef.current = enumeratedKey(isRoot(props.primary) ? ROOT_KEY : itemKey(props.primary))),
                data: {
                    level: props.primary,
                    children: props.children,
                },
                style: { translateX: spring(0), opacity: spring(1) },
            },
        ];
    }
    function willEnter() {
        return {
            translateX: pushing ? TRANSLATE_X_RIGHT : TRANSLATE_X_LEFT,
            opacity: 0,
        };
    }
    function willLeave() {
        return {
            translateX: spring(pushing ? TRANSLATE_X_LEFT : TRANSLATE_X_RIGHT),
            opacity: spring(0),
        };
    }
    const { resetTabFocus } = useContext(TabTrappingContext);
    useEffect(() => {
        resetTabFocus();
    }, [props.primary, resetTabFocus]);
    return (React.createElement(TransitionMotion, { styles: getStyles, willEnter: willEnter, willLeave: willLeave }, (interpolatedStyles) => (React.createElement(Base, null, interpolatedStyles.map(entry => {
        const headline = !isRoot(entry.data.level) && entry.data.level.firstRowHeadline;
        const secondRowHeadline = !isRoot(entry.data.level) && entry.data.level.secondRowHeadline;
        const firstRowHighlight = isRoot(entry.data.level)
            ? isFirstRowHighlight
            : entry.data.level.isFirstRowHighlight;
        const secondRowHighlight = isRoot(entry.data.level)
            ? isSecondRowHighlight
            : entry.data.level.isSecondRowHighlight;
        return (React.createElement(Entry, { key: entry.key, style: entry.style.translateX !== 0 || entry.style.opacity !== 1
                ? Object.assign({ transform: `translateX(${pixelsToUnit(entry.style.translateX)})`, opacity: entry.style.opacity }, (entry.key !== currentKeyRef.current && {
                    pointerEvents: 'none',
                })) : null },
            React.createElement(Level, { data: entry.data.level, pushLevel: props.setLevel, popLevel: props.popLevel, headline: headline, secondRowHeadline: secondRowHeadline, isFirstRowHighlight: firstRowHighlight, isSecondRowHighlight: secondRowHighlight }),
            isRoot(entry.data.level) && entry.data.children));
    })))));
}
function isPushing(previousPrimary, currentPrimary) {
    if (isRoot(previousPrimary)) {
        return true;
    }
    if (isRoot(currentPrimary)) {
        return false;
    }
    return (previousPrimary.children &&
        previousPrimary.children.length > 0 &&
        !previousPrimary.children.every(item => item !== currentPrimary));
}
const Base = styled.nav({
    position: 'relative',
    height: '100%',
    overflowX: 'hidden',
});
const Entry = styled.div({
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    willChange: 'opacity, transform',
    overflowX: 'hidden',
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
});
