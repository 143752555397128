import React from 'react';
import { styled, applyClassName } from '@glitz/react';
import * as style from '../Style';
export const iconDecorator = styled({
    display: 'inline-flex',
    width: '1em',
    height: '1em',
    fontSize: style.pixelsToUnit(style.defaultLineHeight, style.Unit.Em),
});
export default function factory(Glyph) {
    return styled(applyClassName((props) => React.createElement(Glyph, Object.assign({ width: "1em", height: "1em", alt: "" }, props))), iconDecorator);
}
