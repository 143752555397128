import { URLX, currentUrl } from '@avensia/scope';
export const ROOT_KEY = '/';
export function itemKey(item, index) {
    return `${item === null || item === void 0 ? void 0 : item.menuId}_${index}`;
}
export function findItem(items, key) {
    var _a, _b, _c;
    if (Array.isArray(items)) {
        for (const item of items) {
            if (itemKey(item) === key) {
                return item;
            }
            if ((item === null || item === void 0 ? void 0 : item.children) && ((_a = item.children) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                const foundItem = findItem(item === null || item === void 0 ? void 0 : item.children, key);
                if (foundItem) {
                    return foundItem;
                }
            }
            if ((item === null || item === void 0 ? void 0 : item.secondRowChildren) && ((_b = item.secondRowChildren) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                const foundItem = findItem(item === null || item === void 0 ? void 0 : item.secondRowChildren, key);
                if (foundItem) {
                    return foundItem;
                }
            }
            const foundItem = findItem((_c = item === null || item === void 0 ? void 0 : item.children) === null || _c === void 0 ? void 0 : _c.concat(item === null || item === void 0 ? void 0 : item.secondRowChildren), key);
            if (foundItem) {
                return foundItem;
            }
        }
    }
}
export function findParentKey(items, visibleKey, parentKey = ROOT_KEY) {
    if (Array.isArray(items)) {
        for (const item of items) {
            const currentKey = itemKey(item);
            if (currentKey === visibleKey) {
                return parentKey;
            }
            if ((item === null || item === void 0 ? void 0 : item.children) && item.children.length > 0) {
                const key = findParentKey(item === null || item === void 0 ? void 0 : item.children, visibleKey, currentKey);
                if (key) {
                    return key;
                }
            }
            if ((item === null || item === void 0 ? void 0 : item.secondRowChildren) && item.secondRowChildren.length > 0) {
                const key = findParentKey(item === null || item === void 0 ? void 0 : item.secondRowChildren, visibleKey, currentKey);
                if (key) {
                    return key;
                }
            }
        }
    }
    return null;
}
function isCurrentCategory(item) {
    const current = currentUrl();
    const itemUrl = item.url === null ? new URLX(ROOT_KEY) : new URLX(item.url);
    return current.pathname.startsWith(itemUrl === null || itemUrl === void 0 ? void 0 : itemUrl.pathname);
}
export function isCategoryActive(item, mainMenu) {
    return ((isCurrentCategory(item) && !mainMenu.mainMenuOpen) ||
        (mainMenu.mainMenuOpen && mainMenu.openMenuItem && item.url === mainMenu.openMenuItem.url));
}
export function convertUrlToId(url) {
    return `menu${url === null || url === void 0 ? void 0 : url.replace('/', '-')}`;
}
export function isDummyChildOnly(item) {
    var _a, _b, _c, _d, _e;
    return (((_a = item === null || item === void 0 ? void 0 : item.children) === null || _a === void 0 ? void 0 : _a.length) === 1 &&
        ((_b = item === null || item === void 0 ? void 0 : item.children[0]) === null || _b === void 0 ? void 0 : _b.url) === null &&
        ((_c = item === null || item === void 0 ? void 0 : item.children[0]) === null || _c === void 0 ? void 0 : _c.name) === null &&
        ((_d = item === null || item === void 0 ? void 0 : item.children[0]) === null || _d === void 0 ? void 0 : _d.children) === null &&
        ((_e = item === null || item === void 0 ? void 0 : item.children[0]) === null || _e === void 0 ? void 0 : _e.secondRowChildren) === null);
}
