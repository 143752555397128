import { styled } from '@glitz/react';
import { omega, kilo, charlie, mediaMinMedium, mediaMinSmall, large, FontWeight, mediaMinTiny, iota, } from 'Shared/Style';
import { media } from '@glitz/core';
export const headingDecorator = styled({
    font: {
        family: ({ siteTheme }) => siteTheme.headings.main.font,
        weight: FontWeight.SemiBold,
    },
});
export const headingAlternativeDecorator = styled({
    font: {
        family: ({ siteTheme }) => siteTheme.headings.alternative.font,
        weight: FontWeight.Bold,
    },
});
export const h1Decorator = styled(Object.assign(Object.assign(Object.assign(Object.assign({ margin: { y: large }, fontSize: iota }, media(mediaMinTiny, { fontSize: charlie })), media(mediaMinSmall, { fontSize: kilo })), media(mediaMinMedium, { fontSize: omega })), { hyphens: 'auto' }));
export const h1MainDecorator = styled(h1Decorator, headingDecorator);
export const h1Styled = styled(styled.H1, h1MainDecorator);
export const h1AlternativeDecorator = styled(h1Decorator, headingAlternativeDecorator);
export default styled(styled.H1, h1AlternativeDecorator);
