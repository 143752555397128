import { __rest } from "tslib";
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSwipeable } from 'react-swipeable';
import { styled } from '@glitz/react';
import { on, translate } from '@avensia/scope';
import { Motion, spring } from 'react-motion';
import Overlay from 'Shared/Overlay';
import * as style from 'Shared/Style';
import ErrorBoundary from 'Shared/ErrorBoundary';
import { margin, huge } from 'Shared/Style';
import { SquareButton, Theme } from 'Shared/Button';
import { Close } from 'Shared/Icon';
import { useSelector } from 'react-redux';
import { RootPortal } from 'Shared/RootPortal';
import { TabTrap } from 'Shared/a11y/TabTrap';
import { testIdProps } from 'test-automation';
const DELTA_THRESHOLD = 20;
export var Position;
(function (Position) {
    Position[Position["Left"] = 0] = "Left";
    Position[Position["Right"] = 1] = "Right";
})(Position || (Position = {}));
var Direction;
(function (Direction) {
    Direction["Left"] = "Left";
    Direction["Right"] = "Right";
    Direction["Up"] = "Up";
    Direction["Down"] = "Down";
})(Direction || (Direction = {}));
const flyoutDecorator = styled(Object.assign({ position: 'fixed', top: 0, height: '100%', width: '23.44rem', maxWidth: `calc(100% - ${style.huge}px)`, backgroundColor: 'white', willChange: 'transform' }, style.depthDecorator()));
const Left = styled(styled.Div, flyoutDecorator, { right: '100%', display: 'flex', flexDirection: 'column' });
const Right = styled(styled.Div, flyoutDecorator, { left: '100%', display: 'flex', flexDirection: 'column' });
export default function Flyout({ isOpen, onClose, position, children }) {
    const [translateX, setTranslateX] = useState(0);
    const mounted = useRef(false);
    const isSwiping = useRef(false);
    const previousDeltaX = useRef(0);
    const elementRef = useRef(null);
    const width = useRef();
    const flyoutDragIsDisabled = useSelector((s) => s.disableFlyoutTouch);
    const updateWidthTranslateX = useCallback(() => {
        if (elementRef.current) {
            width.current = elementRef.current.offsetWidth;
            const newTranslateX = isOpen ? (position === Position.Left ? width.current : -width.current) : 0;
            if (mounted.current) {
                setTranslateX(newTranslateX);
            }
        }
    }, [isOpen, position]);
    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);
    useEffect(() => {
        updateWidthTranslateX();
        const unsubscribeResize = on('resize', updateWidthTranslateX);
        return () => unsubscribeResize();
    }, [isOpen, position, updateWidthTranslateX]);
    function onSwiping({ deltaX, dir }) {
        if (flyoutDragIsDisabled)
            return;
        const newTranslateX = translateX + previousDeltaX.current - deltaX;
        previousDeltaX.current = deltaX;
        isSwiping.current = true;
        if (((position === Position.Left && dir === Direction.Left && newTranslateX < width.current) ||
            (position === Position.Right && dir === Direction.Right && newTranslateX > -width.current)) &&
            newTranslateX !== translateX) {
            setTranslateX(newTranslateX);
        }
    }
    const onSwiped = useCallback(({ deltaX, dir }) => {
        if (flyoutDragIsDisabled)
            return;
        previousDeltaX.current = 0;
        isSwiping.current = false;
        const isClosingDirection = (position === Position.Left && dir === Direction.Left) ||
            (position === Position.Right && dir === Direction.Right);
        if ((isClosingDirection && deltaX > DELTA_THRESHOLD) || (isClosingDirection && deltaX < -DELTA_THRESHOLD)) {
            onClose();
        }
        else {
            setTranslateX(position === Position.Left ? width.current : -width.current);
        }
    }, [flyoutDragIsDisabled, onClose, position]);
    function getStyle() {
        return {
            translateX: isSwiping.current ? translateX : spring(translateX),
        };
    }
    const handlers = useSwipeable({ onSwiping, onSwiped, delta: DELTA_THRESHOLD });
    const Base = position === Position.Left ? Left : Right;
    return (React.createElement(RootPortal, null,
        React.createElement(Overlay, { onClose: onClose, enabled: isOpen },
            React.createElement(TabTrap, { enabled: isOpen },
                React.createElement("div", Object.assign({}, handlers),
                    React.createElement(Motion, { style: getStyle() }, ({ translateX: motionTranslateX }) => {
                        /** temp hack to unmount content on closed flyout */
                        const isClosed = motionTranslateX !== 0;
                        return (React.createElement(Base, { css: __$hoisted_o0, ref: elementRef, "aria-hidden": !isOpen, style: motionTranslateX !== 0 ? { transform: `translateX(${motionTranslateX}px)` } : null },
                            React.createElement(ErrorBoundary, { reloadCurrentPage: true }, isClosed && children)));
                    }))))));
}
export const Header = styled((_a) => {
    var { children, onClose, top } = _a, restProps = __rest(_a, ["children", "onClose", "top"]);
    return (React.createElement(styled.Header, Object.assign({}, restProps),
        children,
        React.createElement(CloseButton, Object.assign({}, testIdProps.CloseMiniCartButton, { "aria-label": translate('/Shared/Close'), as: Theme.None, onClick: onClose, css: { top: top } }),
            React.createElement(Close, null))));
}, {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: 55,
    flexShrink: 0,
    padding: { x: huge },
});
const CloseButton = styled(SquareButton, {
    position: 'absolute',
    right: 0,
    marginLeft: 'auto',
});
export const Body = styled.div({ padding: { y: margin.large, x: huge } });
const __$hoisted_o0 = { cursor: 'initial' };
