import React from 'react';
import { styled } from '@glitz/react';
import { linkColor, createTransitionDecorator, createTruncateDecorator, grey60 } from 'Shared/Style';
import Spinner from 'Shared/Icon/Spinner';
const SPINNER_HEIGHT = 50;
const SPINNER_WIDTH = 50;
export const SpinnerLoader = styled(() => {
    return (React.createElement(styled.Div, null,
        React.createElement(SpinnerIcon, null)));
});
export const SpinnerGrey = styled(() => {
    return (React.createElement(styled.Div, null,
        React.createElement(SpinnerGreyIcon, null)));
});
const SpinnerIcon = styled(Spinner, Object.assign(Object.assign(Object.assign({ color: linkColor, fill: linkColor, height: SPINNER_HEIGHT, width: SPINNER_WIDTH }, createTruncateDecorator()), { opacity: 1, transform: 'scale(1)' }), createTransitionDecorator({ property: ['opacity', 'transform'] })));
const SpinnerGreyIcon = styled(Spinner, Object.assign(Object.assign(Object.assign({ color: grey60, fill: grey60, height: 24, width: 24 }, createTruncateDecorator()), { opacity: 1, transform: 'scale(1)' }), createTransitionDecorator({ property: ['opacity', 'transform'] })));
