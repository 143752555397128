import React from 'react';
import { styled } from '@glitz/react';
import { average, calculateLineHeightInPixels, pixelsToUnit, createTransitionDecorator, delta } from './Style';
import { PrimaryThemeBlock } from './Theme';
export default styled(function RoundIndicator({ cartCount }) {
    const indicatorStyle = {
        transform: 'scale(1)',
    };
    const qtyToDisplay = showMaxCountIndicator(cartCount);
    return React.createElement(Indicator, { css: indicatorStyle }, qtyToDisplay);
});
function showMaxCountIndicator(count) {
    if (count <= 99) {
        return count;
    }
    else if (count > 99 && count <= 999) {
        return '99+';
    }
    else {
        return '999+';
    }
}
const INDICATOR_SIZE = calculateLineHeightInPixels(delta);
const Indicator = styled(PrimaryThemeBlock, Object.assign({ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', height: INDICATOR_SIZE, minWidth: INDICATOR_SIZE, top: `calc(50% - ${pixelsToUnit(INDICATOR_SIZE)})`, right: `calc(50% - ${pixelsToUnit(INDICATOR_SIZE)})`, fontSize: average, borderRadius: '100vw', transform: `scale(0)` }, createTransitionDecorator({ property: 'transform' })));
