import { __rest } from "tslib";
import React from 'react';
import { styled } from '@glitz/react';
import { Basic, Appearance as PageAppearance } from 'Shared/PageLayout';
import { createGeneralDecorator } from 'Shared/Style';
import { PrimaryThemeBlock } from 'Shared/Theme';
import { SiteBannerContext } from './SiteBannerContext';
export default createSiteBanner();
export function createSiteBanner(ThemeBlock = PrimaryThemeBlock, general) {
    const Base = styled(ThemeBlock, {
        overflow: 'hidden',
    });
    const Banner = styled(Basic, createGeneralDecorator(general), { textAlign: 'center' });
    return styled((_a) => {
        var { elementRef, children } = _a, restProps = __rest(_a, ["elementRef", "children"]);
        return (React.createElement(SiteBannerContext.Provider, { value: { isRenderSiteBanner: true } },
            React.createElement(Base, Object.assign({}, restProps),
                React.createElement(Banner, { appearance: [PageAppearance.Narrow, PageAppearance.Gap], elementRef: elementRef }, children))));
    });
}
export function createSiteFooterBanner(ThemeBlock = PrimaryThemeBlock, general) {
    const Base = styled(ThemeBlock, {
        height: 'auto',
    });
    const Banner = createGeneralDecorator(general)(Basic, {
        padding: { x: 0 },
    });
    return styled((_a) => {
        var { elementRef, children } = _a, restProps = __rest(_a, ["elementRef", "children"]);
        return (React.createElement(SiteBannerContext.Provider, { value: { isRenderSiteBanner: false } },
            React.createElement(Base, Object.assign({}, restProps),
                React.createElement(Banner, { elementRef: elementRef }, children))));
    });
}
