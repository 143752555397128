import React, { createContext, useRef, useCallback, useMemo } from 'react';
export const RootContext = createContext(null);
export const RootContextProvider = ({ children }) => {
    const rootElementRef = useRef();
    const setRootElementRef = useCallback((element) => {
        rootElementRef.current = element;
    }, []);
    const memoValue = useMemo(() => ({ setRootElementRef, rootElementRef }), [setRootElementRef, rootElementRef]);
    return React.createElement(RootContext.Provider, { value: memoValue }, children);
};
