import { defaultTextColor } from './typography';
export const white = 'white';
export const whiteHex = '#FFFFFF';
export const offBlack = '#222222';
export const dirtyWhite = '#FDFDFD';
export const secondaryLinkColor = '#e6f8fd';
export const linkColor = '#00B4E6';
export const baseBlue = '#001E46';
export const primary = linkColor;
export const primaryDark = '#0092BC';
export const primaryText = white;
export const secondary = '#CCF0FA';
export const secondaryLight = '#4f4f4f';
export const secondaryDark = '#633B6F';
export const secondaryText = white;
export const secondaryIndicator = linkColor;
export const lightBlack = '#E6E6E6';
export const sigvaris = '#7474C1';
export const sigvarisDark = '#685BC7';
export const sigvarisText = white;
export const dynaven = '#00BFB3';
export const dynavenDark = '#00677F';
export const dynavenText = white;
export const placeholderGrey = '#ACACAC';
export const outlineGrey = '#D1D1D1';
export const hoverGrey = '#E4E4E4';
export const darkWhite = '#F3F3F3';
//////////////////
export const monochromeLight = '#f9f9f9';
export const monochrome = '#f0f0f0';
export const monochromeDark = '#e7e7e7';
export const monochromeText = defaultTextColor;
//////////////////
//////////////////
// Brand Colors //
export const secondaryBlue100 = linkColor;
export const sigvarisBrandUnisex100 = '#685BC7';
export const dynavenWomen1100 = '#830065';
//////////////////
//////////////////
// Black Multi Colors
export const black = 'black';
export const black60 = '#666666';
export const black05 = '#F5F5F5';
export const black04 = '#505050';
//////////////////
//////////////////
// Grey Multi Colors
export const grey = '#2F2F2F';
export const grey80 = '#595959';
export const grey60 = '#828282';
export const grey50 = '#737373';
export const grey40 = '#ACACAC';
export const grey30 = '#B9B9B9';
export const grey20 = '#D5D5D5';
export const grey05 = '#7D7D7D';
export const grey04 = '#C5C5C5';
export const grey03 = '#d3d3d3';
//////////////////
//////////////////
// State Colors
export const stateGreen = '#7EE275';
export const stateYellow = '#FFD84E';
export const stateRed = '#EE4F2D';
//////////////////
export const positiveLight = '#57c177';
export const positive = '#42B264';
export const positiveDark = '#389955';
export const positiveText = white;
export const negativeLight = '#ff7e70';
export const negative = '#ff604e';
export const negativeDark = '#ff3e29';
export const negativeText = white;
export const red = '#af0917';
export const yellow = '#ffd410';
export const green = '#569910';
export const darkRed = '#d90005';
export const socialShare = '#888888';
// Sigvaris color schemes
export const hoverBlue = '#E6F8FD';
export const tooltipColor = '#494949';
export const secondaryHoverBlue = '#E9F7FC';
export const contactBorderGrey = '#CCCCCC';
export const lightGrey = '#F5F5F5';
export const lightBlue = linkColor;
export const formGrey = ' #E5E5E5';
export const borderGrey = '#D0D0D0';
export const lightBorderGrey = '#CFCECE';
export const outlineLightGrey = '#E2E2E2';
export const lineGrey = '#DEDEDE';
export const darkTextTheme = '#0095BE';
export const lightTextTheme = '#CCF0FA';
export const tableRowBorder = '#E1E1E1';
export const disabledGrey = borderGrey;
export const disabledBorderGrey = '#A1A1A1';
export const subtleGrey = '#F8F8F8';
export const contentHeaderLinkGrey = '#CCD2DA';
export const scrollInnerGrey = '#A5A5A5';
export const scrollOuterGrey = '#F2F2F2';
export const titleBlockBlack = grey;
export const closeColorDarkBlue = '#004167';
export const modalCloseColor = '#004167';
export const darkBlue = baseBlue;
export const findRetailerArrow = '#1C1B1F';
//Stocks
export const availableStocks = '#30B66D';
export const limitedStocks = '#E9C16B';
export const unavailableStocks = '#DA3535';
