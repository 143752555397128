import { __rest } from "tslib";
import React, { useMemo } from 'react';
import { styled, ThemeProvider, forwardStyle } from '@glitz/react';
import { white, defaultTextColor, primary, primaryText, linkColor, lightBlack, black, darkWhite, secondaryText, secondary, lightGrey, black04, } from './Style';
import { useIsCompact, useIsTablet, useIsMobile } from './use-viewport';
import { useCurrentTheme } from 'SiteLayout/Theming';
export var Theme;
(function (Theme) {
    Theme[Theme["Primary"] = 0] = "Primary";
    Theme[Theme["Secondary"] = 1] = "Secondary";
    Theme[Theme["Sigvaris"] = 2] = "Sigvaris";
    Theme[Theme["Dynaven"] = 3] = "Dynaven";
    Theme[Theme["White"] = 4] = "White";
    Theme[Theme["Light"] = 5] = "Light";
    Theme[Theme["Mid"] = 6] = "Mid";
    Theme[Theme["Dark"] = 7] = "Dark";
})(Theme || (Theme = {}));
function themeFactory(colorTheme) {
    return function Theme({ children }) {
        const isMobile = useIsMobile();
        const isCompact = useIsCompact();
        const isTablet = useIsTablet();
        const siteTheme = useCurrentTheme();
        const theme = useMemo(() => (Object.assign(Object.assign({}, colorTheme), { siteTheme, isCompact, isMobile, isTablet })), [siteTheme, isCompact, isMobile, isTablet]);
        return React.createElement(ThemeProvider, { theme: theme }, children);
    };
}
const primaryTheme = {
    type: Theme.Primary,
    mainBackgroundColor: primary,
    mainTextColor: primaryText,
    linkColor,
};
const secondaryTheme = {
    type: Theme.Secondary,
    mainBackgroundColor: secondary,
    mainTextColor: secondaryText,
    linkColor,
};
const whiteTheme = {
    type: Theme.White,
    mainBackgroundColor: white,
    mainTextColor: defaultTextColor,
    linkColor,
};
const lightTheme = {
    type: Theme.Light,
    mainBackgroundColor: darkWhite,
    mainTextColor: black,
    linkColor,
};
const midTheme = {
    type: Theme.Mid,
    mainBackgroundColor: lightBlack,
    mainTextColor: black,
    linkColor,
};
const darkTheme = {
    type: Theme.Dark,
    mainBackgroundColor: black,
    mainTextColor: white,
    linkColor,
};
const lightGreyTheme = {
    type: Theme.Light,
    mainBackgroundColor: lightGrey,
    mainTextColor: black04,
    linkColor,
};
export const PrimaryTheme = themeFactory(primaryTheme);
export const SecondaryTheme = themeFactory(secondaryTheme);
export const WhiteTheme = themeFactory(whiteTheme);
export const LightTheme = themeFactory(lightTheme);
export const MidTheme = themeFactory(midTheme);
export const DarkTheme = themeFactory(darkTheme);
export const LightGreyTheme = themeFactory(lightGreyTheme);
const blockDecorator = styled({
    color: theme => theme.mainTextColor,
    backgroundColor: theme => theme.mainBackgroundColor,
});
function blockFactory(Provider) {
    return styled(forwardStyle((_a) => {
        var { compose, elementRef } = _a, restProps = __rest(_a, ["compose", "elementRef"]);
        return (React.createElement(Provider, null,
            React.createElement(styled.Div, Object.assign({}, restProps, { css: compose(), ref: elementRef }))));
    }), blockDecorator);
}
export const PrimaryThemeBlock = blockFactory(PrimaryTheme);
export const SecondaryThemeBlock = blockFactory(SecondaryTheme);
export const WhiteThemeBlock = blockFactory(WhiteTheme);
export const LightThemeBlock = blockFactory(LightTheme);
export const MidThemeBlock = blockFactory(MidTheme);
export const DarkThemeBlock = blockFactory(DarkTheme);
export const LightGreyBlock = blockFactory(LightGreyTheme);
