import { postJson } from '@avensia/scope';
let timeoutId = 0;
let cartActionsQueue = [];
let trackingInformationProductsInQueue = [];
export function addToCartThrottler(payload, callbacks) {
    cartActionsQueue.push(payload);
    payload.products.forEach(p => trackingInformationProductsInQueue.push(p));
    if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = 0;
    }
    timeoutId = setTimeout(() => excecuteCartThrottlerQueue(callbacks), 2000);
}
function excecuteCartThrottlerQueue({ onAdded, onRemoved }) {
    if (!cartActionsQueue.length) {
        return;
    }
    timeoutId = 0;
    const { addedCodeQuantities, removedCodeQuantities } = mergeCartAddActions();
    trackingInformationProductsInQueue = [];
    cartActionsQueue = [];
    const allCodesDistinct = [...addedCodeQuantities, ...removedCodeQuantities]
        .filter((v, i, a) => a.indexOf(v) === i)
        .map(s => s.code);
    if (allCodesDistinct === null || allCodesDistinct === void 0 ? void 0 : allCodesDistinct.length) {
        postJson('/Tracking/GetTrackingProducts', { variantCodes: allCodesDistinct }).then((products) => {
            const addedProducts = addedCodeQuantities === null || addedCodeQuantities === void 0 ? void 0 : addedCodeQuantities.map(s => mapCodeQuantityToTrackingProduct(s, products));
            const removedProducts = removedCodeQuantities === null || removedCodeQuantities === void 0 ? void 0 : removedCodeQuantities.map(s => mapCodeQuantityToTrackingProduct(s, products));
            if (addedProducts.length) {
                onAdded === null || onAdded === void 0 ? void 0 : onAdded({ products: addedProducts });
            }
            if (removedProducts.length) {
                onRemoved === null || onRemoved === void 0 ? void 0 : onRemoved({ products: removedProducts });
            }
        });
    }
}
function mapCodeQuantityToTrackingProduct(codeQuantity, products) {
    const code = cleanVariationCode(codeQuantity.code);
    const trackingProduct = products.find(c => c.id === code);
    if (trackingProduct !== undefined) {
        trackingProduct.quantity = codeQuantity.quantity;
    }
    return trackingProduct;
}
function mergeCartAddActions() {
    const intermediate = {};
    // If the user has done very quick modifications to the cart from different places
    // we will track as if it did all the modifications in the last place
    let lastActionListName = '';
    cartActionsQueue.forEach(payload => {
        payload.products.forEach(product => {
            if (!(product.code in intermediate)) {
                intermediate[product.code] = payload.isAdd ? product.quantity : -product.quantity;
            }
            else {
                intermediate[product.code] = payload.isAdd
                    ? intermediate[product.code] + product.quantity
                    : intermediate[product.code] - product.quantity;
            }
            lastActionListName = payload.listName;
        });
    });
    const addedCodeQuantities = [];
    const removedCodeQuantities = [];
    for (const code in intermediate) {
        const trackingProduct = trackingInformationProductsInQueue.find(s => s.code === code);
        if (intermediate[code] > 0) {
            if (trackingProduct !== undefined) {
                trackingProduct.quantity = intermediate[code];
                addedCodeQuantities.push(trackingProduct);
            }
        }
        else if (intermediate[code] < 0) {
            if (trackingProduct !== undefined) {
                trackingProduct.quantity = Math.abs(intermediate[code]);
                removedCodeQuantities.push(trackingProduct);
            }
        }
    }
    return { addedCodeQuantities, removedCodeQuantities, listName: lastActionListName };
}
export const cleanVariationCode = (variationCode) => variationCode.includes('¤') ? variationCode.split('¤')[0] : variationCode;
