import React, { forwardRef } from 'react';
import { styled } from '@glitz/react';
import { useCurrentTheme } from 'SiteLayout/Theming';
export var Theme;
(function (Theme) {
    Theme[Theme["None"] = 0] = "None";
    Theme[Theme["Primary"] = 1] = "Primary";
    Theme[Theme["PrimaryLight"] = 2] = "PrimaryLight";
    Theme[Theme["PrimaryDark"] = 3] = "PrimaryDark";
    Theme[Theme["Secondary"] = 4] = "Secondary";
    Theme[Theme["Negative"] = 5] = "Negative";
    Theme[Theme["Positive"] = 6] = "Positive";
    Theme[Theme["Disabled"] = 7] = "Disabled";
    Theme[Theme["White"] = 8] = "White";
    Theme[Theme["Black"] = 9] = "Black";
    Theme[Theme["Grey"] = 10] = "Grey";
    Theme[Theme["LightGrey"] = 11] = "LightGrey";
    Theme[Theme["Transparent"] = 12] = "Transparent";
    Theme[Theme["OutlinedWhite"] = 13] = "OutlinedWhite";
    Theme[Theme["OutlinedBlack"] = 14] = "OutlinedBlack";
    Theme[Theme["OutlinedGrey"] = 15] = "OutlinedGrey";
    Theme[Theme["OutlinedTheme"] = 16] = "OutlinedTheme";
    Theme[Theme["OutlinedThemeLight"] = 17] = "OutlinedThemeLight";
    Theme[Theme["OutlinedThemeDark"] = 18] = "OutlinedThemeDark";
})(Theme || (Theme = {}));
export var Casing;
(function (Casing) {
    Casing[Casing["None"] = 0] = "None";
    Casing[Casing["Lowercase"] = 1] = "Lowercase";
    Casing[Casing["Uppercase"] = 2] = "Uppercase";
})(Casing || (Casing = {}));
const ThemeButtonWithRef = forwardRef((props, ref) => {
    const theme = useCurrentTheme();
    const Component = theme.buttons.ThemedButtonComponent;
    return React.createElement(Component, Object.assign({}, props, { ref: ref }));
});
export const ThemeButton = styled(ThemeButtonWithRef);
export const caseDecorators = {
    [Casing.None]: {
        textTransform: 'inherit',
    },
    [Casing.Lowercase]: {
        textTransform: 'initial',
    },
    [Casing.Uppercase]: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },
};
