import { __rest } from "tslib";
import { styled } from '@glitz/react';
import React from 'react';
import DefaultLogo from './logotype.svg';
import connect from 'Shared/connect';
import * as style from '../Style';
const Logotype = styled(connect(state => ({
    siteLogo: state.appShellData.siteLogo,
    siteLogoCompact: state.appShellData.siteLogoCompact,
}))((props) => {
    const { compact, className, siteLogo, siteLogoCompact } = props, restProps = __rest(props, ["compact", "className", "siteLogo", "siteLogoCompact"]);
    delete restProps.dispatch;
    const responsiveLogo = compact && siteLogoCompact ? siteLogoCompact : siteLogo;
    if (responsiveLogo === null || responsiveLogo === void 0 ? void 0 : responsiveLogo.url) {
        return (React.createElement(styled.Img, Object.assign({ className: className }, restProps, { src: responsiveLogo.url, alt: responsiveLogo.altText }, { fetchpriority: 'high' })));
    }
    return React.createElement(DefaultLogo, { style: { width: 50, height: 50, display: 'block', marginLeft: '1rem' } });
}));
export default styled((_a) => {
    var restProps = __rest(_a, []);
    return (React.createElement(Logotype, Object.assign({ css: {
            minHeight: restProps.minHeight ? style.pixelsToUnit(restProps.minHeight) : 50,
            maxHeight: restProps.maxHeight ? style.pixelsToUnit(restProps.maxHeight) : 50,
            maxWidth: restProps.maxWidth ? style.pixelsToUnit(restProps.maxWidth) : 50,
        } }, restProps)));
});
