import React from 'react';
import { styled } from '@glitz/react';
import Overlay from 'Shared/Overlay';
import { PlainButton } from 'Shared/Button';
import { Close } from 'Shared/Icon';
import * as style from 'Shared/Style';
import ErrorBoundary from 'Shared/ErrorBoundary';
import { RootPortal } from './RootPortal';
import { TabTrap } from './a11y/TabTrap';
const Base = styled.div({
    position: 'fixed',
    width: '95%',
    maxWidth: '500px',
    left: '50%',
    top: '8%',
    backgroundColor: 'white',
    transform: 'translateX(-50%)',
    padding: {
        x: style.medium,
        y: style.medium,
    },
});
const InnerContainer = styled.div({
    position: 'relative',
});
const CloseButton = styled(PlainButton, {
    position: 'absolute',
    right: 0,
    top: 0,
});
export default function Modal(props) {
    return (React.createElement(RootPortal, null,
        React.createElement(Overlay, { enabled: props.isOpen, onClose: props.onClose }, props.isOpen && (React.createElement(Base, null,
            React.createElement(TabTrap, { enabled: props.isOpen },
                React.createElement(InnerContainer, null,
                    React.createElement(ErrorBoundary, { reloadCurrentPage: true }, props.children),
                    !props.hideCloseIcon && (React.createElement(CloseButton, { onClick: props.onClose },
                        React.createElement(Close, null))))))))));
}
