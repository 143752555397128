let store = undefined;
let gaKey = undefined;
const reportedVariations = {};
let currentPage = undefined;
let appShellData = undefined;
export function setStoreForABTesting(s, analyticsKey) {
    store = s;
    store.subscribe(() => {
        const state = store.getState();
        let report = false;
        if (state.currentPage !== currentPage) {
            currentPage = state.currentPage;
            report = true;
        }
        if (state.appShellData !== appShellData) {
            appShellData = state.appShellData;
            report = true;
        }
        gaKey = analyticsKey;
        if (report) {
            setGoogleOptimizeExperimentVariations();
        }
    });
}
function setGoogleOptimizeExperimentVariations() {
    if (typeof ga !== 'function') {
        return;
    }
    if (isABTestedContent(appShellData)) {
        reportExperimentsVariation(appShellData);
    }
    if (isABTestedAppShellData(appShellData)) {
        for (const experimentName of Object.keys(appShellData.runningExperiments)) {
            reportSelectedVariation(appShellData.runningExperiments[experimentName]);
        }
    }
    if (isABTestedContent(currentPage)) {
        reportExperimentsVariation(currentPage);
    }
}
export function reportSelectedVariation(experiment) {
    if (reportedVariations[experiment.experimentId]) {
        return;
    }
    if (typeof ga === 'function' && experiment.shouldIgnoreReporting !== true) {
        ga('create', gaKey, 'auto');
        ga('set', 'exp', experiment.experimentId + '.' + experiment.selectedVariationId);
        ga('send', 'pageview');
        reportedVariations[experiment.experimentId] = experiment.selectedVariationId;
    }
}
export function setUserID(userID) {
    if (typeof ga === 'function') {
        ga('set', 'userId', userID);
    }
}
function reportExperimentsVariation(obj) {
    if (typeof ga === 'function') {
        for (const experiment of obj.__goExperiments) {
            reportSelectedVariation(experiment);
        }
    }
}
function isABTestedContent(obj) {
    return !!obj.__goExperiments;
}
function isABTestedAppShellData(obj) {
    return !!obj.runningExperiments;
}
