import { styled } from '@glitz/react';
import { small, large, huge, medium } from './values';
import { delta, lineHeight, calculateLineHeightInPixels, zeta } from './typography';
// Used to make sure some elements are styled the same, e.g. buttons and fields
export var General;
(function (General) {
    General[General["Default"] = 0] = "Default";
    General[General["Small"] = 1] = "Small";
    General[General["Large"] = 2] = "Large";
    General[General["Medium"] = 3] = "Medium";
})(General || (General = {}));
function createGeneral(fontSize, x, y) {
    return {
        height: calculateLineHeightInPixels(fontSize) + y * 2,
        padding: { x, y },
        fontSize,
    };
}
const generals = {
    [General.Small]: createGeneral(zeta, medium, small),
    [General.Default]: createGeneral(delta, large, 10),
    [General.Large]: createGeneral(delta, huge, 12),
    [General.Medium]: createGeneral(delta, medium, 4),
};
const generalResetStyled = styled({ lineHeight });
export function general(type = General.Default) {
    return generals[type];
}
export function createGeneralDecorator(type = General.Default, singleLine = true) {
    const decorator = styled(generalResetStyled, general(type));
    if (singleLine) {
        return decorator;
    }
    else {
        // Remove `height` to enable multiple rows
        return styled(decorator, { height: undefined });
    }
}
