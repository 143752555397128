import { __awaiter } from "tslib";
import { addUserLog, currentUrl, URLX, resolveData, postJson, replaceState, clearContentCaches, setOnAuthRequired, } from '@avensia/scope';
import { getCurrentUser } from 'Shared/dynamic-data';
import { batchActions } from 'redux-batched-actions';
import { QUERY_LOGIN_PARAM, QUERY_LOGIN_VALUE_REQUIRED, QUERY_LOGIN_PARAM_RETURN_URL } from './utils';
import { addToDataLayer } from 'TrackingInformation';
import { createLogoutGa4 } from 'TrackingInformation/ga4';
export const CLOSE_ACCOUNT_PANEL = 'CLOSE_ACCOUNT_PANEL';
export const OPEN_ACCOUNT_PANEL = 'OPEN_ACCOUNT_PANEL';
export const SET_ACCOUNT_MESSAGE = 'SET_ACCOUNT_MESSAGE';
export const SET_ACCOUNT_PANEL_MODE = 'SET_ACTIVE_ACCOUNT_MODE';
export const LOGOUT_USER = 'LOGOUT_USER';
export var AccountPanelMode;
(function (AccountPanelMode) {
    AccountPanelMode[AccountPanelMode["Login"] = 0] = "Login";
    AccountPanelMode[AccountPanelMode["Registration"] = 1] = "Registration";
    AccountPanelMode[AccountPanelMode["ForgotPassword"] = 2] = "ForgotPassword";
})(AccountPanelMode || (AccountPanelMode = {}));
export function openAccountPanel(mode = AccountPanelMode.Login, redirectToAfterLogin) {
    addUserLog('Opening login/registration overlay');
    const action = {
        type: OPEN_ACCOUNT_PANEL,
        redirectToAfterLogin,
    };
    return (dispatch) => {
        dispatch(batchActions([setAccountPanelMode(mode), action]));
    };
}
export function closeAccountPanel(snackMessage) {
    addUserLog('Closing login/registration overlay');
    return (dispatch) => {
        dispatch({
            type: CLOSE_ACCOUNT_PANEL,
        });
        if (snackMessage) {
            setAccountMessage(snackMessage, false)(dispatch);
        }
    };
}
export function setAccountMessage(snackMessage, persist = false) {
    return (dispatch) => {
        dispatch({
            type: SET_ACCOUNT_MESSAGE,
            message: snackMessage,
        });
        if (!persist && !!snackMessage) {
            setTimeout(() => {
                dispatch(setAccountMessage(null));
            }, 3000);
        }
    };
}
export function setAccountPanelMode(mode) {
    addUserLog('Changing login/registration overlay mode');
    return {
        type: SET_ACCOUNT_PANEL_MODE,
        mode,
    };
}
export function openLoginBoxOn401(store) {
    return __awaiter(this, void 0, void 0, function* () {
        // What happens here is that we register a callback that will be called on all 401
        // responses. We then get a chance to let the user login and return a resolved
        // promise. Which will signal to scope that we should retry the request. This
        // creates a seamless experience for the user where she doesn't have to re-do whatever
        // action she did that caused the 401.
        setOnAuthRequired(() => {
            return new Promise((resolve, reject) => {
                store.dispatch(openAccountPanel(AccountPanelMode.Login));
                const unsubscribe = store.subscribe(() => __awaiter(this, void 0, void 0, function* () {
                    // We listen to store updates until the login box is closed
                    // because by that time the user has either logged in or given up
                    if (!store.getState().userAccount.accountPanelIsOpen) {
                        const user = yield resolveData(getCurrentUser());
                        if (user.isLoggedIn) {
                            resolve();
                        }
                        else {
                            reject();
                        }
                        unsubscribe();
                    }
                }));
            });
        });
    });
}
export function openLoginBoxIfUrlSaysSo(store) {
    return __awaiter(this, void 0, void 0, function* () {
        const url = currentUrl();
        const isLoginRequired = url.searchParams.get(QUERY_LOGIN_PARAM) === QUERY_LOGIN_VALUE_REQUIRED;
        const returnUrl = url.searchParams.has(QUERY_LOGIN_PARAM_RETURN_URL)
            ? new URLX(url.searchParams.get(QUERY_LOGIN_PARAM_RETURN_URL))
            : null;
        const currentUser = yield resolveData(getCurrentUser());
        if (isLoginRequired && !currentUser.isLoggedIn) {
            store.dispatch(openAccountPanel(AccountPanelMode.Login, returnUrl));
        }
    });
}
export function logout(redirect, isChangeCountry = false, market = null) {
    // TODO add some error handling in the future for failed logouts.
    // Maybe make a thunk of this in the future to be able to have different
    // states. "LOGOUT_USER_PENDING" "LOGOUT_USER_SUCCESS" etc
    postJson('/logoutpage/logoutuser')
        .then(() => {
        // clear the content caches first
        return clearContentCaches();
    })
        .then(() => {
        addUserLog('Logout successful');
        addToDataLayer(createLogoutGa4(market));
        // if not in the same country, do not use the replace state
        // as it causes to get the previous market APP_SHELL_DATA
        // else if the changing market is in the same country
        if (isChangeCountry) {
            window.location.href = redirect;
            return Promise.resolve();
        }
        else {
            return replaceState(redirect, { includeAppShellData: true });
        }
    })
        .catch(e => {
        console.error(e);
        return Promise.reject(e);
    });
    return {
        type: LOGOUT_USER,
    };
}
