import React, { useState, useRef, useContext, useEffect } from 'react';
import { styled } from '@glitz/react';
import { useSelector } from 'Shared/State';
import { isCategoryActive, convertUrlToId } from 'SiteLayout/MainMenu/item-helpers';
import { delta, large, medium, ZIndex } from 'Shared/Style';
import { useMainMenu } from 'SiteLayout/MainMenu/use-mainmenu';
import MainMenu from '.';
import { useClickOutside } from 'Shared/use-click-outside';
import { HeaderContext } from 'SiteLayout/Header/HeaderContext';
import { createPortal } from 'react-dom';
import { HeaderItem, selectedHeaderItemDecorator } from 'SiteLayout/Header/HeaderItem';
import { testIdProps } from 'test-automation';
import useHover from 'Shared/use-hover';
import Image from 'Shared/Image/Ratio';
import { formatIcon, requireIfExists } from 'Shared/Icon/fontawesomefactory';
export default function FullHeader() {
    const headerContext = useContext(HeaderContext);
    const mainMenu = useSelector(state => state.mainMenu);
    const { openMainMenu, closeMainMenu, isMainMenuOpen, mainMenuItems } = useMainMenu();
    const baseRef = useRef();
    const [hoverRef, isHoveringRef] = useHover();
    const [isHovering, setIsHovering] = useState(false);
    useClickOutside(baseRef, () => {
        if (isMainMenuOpen) {
            closeMainMenu();
        }
    });
    // Disable active category link styling when hovering
    function hoverStart() {
        setIsHovering(true);
    }
    // No hover? Enable the styles..
    function hoverEnd() {
        setIsHovering(false);
    }
    useEffect(() => {
        const timer = setTimeout(() => {
            const shouldClose = !isHoveringRef && !isHovering && isMainMenuOpen;
            if (shouldClose) {
                closeMainMenu();
            }
        }, 50);
        return () => {
            clearTimeout(timer);
        };
    }, [closeMainMenu, isHovering, isHoveringRef, isMainMenuOpen]);
    return (React.createElement(Base, { "aria-label": "main menu", ref: baseRef },
        mainMenuItems.map((item, key) => {
            const filePath = requireIfExists(formatIcon(item.fontAwesomeIcon));
            return (React.createElement(Flex, { key: key },
                !!filePath && React.createElement(StyledIcon, { src: { url: filePath }, lazy: true, alt: filePath }),
                React.createElement(Item, Object.assign({}, testIdProps.MainMenuTabs, { to: item.url, onMouseEnter: (e) => {
                        var _a, _b;
                        hoverStart();
                        if (((_a = item.children) === null || _a === void 0 ? void 0 : _a.length) > 0 || ((_b = item.secondRowChildren) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                            openMainMenu(item, e);
                        }
                        else {
                            closeMainMenu();
                            hoverEnd();
                        }
                    }, onMouseLeave: hoverEnd, css: isCategoryActive(item, mainMenu) && !isHovering && selectedHeaderItemDecorator, id: convertUrlToId(item.url), "aria-expanded": isCategoryActive(item, mainMenu), "aria-label": `show ${item.name}`, role: 'button' }), item.name)));
        }),
        headerContext.mainMenuMountElement.current
            ? createPortal(React.createElement(DropdownMenu, { tabIndex: 0, ref: hoverRef, closeMenu: false }), headerContext.mainMenuMountElement.current)
            : null));
}
const Base = styled.nav({
    display: 'flex',
    alignItems: 'center',
    margin: { x: large },
    zIndex: ZIndex.Header,
});
const Item = styled(HeaderItem, {
    fontSize: delta,
});
const DropdownMenu = styled(MainMenu, {
    position: 'absolute',
    top: '100%',
    zIndex: ZIndex.Overlay,
    maxWidth: '100%',
});
const Flex = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: medium,
});
const StyledIcon = styled(Image, {
    width: medium,
});
