import React, { useCallback } from 'react';
import { LinkStyledButton } from 'Shared/Button';
import { useSelector } from 'Shared/State';
import { translate } from '@avensia/scope';
import { openWishList } from 'SiteLayout/WishList/action-creators';
import { useDispatch } from 'react-redux';
import { Heart } from 'Shared/Icon';
export default function WishList() {
    const isWishlistOpen = useSelector(state => state.wishList.isOpen);
    const dispatch = useDispatch();
    const onToggle = useCallback(() => {
        dispatch(openWishList());
    }, [dispatch]);
    return (React.createElement(LinkStyledButton, { "aria-expanded": isWishlistOpen, "aria-label": `${translate('/Shared/Favorites')}`, title: 'Favorites', onClick: onToggle },
        React.createElement(Heart, null)));
}
