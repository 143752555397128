import { __awaiter } from "tslib";
import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@glitz/react';
import { URLX, loadPage, pushState, translate, currentUrl, equalsUrl, replaceState, ESC_KEY } from '@avensia/scope';
import connect from 'Shared/connect';
import Suggestions from './Suggestions';
import * as searchHistory from '../search-history';
import { loadQuickSearch, hideQuickSearch, showQuickSearch, searchQuery, SEARCH_QUERY_NAME } from '../action-creators';
import { searchPageUrl } from 'Shared/known-urls';
import { LinkButton, resetButtonDecorator } from 'Shared/Button';
import currentPageIsSearch from 'Search/current-page-is-search';
import { useSelector } from 'Shared/State';
import { BareInputWithoutFocusVisuals } from 'Shared/Fields/Text';
import { Close, Search } from 'Shared/Icon';
import { delta, lightGrey, thin, medium, grey60, average, huge } from 'Shared/Style';
import { useClickOutside } from 'Shared/use-click-outside';
import { useIsCompact } from 'Shared/use-viewport';
import { createTabbingFocusElement } from 'Shared/a11y/TabFocus';
import SearchOverlay from './Overlay';
import { selector } from '@glitz/core';
import { testIdProps } from 'test-automation';
import { loadNoSearchResults, unClickLinkItem } from 'Search/nohits-action-creators';
const PREVIEW_DEBOUNCE_TIME = 200;
const FULL_SEARCH_DEBOUNCE = 600;
const MIN_CHAR_SEARCH = 2;
export function searchUrl(query, isPreview) {
    const url = new URLX(searchPageUrl());
    url.searchParams.set(SEARCH_QUERY_NAME, query);
    if (isPreview) {
        url.hiddenParams.set('track', String(false));
    }
    return searchQuery(url);
}
const QuickSearch = (props) => {
    var _a;
    const isCompact = useIsCompact();
    const previewLoadRef = useRef();
    const previewTimeoutRef = useRef();
    const searchTimeoutRef = useRef();
    const searchText = props.quickSearch.searchText;
    const isEmptySearchText = searchText === '';
    const quickSearchHideTimeoutRef = useRef();
    const elementRef = useRef();
    const inputRef = useRef();
    const [searchKeyword, setSearchKeyword] = useState('');
    const mostCommonSearchLinks = useSelector(state => state.appShellData.siteSettings.mostCommonSearchLinks);
    const isNoSearchResultClickLink = useSelector(state => state.noSearchResult.isClickLink);
    useEffect(() => {
        if (isNoSearchResultClickLink) {
            setSearchKeyword(searchText);
        }
    }, [isNoSearchResultClickLink, searchText]);
    function applySuggestion(phrase) {
        search(phrase);
        setSearchKeyword(phrase);
        hideQuickSearchNow();
        clearTimeouts();
    }
    function clearTimeouts() {
        clearTimeout(previewTimeoutRef.current);
        clearTimeout(searchTimeoutRef.current);
    }
    function clearHideQuicksearchTimeout() {
        clearTimeout(quickSearchHideTimeoutRef.current);
    }
    function hideQuickSearchNow() {
        clearHideQuicksearchTimeout();
        props.hideQuickSearch();
    }
    function debounceSearch(query) {
        clearTimeouts();
        previewTimeoutRef.current = setTimeout(() => {
            const load = (previewLoadRef.current = props.loadPage(searchUrl(query.trim(), true), searchPage => load === previewLoadRef.current && (!currentPageIsSearch(searchPage) || searchPage.totalProductCount > 0)));
            load.then(() => Promise.resolve());
        }, PREVIEW_DEBOUNCE_TIME);
        searchTimeoutRef.current = setTimeout(() => {
            search(query, true);
        }, FULL_SEARCH_DEBOUNCE);
    }
    function reset() {
        const browserUrl = currentUrl();
        const stateUrl = new URLX(props.url);
        if (!equalsUrl(browserUrl, stateUrl)) {
            replaceState(searchQuery(browserUrl));
        }
    }
    function search(query, isTimeoutSearch = false) {
        return __awaiter(this, void 0, void 0, function* () {
            clearTimeouts();
            props.unClickLinkItem();
            if (query.length >= MIN_CHAR_SEARCH) {
                const newUrl = searchUrl(query.trim(), isTimeoutSearch);
                searchHistory.add(query);
                props.loadNoSearchResults(true);
                return pushState(newUrl);
            }
        });
    }
    function onSubmit(e) {
        var _a;
        e.preventDefault();
        search(searchText).then(() => Promise.resolve());
        props.hideQuickSearch();
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.blur();
    }
    function queryFromValue(value) {
        // IE11 and below fires an `input` on focus even if the user didn't type anything
        if (value === searchText) {
            return;
        }
        props.loadQuickSearch(value, true);
        if (value.length >= MIN_CHAR_SEARCH) {
            props.showQuickSearch();
        }
        if (value.length >= MIN_CHAR_SEARCH) {
            debounceSearch(value);
        }
        else {
            clearTimeouts();
            reset();
        }
    }
    function onChange(e) {
        queryFromValue(e.currentTarget.value);
        setSearchKeyword(e.currentTarget.value);
    }
    function onFocus(e) {
        const value = e.currentTarget.value;
        e.currentTarget.selectionStart = value.length;
        props.loadQuickSearch(value, true);
        props.showQuickSearch();
    }
    function onKeyPress(e) {
        if (e.keyCode === ESC_KEY) {
            if (searchText) {
                props.loadQuickSearch('', true);
            }
            props.hideQuickSearch();
            clearTimeouts();
            reset();
        }
    }
    function handleOnClickClear() {
        setSearchKeyword('');
    }
    useClickOutside(elementRef, () => {
        var _a;
        if (props.quickSearch.open) {
            clearTimeouts();
            hideQuickSearchNow();
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.blur();
        }
    });
    const entries = new Set((_a = props.quickSearch.history) === null || _a === void 0 ? void 0 : _a.map(d => d.text));
    const suggestions = [
        ...props.quickSearch.history,
        ...props.quickSearch.related.filter(d => !entries.has(d.text)),
    ];
    return (React.createElement(Base, Object.assign({}, testIdProps.SearchForm, { action: searchPageUrl(), onSubmit: onSubmit, ref: elementRef }),
        React.createElement(SearchOverlay, { isOpen: props.quickSearch.open, onClose: props.hideQuickSearch }, isCompact ? (React.createElement(MobilePositionedSuggestions, { open: props.quickSearch.open, items: suggestions, applySuggestion: applySuggestion, mostCommonSearchLinks: mostCommonSearchLinks })) : (React.createElement(PositionedSuggestions, { open: props.quickSearch.open, items: suggestions, applySuggestion: applySuggestion, mostCommonSearchLinks: mostCommonSearchLinks }))),
        React.createElement(InputWrapper, null,
            React.createElement(Input, { type: "search", name: "q", autoComplete: "off", value: searchKeyword, placeholder: isEmptySearchText ? translate('/QuickSearch/Placeholder') : '', onChange: onChange, onFocus: onFocus, onKeyDown: onKeyPress, ref: inputRef }),
            (searchKeyword === null || searchKeyword === void 0 ? void 0 : searchKeyword.length) === 0 && (React.createElement(Submit, Object.assign({}, testIdProps.StartSearchButton, { type: "submit", "aria-label": translate('/Shared/Search') }),
                React.createElement(Search, { color: grey60 }))),
            (searchKeyword === null || searchKeyword === void 0 ? void 0 : searchKeyword.length) > 0 && (React.createElement(ClearLink, { onClick: handleOnClickClear },
                React.createElement(ClearIcon, null))))));
};
export default styled(connect((state) => {
    const searchPage = currentPageIsSearch(state.currentPage) && state.currentPage;
    return {
        hasProducts: searchPage ? searchPage.totalProductCount > 0 : false,
        quickSearch: state.quickSearch,
        url: state.currentPage.url,
    };
}, (dispatch) => ({
    loadPage(url, shouldUseResponse) {
        return dispatch(loadPage({ url, options: { hideSpinner: true }, shouldUseResponse }));
    },
    loadQuickSearch(query, isPreview) {
        return dispatch(loadQuickSearch(query, isPreview));
    },
    hideQuickSearch() {
        dispatch(hideQuickSearch());
    },
    showQuickSearch() {
        dispatch(showQuickSearch());
    },
    loadNoSearchResults(isPreview) {
        return dispatch(loadNoSearchResults(isPreview));
    },
    unClickLinkItem() {
        return dispatch(unClickLinkItem());
    },
}))(QuickSearch));
const Base = styled.form({
    position: theme => (theme.isCompact ? 'inherit' : 'relative'),
    display: 'flex',
    alignItems: 'center',
});
const Submit = styled(LinkButton, resetButtonDecorator, {
    width: 29,
    height: 29,
    position: 'absolute',
    right: 6,
    textAlign: 'center',
});
const InputWrapper = styled.div({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginRight: average,
});
const Input = createTabbingFocusElement(styled(BareInputWithoutFocusVisuals, Object.assign({ fontSize: delta, padding: {
        y: 3,
        left: medium,
        right: 34,
    }, backgroundColor: lightGrey, borderRadius: 29, border: {
        xy: {
            color: 'transparent',
        },
    }, maxWidth: 200, height: huge }, selector('::placeholder', { color: grey60 }))));
const PositionedSuggestions = styled(Suggestions, {
    position: 'absolute',
    top: '100%',
    left: -thin,
    width: 320,
    filter: 'unset',
});
const MobilePositionedSuggestions = styled(PositionedSuggestions, {
    display: 'flex',
    left: 0,
    width: '100vw',
    marginTop: 0,
});
const ClearLink = styled(LinkButton, resetButtonDecorator, {
    position: 'absolute',
    right: 6,
    textAlign: 'center',
});
const ClearIcon = styled(Close, {
    width: 14,
    height: 14,
    marginRight: 6,
});
