import { isEmpty } from 'Shared/utils';
export function formatDataForPrinting(values) {
    const dataKeyValue = [];
    if (values != null && values != undefined) {
        for (const [key, value] of Object.entries(values)) {
            if (key !== '' || !isEmpty(key)) {
                const keyValueObj = {
                    facet: key,
                    selectedId: value.id,
                };
                dataKeyValue.push(keyValueObj);
            }
        }
    }
    return dataKeyValue;
}
