import { __awaiter } from "tslib";
import '../styles/globals.scss';
import React from 'react';
import { GlitzClient } from '@glitz/core';
import { GlitzProvider } from '@glitz/react';
import { browserRender, initUpdateAppShellDataOnSchedule, loadPage, switchBreakpoint, currentBreakpoint, setupResizeListener, resolveComponentAndChildComponents, isBundleLoadError, setPhrases, scrollToHashAfterInitialRender, addUserLog, userLogToString, setStoreForCrossWindowEvents, onHistory, refreshCachedData, hasServiceWorker, clearCacheAndUnregisterServiceWorker, registerServiceWorker, resolveDataSync, } from '@avensia/scope';
import 'Shared/component-registry';
import { initApplicationInsights, trackPageview } from './telemetry';
import { setAppShellData, getCurrentUser, getCart, forceUpdateDynamicData } from './Shared/dynamic-data';
import Container from './SiteLayout/Container';
import createStore from 'Shared/create-store';
import { openLoginBoxIfUrlSaysSo, openLoginBoxOn401 } from 'Account/action-creators';
import { setKnownUrls } from 'Shared/known-urls';
import { pageLoad as gtmPageLoad, initTagManager, setPageLoadPromise } from './TrackingInformation';
import currentPageIsAppShell from './AppShell/Partials/current-page-is-appshell';
import { glitzCoreOptions } from 'Shared/glitz-options';
import { setStoreForABTesting } from './google-optimize';
import currentPageIsCheckout from 'Checkout/current-page-is-checkout';
if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React);
}
let firstLoadIsAppShell = false;
function render(store, glitz) {
    return __awaiter(this, void 0, void 0, function* () {
        const el = document.getElementById('container');
        yield browserRender(store, el, React.createElement(GlitzProvider, { glitz: glitz },
            React.createElement(Container, { store: store })));
        if (location.hash) {
            yield scrollToHashAfterInitialRender(location.hash);
        }
    });
}
function initialRender(appShellData, currentPage, glitz) {
    removeServiceWorkerQuery();
    let initialLog = 'Started at ' + window.location.href + '.';
    if (window.IS_RENDERED_FROM_CACHE) {
        initialLog += ' Was rendered in Service Worker.';
    }
    addUserLog(initialLog);
    // Temporarily added this console log for debugging purposes on the server
    // once ticket is verified(hopefully) we will then remove this console.log
    console.log('initialRender::appShellData ', appShellData);
    setKnownUrls(appShellData.siteSettings);
    setAppShellData(appShellData);
    initApplicationInsights(appShellData);
    const store = createStore({ appShellData, currentPage });
    // Since some errors occur before this code has loaded some errors slip past this check.
    // Because of this a slightly different version of this is placed in _Layout.cshtml
    // as well
    if (window.rg4js) {
        window.rg4js('onBeforeSend', (payload) => {
            function stackTraceHasNoInformation(stackTrace) {
                if (!stackTrace || stackTrace.length === 0) {
                    return true;
                }
                const firstItem = stackTrace[0];
                const lastItem = stackTrace[stackTrace.length - 1];
                return ((!firstItem.ColumnNumber && !firstItem.LineNumber) ||
                    (!firstItem.ColumnNumber && firstItem.LineNumber === 1) ||
                    // If the only stack item is from window.onerror we want to ignore it
                    (stackTrace.length === 1 &&
                        (!firstItem.MethodName || firstItem.MethodName.toLowerCase().includes('onerror'))) ||
                    // Any errors from global code is not our fault
                    (lastItem.MethodName || '').toLowerCase().includes('global code') ||
                    // A bug in Chrome for iOS sometimes propagates errors to us for autofill
                    (firstItem.MethodName || '').includes('getUnownedAutofillableFormFieldElements_') ||
                    firstItem.MethodName === '?' ||
                    (firstItem.FileName || '').includes('googleusercontent.com'));
            }
            const ignores = ['InvalidStateError', 'Network request failed', '__gCrWeb'];
            for (const ignore of ignores) {
                if (payload.Details.Error.Message.indexOf(ignore) !== -1) {
                    return null;
                }
            }
            if (stackTraceHasNoInformation(payload.Details.Error.StackTrace)) {
                return null;
            }
            if (!payload.Details.UserCustomData) {
                payload.Details.UserCustomData = {};
            }
            payload.Details.UserCustomData.userLog = userLogToString();
            const currentPageState = store.getState().currentPage;
            const currentPageJson = JSON.stringify(currentPageState);
            const currentUserData = resolveDataSync(getCurrentUser());
            const currentUserDataJson = JSON.stringify(currentUserData);
            const cartData = resolveDataSync(getCart());
            const cartDataJson = JSON.stringify(cartData);
            // raygun has a limit of 128 KB on their crash reporting messages
            // we make sure we have some margin here
            // https://raygun.com/documentation/product-guides/crash-reporting/api/
            const byteLimit = 127 * 1024;
            if (currentPageJson.length + JSON.stringify(payload).length < byteLimit) {
                payload.Details.UserCustomData.currentPage = currentPageState;
            }
            if (currentUserDataJson.length + JSON.stringify(payload).length < byteLimit) {
                payload.Details.UserCustomData.currentUser = currentUserData;
            }
            if (cartDataJson.length + JSON.stringify(payload).length < byteLimit) {
                payload.Details.UserCustomData.cart = cartData;
            }
            return payload;
        });
        window.rg4js('groupingKey', (payload) => {
            return payload.Details.Error.Message;
        });
    }
    // Comment this row out in case you want to disable GTM.
    initTagManager(store);
    setStoreForCrossWindowEvents(store);
    setStoreForABTesting(store, appShellData.siteSettings.googleAnalyticsKey);
    setupResizeListener(store);
    initUpdateAppShellDataOnSchedule(store);
    openLoginBoxIfUrlSaysSo(store);
    openLoginBoxOn401(store);
    const breakpoint = currentBreakpoint();
    if (breakpoint !== store.getState().currentBreakpoint) {
        console.debug('Setting new breakpoint since server guess was incorrect');
        store.dispatch(switchBreakpoint(breakpoint));
    }
    else {
        console.debug('Server breakpoint guess was correct');
    }
    if (!currentPageIsCheckout(currentPage)) {
        forceUpdateDynamicData();
    }
    onHistory(['push', 'pop', 'replace'], e => {
        const loadPromise = store.dispatch(loadPage({ url: e.url, options: e.options, stateChangeEvent: e, replaceStateOnRedirect: true }));
        setPageLoadPromise(loadPromise);
        loadPromise.then(() => {
            const state = store.getState();
            const cp = state.currentPage;
            if (!cp.isPartial) {
                gtmPageLoad(cp, true, state);
                trackPageview(cp);
            }
        });
        return loadPromise;
    });
    // Make sure phrases isn't `undefined`, otherwise
    // `translate(...)` with cause an exception.
    setPhrases(appShellData.languagePhrases);
    const page = store.getState().currentPage;
    if (!currentPageIsAppShell(page)) {
        gtmPageLoad(page, false, store.getState());
        trackPageview(page);
    }
    else {
        firstLoadIsAppShell = true;
    }
    render(store, glitz);
    return store;
}
window.scopeReady.then(() => {
    if (!window.APP_SHELL_DATA || !window.CURRENT_PAGE) {
        if (window.rg4js) {
            window.rg4js('send', {
                error: new Error('Script error happened on ' + window.location.href + ' that caused important globals not to exist'),
                tags: ['handled'],
            });
        }
        if (window.location.href !== '/') {
            // We assume that the error doesn't occur on the start page
            // Give Raygun some time to send the request
            setTimeout(() => {
                window.location.href = '/';
            }, 1000);
        }
    }
    else {
        if (module.hot && module.hot.data && module.hot.data.store && module.hot.data.glitz) {
            const store = module.hot.data.store;
            const glitz = module.hot.data.glitz;
            module.hot.accept();
            module.hot.dispose(data => {
                data.store = store;
                data.glitz = glitz;
            });
            const state = store.getState();
            console.info('HMR updating');
            resolveComponentAndChildComponents([state.currentPage, state.appShellData], state.appShellData.currentTheme).then(() => {
                console.info('HMR re-render');
                render(store, glitz);
            }, e => {
                console.error('HMR reloading due to update error: ', e);
                window.location.reload();
            });
        }
        else {
            resolveComponentAndChildComponents([window.CURRENT_PAGE, window.APP_SHELL_DATA], window.APP_SHELL_DATA.currentTheme).then(() => {
                const glitz = new GlitzClient(glitzCoreOptions);
                const store = initialRender(window.APP_SHELL_DATA, window.CURRENT_PAGE, glitz);
                if (module.hot) {
                    module.hot.accept();
                    module.hot.dispose(data => {
                        data.store = store;
                        data.glitz = glitz;
                    });
                }
                if (window.IS_RENDERED_FROM_CACHE) {
                    refreshCachedData(store).then(() => {
                        const page = store.getState().currentPage;
                        if (firstLoadIsAppShell) {
                            gtmPageLoad(page, false, store.getState());
                            trackPageview(page);
                            firstLoadIsAppShell = false;
                        }
                    });
                }
            }, e => {
                if (isBundleLoadError(e) && hasServiceWorker) {
                    clearCacheAndUnregisterServiceWorker().then(() => {
                        window.location.reload();
                    });
                }
            });
        }
        registerServiceWorker();
        const oldOnError = window.onerror;
        if (process.env.NODE_ENV !== 'production') {
            // Make device debugging a bit simpler
            // tslint:disable-next-line only-arrow-functions
            window.onerror = function (message, url, line, col, e) {
                const error = document.createElement('div');
                error.style.position = 'fixed';
                error.style.padding = '10px';
                error.style.border = '1px solid red';
                error.style.top = '10px';
                error.style.left = '10px';
                error.style.right = '10px';
                error.style.bottom = '10px';
                error.style.background = 'white';
                const html = '<h1>Oh noes!</h1>' +
                    '<p>Message: ' +
                    message +
                    '</p>' +
                    '<p>Line: ' +
                    line +
                    '</p>' +
                    '<p>Error: ' +
                    JSON.stringify(e, null, 2) +
                    '</p>';
                error.innerHTML = html;
                document.body.appendChild(error);
                error.addEventListener('click', () => document.body.removeChild(error));
                if (oldOnError) {
                    oldOnError.apply(window, arguments);
                }
            };
        }
        else {
            printAvensiaSplashToConsole();
        }
    }
});
// We remove ?ssw from the url because it doesn't look very nice.
// Why doesn't the server just skip redirecting to a url with ?ssw in
// it you might ask? Great question!
//
// It goes like this: The client does something like this:
// window.location.href = '/en?lang=sv-SE&ssw=1'
// to trigger a full page load that skips the service worker
// in order to change the language.
// But if the server redirects us to /en instead of /en?ssw=1
// it means that /en will be served from the SW and can contain
// caches for the previous language. Instead the server redirects
// to /en?ssw=1 to skip the service worker, and we instead use
// replaceState() here to get rid of it.
function removeServiceWorkerQuery() {
    const url = new URL(document.location.href);
    if (url.searchParams.has('ssw')) {
        if (history.replaceState) {
            url.searchParams.delete('ssw');
            history.replaceState(history.state, document.title, url.toString());
        }
    }
}
function printAvensiaSplashToConsole() {
    // tslint:disable-next-line no-string-literal
    if (window['console'] && window['console']['log']) {
        // tslint:disable-next-line no-string-literal
        window['console']['log']('     _                      _       \n' +
            '    / \\__   _____ _ __  ___(_) __ _ \n' +
            "   / _ \\ \\ / / _ | '_ \\/ __| |/ _` |\n" +
            '  / ___ \\ V |  __| | | \\__ | | (_| |\n' +
            ' /_/   \\_\\_/ \\___|_| |_|___|_|\\__,_|\n\n' +
            'Do you end up here a lot? Perhaps you should come work for us!\n' +
            'We build sites using React, Redux, TypeScript, Webpack, CSS-in-JS, etc.\n' +
            'http://www.avensia.com/careers/');
    }
}
