import React, { useCallback } from 'react';
import { LinkStyledButton } from 'Shared/Button';
import { toggleCountrySelector } from 'SiteLayout/CountrySelector/action-creators';
import { useDispatch } from 'react-redux';
import { useSelector } from 'Shared/State';
import { translate } from '@avensia/scope';
import { testIdProps } from 'test-automation';
import { styled } from '@glitz/react';
import { black } from 'Shared/Style';
import { selector } from '@glitz/core';
import { getFlagIconForLanguage } from 'SiteLayout/CountrySelector';
export default function CountrySelectorButton() {
    const availableLanguages = useSelector(state => state.appShellData.availableLanguages);
    const language = useSelector(state => state.appShellData.currentLanguage);
    const culture = useSelector(state => state.appShellData.culture);
    const currentCountry = useSelector(state => state.appShellData.currentCountry);
    const currentLanguage = availableLanguages.find(l => l.culture === language || l.culture === culture) ||
        availableLanguages.find(l => l.market === currentCountry.alpha3);
    const isOpen = useSelector(state => state.countrySelector.isOpen);
    const dispatch = useDispatch();
    const onToggle = useCallback(() => {
        dispatch(toggleCountrySelector());
    }, [dispatch]);
    return (React.createElement(Button, Object.assign({ "aria-expanded": isOpen, "aria-label": `${translate('/Shared/Country')} / ${translate('/Shared/Language')}`, title: currentLanguage === null || currentLanguage === void 0 ? void 0 : currentLanguage.name, onClick: onToggle }, testIdProps.CountrySelectorButton), getFlagIconForLanguage(currentLanguage === null || currentLanguage === void 0 ? void 0 : currentLanguage.culture, currentLanguage === null || currentLanguage === void 0 ? void 0 : currentLanguage.flagOverride)));
}
const Button = styled(LinkStyledButton, Object.assign({ color: black }, selector(':hover', { color: black })));
