import { __awaiter } from "tslib";
import React from 'react';
import { styled } from '@glitz/react';
import { Print } from 'Shared/Icon';
import { average, disabledBorderGrey, small } from 'Shared/Style';
import { post } from '@avensia/scope';
import { formatDataForPrinting } from './utils';
import FeedbackButton from 'Shared/Button/Feedback';
export default styled(function PrintPDF(props) {
    function onPrintClick() {
        return __awaiter(this, void 0, void 0, function* () {
            const variantData = {
                variationCode: props.code,
                mtm: props.mtm,
                selectedUnit: props.unit,
                comments: props.comments,
            };
            const productData = {
                productCode: props.code,
                mtm: props.mtm,
                selectedUnit: props.unit,
                selectedValues: formatDataForPrinting(props.selected),
                comments: props.comments,
            };
            yield post(props.target, props.IsSelectedVariant ? variantData : productData)
                .then(response => response.blob())
                .then(blob => URL.createObjectURL(blob))
                .then(href => {
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.style.display = 'none';
                a.href = href;
                a.download = 'product.pdf';
                a.click();
            });
        });
    }
    return (React.createElement(PrintButton, { disabled: props.disabled, onClick: onPrintClick },
        React.createElement(Print, { color: disabledBorderGrey })));
});
const PrintButton = styled(FeedbackButton, {
    display: 'flex',
    alignItems: 'center',
    padding: { x: average, y: small },
    position: 'relative',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    borderBottomWidth: 0,
    ':hover': { backgroundColor: 'transparent' },
});
