import { isIOS, scrollPosition } from '@avensia/scope';
import { getScrollbarWidth } from './use-viewport';
export default function feezeScroll() {
    const element = document.body;
    const currentStyle = element.style.cssText;
    const scrollbarWidth = getScrollbarWidth();
    if (isIOS()) {
        const currentScrollPosition = scrollPosition();
        // Setting position fixed is the only way to prevent body scroll
        // to occur on iOS when you reach the bottom of the scroll position in
        // a floating layer
        Object.assign(element.style, {
            // A rendering bug occurs in iOS10.3 when nesting fixed positioned
            // elements. The properties below creates a temporarily workaround,
            // discovered in https://bugs.webkit.org/show_bug.cgi?id=170280#c6,
            // until the fix is released and the majority of users has updated
            // their devices.
            margin: '-1px',
            padding: '1px',
        });
        let abort = false;
        requestAnimationFrame(() => {
            // Abort if reset function is triggered on the same tick
            if (abort) {
                return;
            }
            // Setting position fixed is the only way to prevent body scroll
            // to occur on iOS when you reach the bottom of the scroll position in
            // a floating layer
            Object.assign(element.style, {
                position: 'fixed',
                left: '0',
                right: '0',
                top: currentScrollPosition * -1 + 'px',
            });
        });
        return () => {
            abort = true;
            element.style.cssText = currentStyle;
            scrollPosition(currentScrollPosition);
        };
    }
    else {
        element.style.overflow = 'hidden';
        //Prevent the layout from jumping when the scrollbar is hidden and flyout is open
        element.style.width = `calc(100vw - ${scrollbarWidth}px)`;
        return () => {
            element.style.cssText = currentStyle;
        };
    }
}
