import { useState, useCallback, useRef, useEffect } from 'react';
export default function useHover(disable) {
    const [value, setValue] = useState(false);
    const handleMouseOver = useCallback(() => setValue(true), []);
    const handleMouseOut = useCallback(() => setValue(false), []);
    const ref = useRef();
    const callbackRef = useCallback(node => {
        if (ref.current) {
            ref.current.removeEventListener('mouseenter', handleMouseOver);
            ref.current.removeEventListener('mouseleave', handleMouseOut);
        }
        ref.current = node;
        if (ref.current && !disable) {
            ref.current.addEventListener('mouseenter', handleMouseOver);
            ref.current.addEventListener('mouseleave', handleMouseOut);
        }
    }, [handleMouseOver, handleMouseOut, disable]);
    useEffect(() => {
        return () => {
            if (ref.current && !disable) {
                ref.current.addEventListener('mouseenter', handleMouseOver);
                ref.current.addEventListener('mouseleave', handleMouseOut);
            }
        };
    }, [handleMouseOut, handleMouseOver, disable]);
    return [callbackRef, value];
}
