import React from 'react';
import { styled } from '@glitz/react';
import Logotype from 'Shared/Logotype';
import Link from 'Shared/Link';
import { WhiteTheme } from 'Shared/Theme';
import { Theme, LargeSquareButton } from 'Shared/Button';
import { Menu } from 'Shared/Icon';
import { useMainMenu } from 'SiteLayout/MainMenu/use-mainmenu';
import { delta, medium, monochromeDark, thin, tiny, zeta, ZIndex } from 'Shared/Style';
import AccountPanel from 'Account/AccountPanel';
import { translate } from '@avensia/scope';
import QuickSearch from 'Search/QuickSearch';
import { useIsCompact, useIsMicro } from 'Shared/use-viewport';
import CartButton from './CartButton';
import { HEADER_HEIGHT_COMPACT } from './use-header-height';
import QuickOrderButton from 'Search/QuickOrderSearch';
import { useSelector } from 'Shared/State';
import { MarketType } from 'Shared/constants';
import currentPageIsCheckout from 'Checkout/current-page-is-checkout';
export default function CompactHeader({ quickOrderEnabled }) {
    const { openMainMenu, homeUrl } = useMainMenu();
    const isCompact = useIsCompact();
    const isMicro = useIsMicro();
    const marketType = useSelector(state => state.appShellData.countrySettings.marketType);
    const canUsePurchaseActions = useSelector(state => state.appShellData.canUsePurchaseActions);
    const isCheckoutPage = useSelector(state => currentPageIsCheckout(state.currentPage));
    return (React.createElement(React.Fragment, null,
        React.createElement(WhiteTheme, null,
            React.createElement(Base, null,
                React.createElement(Home, { to: homeUrl, "aria-label": translate('/Shared/GoToStartpage') }, React.createElement(Logotype, { css: { marginLeft: isMicro ? zeta : delta }, maxWidth: isCompact ? 90 : 200, maxHeight: 50, compact: isCompact })),
                React.createElement(Placeholder, { css: __$hoisted_o0 },
                    React.createElement(Search, null)),
                marketType !== MarketType.NO_COMMERCE && React.createElement(AccountPanel, null),
                canUsePurchaseActions && !isCheckoutPage && React.createElement(CartButton, null),
                React.createElement(LargeSquareButton, { as: Theme.None, "aria-label": translate('/MainMenu/Title'), onClick: () => openMainMenu(null) },
                    React.createElement(Menu, null)))),
        quickOrderEnabled && (React.createElement(QuickOrderBase, null,
            React.createElement(QuickOrderButton, null)))));
}
const Base = styled.header({
    display: 'flex',
    alignItems: 'center',
    color: theme => theme.mainTextColor,
    backgroundColor: theme => theme.mainBackgroundColor,
    padding: { top: tiny, bottom: tiny },
    position: 'sticky',
    top: 0,
    height: HEADER_HEIGHT_COMPACT,
    zIndex: ZIndex.Header,
    ':after': {
        content: '""',
        position: 'absolute',
        width: '100%',
        bottom: 0,
        borderBottom: {
            style: 'solid',
            width: thin,
            color: monochromeDark,
        },
    },
});
const QuickOrderBase = styled(Base, {
    padding: { y: 0 },
    color: 'none',
    backgroundColor: 'none',
    top: HEADER_HEIGHT_COMPACT,
    height: -HEADER_HEIGHT_COMPACT,
    zIndex: ZIndex.Header,
    ':after': {
        borderBottom: {
            style: 'solid',
            width: thin,
            color: 'transparent',
        },
    },
});
// Used for centering logotype in the middle when content
// to the right and left haft different width
const Home = styled(Link, {
    margin: { x: 'auto' },
});
const Search = styled(QuickSearch, {
    marginLeft: medium,
});
const Placeholder = styled.div({
    flexGrow: 1,
    flexBasis: 0,
    display: 'flex',
});
const __$hoisted_o0 = { justifyContent: 'flex-end' };
