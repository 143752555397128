import { useState, useEffect } from 'react';
import { findBrowserCachedResource, subscribeToBrowserCache } from './utils';
export default function useSubscribeToBrowserCache(originalSrc, enable) {
    const [cachedSrc, setCachedSrc] = useState(findBrowserCachedResource(originalSrc));
    useEffect(() => {
        let unsubsubscribe = null;
        if (enable && !cachedSrc) {
            unsubsubscribe = subscribeToBrowserCache(originalSrc, () => {
                setCachedSrc(findBrowserCachedResource(originalSrc));
            });
        }
        return () => {
            unsubsubscribe === null || unsubsubscribe === void 0 ? void 0 : unsubsubscribe();
        };
    }, [cachedSrc, enable, originalSrc]);
    return cachedSrc;
}
