import currentPageIsCheckout from 'Checkout/current-page-is-checkout';
import { useSelector } from 'Shared/State';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { toggleMiniCart as toggleMiniCartAction } from './action-creators';
import miniCartIsDisabledSelector from 'SiteLayout/MiniCart/is-disabled-selector';
export function useMiniCart() {
    const dispatch = useDispatch();
    const currentPage = useSelector(state => state.currentPage);
    const miniCartIsDisabled = useSelector(state => miniCartIsDisabledSelector(state));
    const isOpen = useSelector(state => state.miniCart.isOpen && !currentPageIsCheckout(currentPage) && !miniCartIsDisabled);
    const toggleMiniCart = useCallback((e) => {
        dispatch(toggleMiniCartAction());
    }, [dispatch]);
    return { miniCartIsDisabled, isOpen, toggleMiniCart };
}
