import { useDispatch } from 'react-redux';
import { openMainMenu as openMainMenuAction, closeMainMenu as closeMainMenuAction, setHighlightedRowMainMenu as setHighlightedRowMainMenuAction, } from './action-creators';
import { useCallback } from 'react';
import { useSelector } from 'Shared/State';
import { resolveData, useDynamicData } from '@avensia/scope';
import { getMainMenu } from 'Shared/dynamic-data';
import { isDummyChildOnly } from './item-helpers';
export function useMainMenu() {
    var _a, _b, _c;
    const dispatch = useDispatch();
    const topItems = useSelector(state => state.mainMenu.mainMenuItems);
    const mainMenuItems = (_b = (_a = useDynamicData(getMainMenu())) === null || _a === void 0 ? void 0 : _a.items) !== null && _b !== void 0 ? _b : topItems;
    const isMainMenuOpen = useSelector(state => state.mainMenu.mainMenuOpen);
    const openMenuItem = useSelector(state => state.mainMenu.openMenuItem);
    const highlightedRowMainMenu = useSelector(state => state.mainMenu.highlightedRowMainMenu);
    const currentLanguage = useSelector(state => state.appShellData.currentLanguage);
    const availableLanguages = useSelector(state => state.appShellData.availableLanguages);
    // It's known that this causes a "Warning: Prop `href` did not match" using Dev Server.
    // Is it an actual problem that I should investigate and ask questions about? No.
    // Okey, so I should just ignore and move on? Yes.
    // https://www.youtube.com/watch?v=0dTQlSdiXrE
    const homeUrl = (_c = availableLanguages.find(lang => lang.culture === currentLanguage)) === null || _c === void 0 ? void 0 : _c.url;
    const openMainMenu = useCallback((menuItem, e) => {
        e === null || e === void 0 ? void 0 : e.preventDefault();
        dispatch(openMainMenuAction(menuItem));
        if (isDummyChildOnly(menuItem)) {
            /**
             * In case user open menu while we are loading full menu we need to update the redux state with the resolved menu item since we keep the actual object in state.
             * This can only happen in a short window between hydration is done (onClick event is hooked up) and Dynamic data for mainMenu has not been resolved.
             */
            resolveData(getMainMenu()).then(data => {
                const resolvedMenuItem = data.items.find(i => i.menuId === menuItem.menuId);
                if (resolvedMenuItem) {
                    dispatch(openMainMenuAction(resolvedMenuItem));
                }
            });
        }
    }, [dispatch]);
    const closeMainMenu = useCallback((e) => {
        dispatch(closeMainMenuAction());
    }, [dispatch]);
    const setHighlightedRowMainMenu = useCallback((menuItem) => {
        dispatch(setHighlightedRowMainMenuAction(menuItem));
    }, [dispatch]);
    return {
        openMainMenu,
        closeMainMenu,
        isMainMenuOpen,
        mainMenuItems,
        openMenuItem,
        homeUrl,
        highlightedRowMainMenu,
        setHighlightedRowMainMenu,
    };
}
