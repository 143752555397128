import React from 'react';
import { styled } from '@glitz/react';
import { useIsCompact } from 'Shared/use-viewport';
import Full from './Full';
import Compact from './Compact';
import * as style from 'Shared/Style';
import { MidThemeBlock } from 'Shared/Theme';
import { useSelector } from 'Shared/State';
import { currentPageIsFindRetailer } from 'FindRetailer/current-page-is-findretailer';
function MainFooter() {
    const isCompact = useIsCompact();
    const currentPage = useSelector(state => state.currentPage);
    const isFindRetailer = currentPageIsFindRetailer(currentPage);
    return (React.createElement(styled.Footer, null,
        !isCompact && (React.createElement(Footer, null,
            React.createElement(Full, null))),
        isCompact && !isFindRetailer && (React.createElement(Footer, null,
            React.createElement(Compact, null)))));
}
export default MainFooter;
const Footer = styled(MidThemeBlock, {
    padding: {
        y: style.medium,
    },
});
