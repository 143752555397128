import { addUserLog } from '@avensia/scope';
export const OPEN_MAIN_MENU = 'TOGGLE_MAIN_MENU';
export const CLOSE_MAIN_MENU = 'CLOSE_MAIN_MENU';
export const SET_HIGHLIGHTED_ROW_MAIN_MENU = 'SET_HIGHLIGHTED_ROW_MAIN_MENU';
export function openMainMenu(menuItem) {
    addUserLog('Opening main menu');
    return {
        type: OPEN_MAIN_MENU,
        menuItem,
    };
}
export function closeMainMenu() {
    addUserLog('Closing main menu');
    return {
        type: CLOSE_MAIN_MENU,
    };
}
export function setHighlightedRowMainMenu(highlightedRowMainMenu) {
    addUserLog('Setting highlighted row main menu');
    return {
        type: SET_HIGHLIGHTED_ROW_MAIN_MENU,
        highlightedRowMainMenu,
    };
}
