import { TOGGLE_QUICKORDER_SEARCH } from './action-creators';
export default function (state = null, action) {
    if (state === null) {
        state = {
            isOpen: false,
        };
    }
    switch (action.type) {
        case TOGGLE_QUICKORDER_SEARCH:
            return Object.assign({}, state, {
                isOpen: !state.isOpen,
            });
        default:
            return state;
    }
}
