import { __rest } from "tslib";
import React, { useMemo } from 'react';
import { forwardStyle, styled } from '@glitz/react';
export default styled(forwardStyle((_a) => {
    var { bare, row, column, compose } = _a, restProps = __rest(_a, ["bare", "row", "column", "compose"]);
    const baseDecorator = useMemo(() => styled((bare || row || column) && {
        margin: { xy: 0 },
        padding: { xy: 0 },
        listStyle: 'none',
    }, (row || column) && {
        display: 'flex',
    }, column && {
        flexDirection: 'column',
    }), [bare, column, row]);
    return React.createElement(styled.Ul, Object.assign({}, restProps, { css: compose(baseDecorator) }));
}));
