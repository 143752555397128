import React, { useEffect, useState } from 'react';
import { styled } from '@glitz/react';
import Item, { SimpleItem } from './Item';
import { Ul } from 'Shared/Generic';
import { black05, FontWeight, large, linkColor, medium, white } from 'Shared/Style';
import { Theme } from 'Shared/Button';
import { translate } from '@avensia/scope';
import { format } from 'Shared/Translate';
import { useMainMenu } from 'SiteLayout/MainMenu/use-mainmenu';
export default function Level(props) {
    const { data, headline, secondRowHeadline, isFirstRowHighlight, isSecondRowHighlight } = props;
    const items = isRoot(data) ? data : data.children || [];
    const secondRowItems = isRoot(data) ? [] : data.secondRowChildren;
    const paddingDecorator = {
        padding: { top: !isRoot(data) ? 16 : 'inherit', bottom: !isRoot(data) ? 32 : 'inherit' },
    };
    const { mainMenuItems, openMenuItem, highlightedRowMainMenu } = useMainMenu();
    const currentItem = openMenuItem || mainMenuItems;
    /*
      Iterates over all items whose parent is a highlighted row in a MainMenuLinkWithTwoRowsBlock.
      If props.isFirstRowHighlight is already true it takes precedence, which it is for root or
      the MainMenuLinkWithTwoRowsBlock parent.
     */
    const [firstRowHighlight, setFirstRowHighlight] = useState(isFirstRowHighlight);
    useEffect(() => {
        if ((highlightedRowMainMenu === null || highlightedRowMainMenu === void 0 ? void 0 : highlightedRowMainMenu.length) > 0 && (mainMenuItems === null || mainMenuItems === void 0 ? void 0 : mainMenuItems.length) > 0) {
            function setActiveMenuItem(menuItems) {
                var _a;
                setFirstRowHighlight(false);
                for (const item of menuItems) {
                    const currentMenuId = !isRoot(currentItem) && (currentItem === null || currentItem === void 0 ? void 0 : currentItem.menuId);
                    if (item !== null && (item === null || item === void 0 ? void 0 : item.menuId) === currentMenuId) {
                        setFirstRowHighlight(true);
                        break;
                    }
                    if (((_a = item === null || item === void 0 ? void 0 : item.children) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                        setActiveMenuItem(item === null || item === void 0 ? void 0 : item.children);
                    }
                }
            }
            if ((highlightedRowMainMenu === null || highlightedRowMainMenu === void 0 ? void 0 : highlightedRowMainMenu.length) > 0 && !isRoot(currentItem) && !firstRowHighlight) {
                setActiveMenuItem(highlightedRowMainMenu);
            }
        }
    }, [currentItem, firstRowHighlight, highlightedRowMainMenu, mainMenuItems]);
    return (React.createElement(Ul, { column: true },
        React.createElement(MenuWrapper, { css: Object.assign({ backgroundColor: firstRowHighlight && !isRoot(data) ? black05 : white }, paddingDecorator) },
            headline && (React.createElement(Heading, { css: { color: firstRowHighlight && !isRoot(data) ? linkColor : 'inherit' } }, headline)),
            items.map((item, key) => {
                if (item) {
                    return (React.createElement(ListItem, { key: key },
                        React.createElement(Item, { item: item, pushLevel: props.pushLevel, isRoot: isRoot(data) })));
                }
            })),
        secondRowItems && (React.createElement(MenuWrapper, { css: Object.assign({ backgroundColor: isSecondRowHighlight && !isRoot(data) ? black05 : white }, paddingDecorator) },
            secondRowHeadline && (React.createElement(Heading, { css: { color: isSecondRowHighlight && !isRoot(data) ? linkColor : 'inherit' } }, secondRowHeadline)), secondRowItems === null || secondRowItems === void 0 ? void 0 :
            secondRowItems.map((item, key) => {
                if (item) {
                    return (React.createElement(ListItem, { key: key },
                        React.createElement(Item, { item: item, pushLevel: props.pushLevel, isRoot: isRoot(data) })));
                }
            }))),
        !isRoot(data) && data.url && (React.createElement(ShowAll, { as: Theme.None, to: data.url }, format(translate('/MainMenu/ShowAll'), data.name)))));
}
export function isRoot(data) {
    return Array.isArray(data);
}
const ShowAll = styled(SimpleItem, {
    marginTop: large / 2,
    color: linkColor,
    textDecoration: 'underline',
    ':hover': {
        color: 'inherit',
    },
});
const ListItem = styled.li({
    borderBottom: {
        width: 'thin',
        style: 'solid',
        color: 'transparent',
    },
});
const MenuWrapper = styled.div({
    backgroundColor: white,
});
const Heading = styled.div({
    fontWeight: FontWeight.SemiBold,
    fontSize: 18,
    padding: {
        x: medium,
        y: large / 2,
    },
    height: 'auto',
    width: '100%',
});
