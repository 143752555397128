import { TOGGLE_COUNTRY_SELECTOR } from './action-creators';
import { PAGE_LOAD } from '@avensia/scope';
export default function (state = { isOpen: false }, action) {
    switch (action.type) {
        case TOGGLE_COUNTRY_SELECTOR:
            return Object.assign({}, state, {
                isOpen: !state.isOpen,
            });
        case PAGE_LOAD: {
            if (state.isOpen) {
                return Object.assign({}, state, {
                    isOpen: false,
                });
            }
            else {
                return state;
            }
        }
        default:
            return state;
    }
}
