import React from 'react';
import { DynamicData, translate } from '@avensia/scope';
import { getCurrentUser } from 'Shared/dynamic-data';
import { openAccountPanel, AccountPanelMode } from 'Account/action-creators';
import { useDispatch } from 'react-redux';
import { LinkStyledButton } from 'Shared/Button';
import AccountPanel from 'Account/AccountPanel';
import { myDetailsPageUrl } from 'Shared/known-urls';
import { testIdProps } from '../../test-automation';
import { useSelector } from 'Shared/State';
import { User } from 'Shared/Icon';
function UserButton(props) {
    const isOpen = useSelector(state => state.userAccount.accountPanelIsOpen);
    const dispatch = useDispatch();
    function onLoginClick() {
        dispatch(openAccountPanel(AccountPanelMode.Login));
    }
    return (React.createElement(DynamicData, { data: getCurrentUser(), fallbackData: { isLoggedIn: false, id: undefined, email: undefined, fullName: undefined, isB2B: false, arePricesHidden: false } }, currentUser => (React.createElement(React.Fragment, null,
        currentUser.isLoggedIn ? (React.createElement(LinkStyledButton, Object.assign({ "aria-expanded": isOpen, "aria-label": translate('/Shared/UserAccount'), to: myDetailsPageUrl() }, testIdProps.MyPagesLink),
            React.createElement(User, null))) : (React.createElement(LinkStyledButton, Object.assign({ "aria-expanded": isOpen, "aria-label": translate('/MainMenu/Login'), onClick: onLoginClick }, testIdProps.OpenLoginButton),
            React.createElement(User, null))),
        React.createElement(AccountPanel, null)))));
}
export default UserButton;
