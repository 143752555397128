import { __rest } from "tslib";
import React from 'react';
import { styled } from '@glitz/react';
import { ArrowRight } from 'Shared/Icon';
import { medium, large, createTruncateDecorator, figma, mediaMinMicro, mediaMinSmall, sourceSansProSemiBold, sourceSansPro, epsilon, FontWeight, } from 'Shared/Style';
import Button, { Theme } from 'Shared/Button';
import { media } from '@glitz/core';
function createLabelDecorator(isRoot) {
    return styled({
        textTransform: isRoot ? 'none' : 'inherit',
    });
}
export default styled(function MenuItem(props) {
    function onClick(e) {
        if (isParent(props)) {
            e.preventDefault();
            props.pushLevel(props.item);
        }
    }
    const { url, name } = props.item;
    return isParent(props) ? (React.createElement(Parent, { as: Theme.None, to: url, onClick: onClick },
        React.createElement(Label, { css: createLabelDecorator(props.isRoot) }, name),
        React.createElement(ArrowRight, null))) : (React.createElement(Base, { as: Theme.None, to: url, onClick: onClick },
        React.createElement(Child, { css: createLabelDecorator(props.isRoot) }, name)));
});
function isParent(props) {
    var _a;
    return ((props.pushLevel && props.item.children && props.item.children.length > 0) ||
        ((_a = props.item.secondRowChildren) === null || _a === void 0 ? void 0 : _a.length) > 0);
}
export const SimpleItem = styled((_a) => {
    var { children } = _a, restProps = __rest(_a, ["children"]);
    return (React.createElement(Base, Object.assign({}, restProps),
        React.createElement(Label, null, children)));
});
const Base = styled(Button, {
    justifyContent: undefined,
    padding: {
        x: medium,
        y: large / 2,
    },
    height: 'auto',
    width: '100%',
});
const Parent = styled(Base, {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
});
const Label = styled.span(createTruncateDecorator(), sourceSansProSemiBold(epsilon), Object.assign(Object.assign({ display: 'block', fontWeight: FontWeight.Normal }, media(mediaMinMicro, { fontSize: figma })), media(mediaMinSmall, { fontSize: figma })));
const Child = styled(Label, sourceSansPro(), {});
