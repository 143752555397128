import { __rest } from "tslib";
import React from 'react';
import { styled } from '@glitz/react';
import * as style from 'Shared/Style';
import { createAnimationDecorator, createTransitionDecorator } from 'Shared/Style';
export const TextPlaceholder = styled(function ({ rowCount, visible = true, animate = true, Component = styled.Div }) {
    return (React.createElement(Component, { css: { opacity: visible ? 1 : 0 } }, [...Array(rowCount).fill(1)].map((_, index) => (React.createElement(LoadingDescriptionLine, { key: index, width: index % 2 === 0 ? 95 : 90 }, animate && React.createElement(LoadingAnimation, null))))));
}, createTransitionDecorator({ property: 'opacity', duration: '100ms' }), {
    width: '100%',
    textAlign: 'inherit',
});
const LoadingAnimation = styled.span(createAnimationDecorator({
    name: {
        '0%': {
            transform: 'translateX(-100%)',
        },
        '100%': {
            transform: 'translateX(100%)',
        },
    },
    duration: 1500,
    timingFunction: 'ease-in-out',
    iterationCount: 'infinite',
}), {
    content: '""',
    position: 'absolute',
    backgroundImage: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.4) 50%, rgba(255,255,255,0) 100%)',
    height: '100%',
    width: '100%',
});
const LoadingDescriptionLine = styled((_a) => {
    var { width } = _a, restProps = __rest(_a, ["width"]);
    return (React.createElement(styled.P, Object.assign({ css: styled({
            height: '1em',
            margin: {
                xy: 0,
            },
            width: width + '%',
            textAlign: 'inherit',
            verticalAlign: 'bottom',
            display: 'inline-block',
            lineHeight: '0.7em',
            backgroundColor: style.monochromeDark,
            position: 'relative',
            overflow: 'hidden',
        }) }, restProps)));
});
