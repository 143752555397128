// Will return `false` if `CSS.supports` is unsupported
const supports = typeof CSS !== 'undefined' && typeof CSS.supports === 'function' ? CSS.supports.bind(CSS) : () => false;
export function supportsObjectFit(browser) {
    const b = browser;
    return (!!b &&
        ((supports('object-fit', 'contain') && supports('object-position', 'center')) ||
            !(b.name === 'ie' ||
                (b.name === 'edge' && parseFloat(b.version) <= 15) ||
                (b.name === 'safari' && parseFloat(b.version) <= 9.2))));
}
