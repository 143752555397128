import { addUserLog } from '@avensia/scope';
export const CLEAR_SELECTED_PROPERTIES = 'CLEAR_SELECTED_PROPERTIES';
export const CLEAR_SELECTED_PROPERTY = 'CLEAR_SELECTED_PROPERTY';
export const SELECT_PROPERTY = 'SELECT_PROPERTY';
export const SET_DEFAULT_PROPERTIES = 'SET_DEFAULT_PROPERTIES';
export const SET_MATCHING_SERIES_PROPERTIES = 'SET_MATCHING_SERIES_PROPERTIES';
export function clearSelectedProperties() {
    addUserLog('Clear selected properties');
    return {
        type: CLEAR_SELECTED_PROPERTIES,
    };
}
export function clearSelectedProperty(propertyName) {
    return {
        type: CLEAR_SELECTED_PROPERTY,
        propertyName: propertyName,
    };
}
export function selectProperty(id, value, propertyName) {
    return {
        type: SELECT_PROPERTY,
        id: id,
        value: value,
        propertyName: propertyName,
    };
}
export function setDefaultProperties(variations, variationCode, availableFacets, units) {
    return {
        type: SET_DEFAULT_PROPERTIES,
        variations: variations,
        variationCode: variationCode,
        availableFacets: availableFacets,
        units: units,
    };
}
export function setMatchingSeriesProperties(lengths, sizes) {
    return {
        type: SET_MATCHING_SERIES_PROPERTIES,
        lengths: lengths,
        sizes: sizes,
    };
}
