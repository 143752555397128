import { styled } from '@glitz/react';
import { Square as FeatherSquare, CheckSquare as FeatherCheckSquare, Home as FeatherHome, Circle as FeatherCircle, } from 'react-feather';
import NitroCheckRadio from './glyphs/checked-circle.svg';
import SigCarouselArrow from './glyphs/CarouselArrow.svg';
import SigTableSort from './glyphs/TableSort.svg';
import SigTableSortAscending from './glyphs/TableSortAscending.svg';
import SigTableSortDescending from './glyphs/TableSortDescending.svg';
import Bars from './glyphs/bars.svg';
import Grid2 from './glyphs/Grid2.svg';
import FlagAustralia from './flags/au.svg';
import FlagAustria from './flags/at.svg';
import FlagBrasil from './flags/br.svg';
import FlagCanada from './flags/ca.svg';
import FlagChina from './flags/cn.svg';
import FlagGermany from './flags/de.svg';
import FlagGlobe from './flags/globe.svg';
import FlagFrance from './flags/fr.svg';
import FlagItaly from './flags/it.svg';
import FlagMexico from './flags/mx.svg';
import FlagPoland from './flags/pl.svg';
import FlagRussia from './flags/ru.svg';
import FlagSwitzerland from './flags/ch.svg';
import FlagUnitedKingdom from './flags/gb.svg';
import FlagUnitedStates from './flags/us.svg';
import FlagOther from './flags/other.svg';
import MyLocationSmall from './retailer/My_location_helper.svg';
import Directions from './retailer/directions.svg';
import Mail from './retailer/mail.svg';
import Desktop from './retailer/desktop_mac.svg';
import Phone from './retailer/phone_enabled.svg';
import Mobile from './retailer/phone_android.svg';
import OnlineShop from './retailer/retailer_onilne_shop.svg';
import Filter from './retailer/retailer_filter.svg';
// Fontawesome tree shaking is not working. By default
// it looks like the entire index.es.js is being included in my compiled version
// despite explicitly using the proper es import syntax. Therefore need import by fullpath to optimized the bundle size.
import { faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons/faHeart';
import { faCirclePlay } from '@fortawesome/pro-solid-svg-icons/faCirclePlay';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faLocationDot as faLocationDotSolid } from '@fortawesome/pro-solid-svg-icons/faLocationDot';
import { faHorizontalRule } from '@fortawesome/pro-solid-svg-icons/faHorizontalRule';
import { faGalleryThumbnails as faSemiViewMap } from '@fortawesome/pro-solid-svg-icons/faGalleryThumbnails';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import { faPause } from '@fortawesome/pro-solid-svg-icons';
import { faVolume } from '@fortawesome/pro-solid-svg-icons';
import { faVolumeXmark } from '@fortawesome/pro-solid-svg-icons';
import { faChevronDown as faChevronDownSolid } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faAddressBook } from '@fortawesome/pro-light-svg-icons/faAddressBook';
import { faPrint } from '@fortawesome/pro-light-svg-icons/faPrint';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons/faTrashCan';
import { faPhone } from '@fortawesome/pro-light-svg-icons/faPhone';
import { faHeart } from '@fortawesome/pro-light-svg-icons/faHeart';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faCartShopping } from '@fortawesome/pro-light-svg-icons/faCartShopping';
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus';
import { faCalendarDay } from '@fortawesome/pro-light-svg-icons/faCalendarDay';
import { faStore } from '@fortawesome/pro-light-svg-icons/faStore';
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars';
import { faCircleCheck } from '@fortawesome/pro-light-svg-icons/faCircleCheck';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { faCircleExclamation } from '@fortawesome/pro-light-svg-icons/faCircleExclamation';
import { faLink } from '@fortawesome/pro-light-svg-icons/faLink';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { faXmarkLarge } from '@fortawesome/pro-light-svg-icons/faXmarkLarge';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faChevronLeft as faChevronLeftLight } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faChevronRight as faChevronRightLight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { faMapLocationDot } from '@fortawesome/pro-light-svg-icons/faMapLocationDot';
import { faLocationArrow } from '@fortawesome/pro-light-svg-icons/faLocationArrow';
import { faLocationDot } from '@fortawesome/pro-light-svg-icons/faLocationDot';
import { faMap } from '@fortawesome/pro-light-svg-icons/faMap';
import { faList } from '@fortawesome/pro-light-svg-icons/faList';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faInstagramSquare } from '@fortawesome/free-brands-svg-icons/faInstagramSquare';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons/faTwitterSquare';
import { faYoutubeSquare } from '@fortawesome/free-brands-svg-icons/faYoutubeSquare';
import { faPinterest } from '@fortawesome/free-brands-svg-icons/faPinterest';
import { faViadeoSquare } from '@fortawesome/free-brands-svg-icons/faViadeoSquare';
import fontAwesomeFactory from './fontawesomefactory';
import factory from './factory';
export { default as Spinner } from './Spinner';
export { default as Sort } from './Sort';
export const CarouselArrowDown = styled(factory(SigCarouselArrow), {
    minWidth: 33,
    minHeight: 10,
});
export const CarouselArrowUp = styled(CarouselArrowDown, { transform: 'rotate(180deg)' });
export const CarouselArrowRight = styled(CarouselArrowDown, { transform: 'rotate(270deg)' });
export const CarouselArrowLeft = styled(CarouselArrowDown, { transform: 'rotate(90deg)' });
export const UncheckedCheckbox = factory(FeatherSquare);
export const CheckedCheckbox = factory(FeatherCheckSquare);
export const Home = factory(FeatherHome);
export const UncheckedRadio = factory(FeatherCircle);
export const CheckedRadio = factory(NitroCheckRadio);
export const TableSort = styled(factory(SigTableSort), { height: 12, width: 12 });
export const TableSortAscending = styled(factory(SigTableSortAscending), { height: 12, width: 12 });
export const TableSortDescending = styled(factory(SigTableSortDescending), { height: 12, width: 12 });
export const List = styled(factory(Bars), { height: 12, width: 12 });
export const Grid = styled(factory(Grid2), { height: 12, width: 12 });
export const Close = fontAwesomeFactory({ icon: faXmarkLarge });
export const Search = fontAwesomeFactory({ icon: faSearch });
export const Trash = fontAwesomeFactory({ icon: faTrashCan });
export const Edit = fontAwesomeFactory({ icon: faPen });
export const Error = fontAwesomeFactory({ icon: faCircleExclamation });
export const Menu = fontAwesomeFactory({ icon: faBars });
export const ShowPassword = fontAwesomeFactory({ icon: faEye });
export const ArrowDown = fontAwesomeFactory({ icon: faChevronDown });
export const ArrowLeft = styled(ArrowDown, { transform: 'rotate(90deg)' });
export const ArrowUp = styled(ArrowDown, { transform: 'rotate(180deg)' });
export const ArrowRight = styled(ArrowDown, { transform: 'rotate(270deg)' });
export const ArrowDownSolid = fontAwesomeFactory({ icon: faChevronDownSolid });
export const ArrowLeftSolid = styled(ArrowDownSolid, { transform: 'rotate(90deg)' });
export const ArrowRightSolid = styled(ArrowDownSolid, { transform: 'rotate(270deg)' });
export const Cart = fontAwesomeFactory({ icon: faCartShopping });
export const CheckCircle = fontAwesomeFactory({ icon: faCircleCheck });
export const Heart = fontAwesomeFactory({ icon: faHeart });
export const HeartFilled = fontAwesomeFactory({ icon: faHeartSolid });
export const Contact = fontAwesomeFactory({ icon: faPhone });
export const User = fontAwesomeFactory({ icon: faUser });
export const Global = fontAwesomeFactory({ icon: faGlobe });
export const Store = fontAwesomeFactory({ icon: faStore });
export const Calendar = fontAwesomeFactory({ icon: faCalendarDay });
export const AddressBook = fontAwesomeFactory({ icon: faAddressBook });
export const Plus = fontAwesomeFactory({ icon: faPlus });
export const Minus = fontAwesomeFactory({ icon: faMinus });
export const Check = fontAwesomeFactory({ icon: faCheck });
export const CarouselVideoPlay = fontAwesomeFactory({ icon: faCirclePlay });
export const RetailerLocation = fontAwesomeFactory({ icon: faLocationArrow });
export const StyleGuideLeftLight = styled(fontAwesomeFactory({ icon: faChevronLeftLight }));
export const StyleGuideRightLight = fontAwesomeFactory({ icon: faChevronRightLight });
export const StyleGuideClose = fontAwesomeFactory({ icon: faTimes });
/** Social Icons */
export const Facebook = fontAwesomeFactory({ icon: faFacebookSquare });
export const Instagram = fontAwesomeFactory({ icon: faInstagramSquare });
export const LinkedIn = fontAwesomeFactory({ icon: faLinkedin });
export const Twitter = fontAwesomeFactory({ icon: faTwitterSquare });
export const Youtube = fontAwesomeFactory({ icon: faYoutubeSquare });
export const Pinterest = fontAwesomeFactory({ icon: faPinterest });
export const Viadeo = fontAwesomeFactory({ icon: faViadeoSquare });
export const Link = fontAwesomeFactory({ icon: faLink });
export const FindRetailer = fontAwesomeFactory({ icon: faMapLocationDot });
export const MapMarker = fontAwesomeFactory({ icon: faLocationDot });
export const MapMarkerFill = fontAwesomeFactory({ icon: faLocationDotSolid });
export const HorizontalRule = fontAwesomeFactory({ icon: faHorizontalRule });
export const SemiViewMap = fontAwesomeFactory({ icon: faSemiViewMap });
export const ViewMap = fontAwesomeFactory({ icon: faMap });
export const ListView = fontAwesomeFactory({ icon: faList });
export const MyLocationHelper = styled(factory(MyLocationSmall), { height: 18 });
export const RetailerDirections = styled(factory(Directions), { height: 18 });
export const RetailerMail = styled(factory(Mail), { height: 18 });
export const RetailerWebsite = styled(factory(Desktop), { height: 18 });
export const RetailerPhone = styled(factory(Phone), { height: 18 });
export const RetailerMobile = styled(factory(Mobile), { height: 18 });
export const RetailerOnlineShop = styled(factory(OnlineShop), { height: 18 });
export const RetailerFilter = styled(factory(Filter), { height: 18 });
export const socialIcons = {
    Facebook,
    Instagram,
    LinkedIn,
    Twitter,
    Youtube,
    Pinterest,
    Link,
    Viadeo,
};
/** Flags */
export const FlagIconAustralia = styled(factory(FlagAustralia), { height: 18 });
export const FlagIconAustria = styled(factory(FlagAustria), { height: 18 });
export const FlagIconBarsil = styled(factory(FlagBrasil), { height: 18 });
export const FlagIconCanada = styled(factory(FlagCanada), { height: 18 });
export const FlagIconChina = styled(factory(FlagChina), { height: 18 });
export const FlagIconFrance = styled(factory(FlagFrance), { height: 18 });
export const FlagIconGermany = styled(factory(FlagGermany), { height: 18 });
export const FlagIconGlobe = styled(factory(FlagGlobe), { height: 18 });
export const FlagIconItaly = styled(factory(FlagItaly), { height: 18 });
export const FlagIconMexico = styled(factory(FlagMexico), { height: 18 });
export const FlagIconOther = styled(factory(FlagOther), { height: 18 });
export const FlagIconPoland = styled(factory(FlagPoland), { height: 18 });
export const FlagIconRussia = styled(factory(FlagRussia), { height: 18 });
export const FlagIconSwitzerland = styled(factory(FlagSwitzerland), { height: 18 });
export const FlagIconUnitedKingdom = styled(factory(FlagUnitedKingdom), { height: 18 });
export const FlagIconUnitedStates = styled(factory(FlagUnitedStates), { height: 18 });
export const AudioPlay = fontAwesomeFactory({ icon: faPlay });
export const AudioPause = fontAwesomeFactory({ icon: faPause });
export const AudioVolume = fontAwesomeFactory({ icon: faVolume });
export const AudioVolumeOff = fontAwesomeFactory({ icon: faVolumeXmark });
export const Print = fontAwesomeFactory({ icon: faPrint });
