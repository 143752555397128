import React from 'react';
import { styled } from '@glitz/react';
import Link from 'Shared/Link';
import { socialIcons } from 'Shared/Icon';
import { useIsCompact } from 'Shared/use-viewport';
import { resetLinkDecorator } from 'Shared/Generic/A';
import { small, tiny } from 'Shared/Style';
import * as style from 'Shared/Style';
export default (props) => {
    var _a;
    const isCompact = useIsCompact();
    return (React.createElement(SocialLinks, null, (_a = props.footer.followUsLinks) === null || _a === void 0 ? void 0 : _a.map((followLink, index) => {
        const DynamicIcon = socialIcons[followLink.destinationType];
        return (React.createElement(SocialLink, { key: index, to: followLink.url, "aria-label": followLink.destinationType, title: followLink.destinationType, target: followLink.target },
            React.createElement(DynamicIcon, null),
            !isCompact && React.createElement(SocialLinkText, null, followLink.destinationType)));
    })));
};
const SocialLinks = styled.div({
    display: 'flex',
    flexDirection: ({ isCompact }) => (isCompact ? 'row' : 'column'),
    flexWrap: 'wrap',
});
const SocialLink = styled(Link, resetLinkDecorator, {
    display: 'flex',
    alignItems: 'center',
    margin: { top: ({ isCompact }) => (isCompact ? 0 : style.medium) },
    padding: { x: '0.5rem' },
    ':hover': {
        backgroundColor: ({ siteTheme }) => siteTheme.footer.hoverBackgroundColor,
        borderRadius: tiny,
    },
});
const SocialLinkText = styled.span({ marginLeft: small });
