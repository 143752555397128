import { styled } from '@glitz/react';
const DEFAULT_DURATION = 200;
const DEFAULT_TIMING_FUNCTION = 'ease-out';
export function createTransitionDecorator(props) {
    const { property, duration = DEFAULT_DURATION, timingFunction = DEFAULT_TIMING_FUNCTION, delay, willChange } = props;
    const properties = Array.isArray(property) ? property.join(', ') : property;
    return styled(Object.assign({ transition: {
            property: properties,
            duration,
            timingFunction,
            delay,
        } }, (willChange && {
        willChange: properties,
    })));
}
export function createAnimationDecorator(props) {
    const { name, duration = DEFAULT_DURATION, timingFunction = DEFAULT_TIMING_FUNCTION, delay, iterationCount, direction, fillMode, playState, } = props;
    return styled({
        animation: {
            name,
            duration,
            timingFunction,
            delay,
            iterationCount,
            direction,
            fillMode,
            playState,
        },
    });
}
