import { Breakpoint, widthFromBreakpoint } from '@avensia/scope/';
import { query } from '@glitz/core';
import { pixelsToUnit } from './unit';
function mediaQuery(breakpoint) {
    return query({
        minWidth: pixelsToUnit(widthFromBreakpoint(breakpoint)),
    });
}
/** Media query for minimum window width for smaller smartphones in portrait mode */
export const mediaMinMicro = mediaQuery(Breakpoint.Micro);
/** Media query for minimum window width for larger smartphones and smaller tablets in portrait mode */
export const mediaMinTiny = mediaQuery(Breakpoint.Tiny);
/** Media query for minimum window width for larger tablets in portrait mode */
export const mediaMinSmall = mediaQuery(Breakpoint.Small);
/** Media query for minimum window width for smaller tablets in landscape mode */
export const mediaMinMedium = mediaQuery(Breakpoint.Medium);
/** Media query for minimum window width for larger tablets in landscape mode, smaller laptops and desktops */
export const mediaMinLarge = mediaQuery(Breakpoint.Large);
/** Media query for minimum window width for larger laptops and desktops */
export const mediaMinHuge = mediaQuery(Breakpoint.Huge);
export const mediaMinGigantic = mediaQuery(Breakpoint.Gigantic);
export const mediaMinQueries = {
    [Breakpoint.Micro]: mediaMinMicro,
    [Breakpoint.Tiny]: mediaMinTiny,
    [Breakpoint.Small]: mediaMinSmall,
    [Breakpoint.Medium]: mediaMinMedium,
    [Breakpoint.Large]: mediaMinLarge,
    [Breakpoint.Huge]: mediaMinHuge,
    [Breakpoint.Gigantic]: mediaMinGigantic,
};
